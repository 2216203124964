import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { Cliente } from '../../models/cliente.class';
import '../../styles/styles.scss'

const UsuarioComponent = ({usuario, guardarCambios, resetPass}) => {

    useEffect(() => {
    console.log(usuario);
      }, []);

      function handleOnChange(event){}
      function handleOnKeyUp(event){
        if (event.keyCode === 13) {
            guardarCambios(usuario.id, document.activeElement.name, event.target.value)
        }
      }


    return (
        <div>
        <tr key={usuario.id} className='listado-row'>
            <td className='listado-field w7_5' style={{padding: '0 0 0 1vw'}}> {usuario.id}</td>
            <td className='listado-field w15 bold'>{usuario.name}</td>
            <td className='listado-field w15'>{usuario.email}</td>
            <td className='listado-field w15'>{usuario.usuario}</td>
            <td className='listado-field w15'>{usuario.roles}</td>
            <div className='acciones' style={{marginLeft:'auto'}}>
                <i className="bi bi-caret-down" title="Ver datos" data-bs-toggle="collapse" data-bs-target={'#collapseExample'.concat(usuario.id)} aria-expanded="true" aria-controls="collapseExample"></i>
                <i class="bi bi-arrow-repeat hover" title="Resetear contraseña" onClick={() => resetPass(usuario.id, usuario.nombre)}></i>
            </div>
        </tr>
            <div className="collapse" id={'collapseExample'.concat(usuario.id)}>
                <div className="card card-body">
                <h6 className='collapse-title'>Datos generales</h6>
                <div className='collapse-row'>
                    <div className='collapse-field w25'>
                        <label className='cflabel w35'>ID</label>
                        <input className='cfinput w65' disabled defaultValue={usuario.id} name='id'></input>                       
                    </div>
                    <div className='collapse-field w25'>
                        <label className='cflabel w35'>Nombre</label>
                        <input className='cfinput w65' defaultValue={usuario.name} name='name' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                    </div>
                    <div className='collapse-field w25'>
                        <label className='cflabel w35'>Usuario</label>
                        <input className='cfinput w65' defaultValue={usuario.usuario} name='usuario' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                    </div>
                </div>
                <div className='collapse-row'>
                    <div className='collapse-field w50'>
                        <label className='cflabel w17_5'>Email</label>
                        <input className='cfinput w82_5' defaultValue={usuario.email} name='email' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};


UsuarioComponent.propTypes = {
    cliente: PropTypes.instanceOf(Cliente)
}


export default UsuarioComponent;
