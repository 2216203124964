export const basicAuthentication = async (usuario, password) => {
    let body =  JSON.stringify({
        "usuario": usuario,
        "password": password
    })
    
    let response = await fetch('https://face-dracars.es:8443/login',{
        method:'POST',
        cache:'no-cache',
        credentials: 'omit',
        headers:{
            'Content-type': 'application/json',
        },
        body
    });
    return response.json();
}

export const getAllArticulos = async (token) => {
var bearer = 'Bearer ' + token;
    let response = await fetch('https://face-dracars.es:8443/v1/getAllArticulos',{
        method:'GET',
        cache:'no-cache',
        headers:{
            'Content-type': 'application/json',
            'Authorization': bearer
        }
    });
    return response.json();
}

export const getArticulo = async (token, id) => {
    var bearer = 'Bearer ' + token;
        let response = await fetch('https://face-dracars.es:8443/v1/getArticulo?id='.concat(id),{
            method:'GET',
            cache:'no-cache',
            headers:{
                'Content-type': 'application/json',
                'Authorization': bearer
            }
        });
        return response.json();
    }

export const createArticulo = async (token,articulo) => {
    var bearer = 'Bearer ' + token;
    
    var body =  JSON.stringify({
        "nombre": articulo.nombre,
        "familia": articulo.familia,
        "subfamilia":articulo.subfamilia,
        "descripcion": articulo.descripcion,
        "stockMin":articulo.stockMin,
        "stockMax":articulo.stockMax,
        "unidades": articulo.unidades,
        "observaciones":articulo.observaciones,
        "precio": articulo.precio,
        "iva": articulo.iva,
        "precioProveedor":articulo.precioProveedor,
        "publicable":articulo.publicable,
        "rotura":articulo.rotura,
        "descatalogado":articulo.descatalogado,
        "portada":articulo.portada,
        "masVendido":articulo.masVendido,
        "novedades":articulo.novedades,
        "codigoEAN13": articulo.codigoEAN13
    });

        let response = await fetch('https://face-dracars.es:8443/v1/createArticulo',{
            method:'POST',
            cache:'no-cache',
            headers:{
                'Content-type': 'application/json',
                'Authorization': bearer
            },
            body
        });
        return response.json();
    }

export const updateArticulo = async (token, id, key, value) => {
    var bearer = 'Bearer ' + token;
    var body =  JSON.stringify({
        [key]:value
    });
    
        let response = await fetch('https://face-dracars.es:8443/v1/updateArticulo?id='.concat(id),{
            method:'PATCH',
            cache:'no-cache',
            headers:{
                'Content-type': 'application/json',
                'Authorization': bearer
            },
            body
        });
        return response.json();
    }

    export const findArticulos = async (token, textos) => {
        var bearer = 'Bearer ' + token;
        var body =  JSON.stringify(textos);
       
        let response = await fetch('https://face-dracars.es:8443/v1/findArticulos',{
            method:'POST',
            cache:'no-cache',
            headers:{
                'Content-type': 'application/json',
                'Authorization': bearer
            },
            body
        });
        
        return response.json();
    }  

export const getAllClientes = async (token) => {
    var bearer = 'Bearer ' + token;
        let response = await fetch('https://face-dracars.es:8443/v1/getAllClientes',{
            method:'GET',
            cache:'no-cache',
            headers:{
                'Content-type': 'application/json',
                'Authorization': bearer
            }
        });
    return response.json();
}

export const createCliente = async (token,cliente) => {
    var bearer = 'Bearer ' + token;
    var body =  JSON.stringify({
        "nombre": cliente.nombre,
        "nombreComercial": cliente.nombreComercial,
        "cif": cliente.cif,
        "direccion": cliente.direccion,
        "cp": cliente.cp,
        "provincia": cliente.provincia,
        "poblacion": cliente.poblacion,
        "tributacion":cliente.tributacion,
        "tipoDePago":cliente.tipoDePago,
        "movil":cliente.movil,
        "telefono": cliente.telefono,
        "email":cliente.email,
        "observaciones":cliente.observaciones
    });

let response = await fetch('https://face-dracars.es:8443/v1/createCliente',{
    method:'POST',
    cache:'no-cache',
    headers:{
        'Content-type': 'application/json',
        'Authorization': bearer
    },
    body
});
return response.json();
}

export const updateCliente = async (token, id, key, value) => {
var bearer = 'Bearer ' + token;
var body =  JSON.stringify({
    [key]:value
});
    let response = await fetch('https://face-dracars.es:8443/v1/updateCliente?id='.concat(id),{
        method:'PATCH',
        cache:'no-cache',
        headers:{
            'Content-type': 'application/json',
            'Authorization': bearer
        },
        body
    });
    return response;
}

export const deleteCliente= async (token, id) => {
    var bearer = 'Bearer ' + token;
        let response = await fetch('https://face-dracars.es:8443/v1/deleteCliente?id='.concat(id),{
            method:'DELETE',
            cache:'no-cache',
            headers:{
                'Content-type': 'application/json',
                'Authorization': bearer
            }
        });
        return response;
    }

export const findClientes = async (token, textos) => {
    var bearer = 'Bearer ' + token;
    var body =  JSON.stringify(textos);
   
    let response = await fetch('https://face-dracars.es:8443/v1/findClientes',{
        method:'POST',
        cache:'no-cache',
        headers:{
            'Content-type': 'application/json',
            'Authorization': bearer
        },
        body
    });
    
    return response.json();
}  

export const findClienteById = async (token, id) => {
    var bearer = 'Bearer ' + token;
   
    let response = await fetch('https://face-dracars.es:8443/v1/findClienteById?id='.concat(id),{
        method:'GET',
        cache:'no-cache',
        headers:{
            'Content-type': 'application/json',
            'Authorization': bearer
        }
    });
    
    return response.json();
}  

export const findClienteByNombre = async (token, nombre) => {
    var bearer = 'Bearer ' + token;
   
    let response = await fetch('https://face-dracars.es:8443/v1/findClienteByNombre?nombre='.concat(nombre),{
        method:'GET',
        cache:'no-cache',
        headers:{
            'Content-type': 'application/json',
            'Authorization': bearer
        }
    });
    
    return response.json();
}  

export const findClienteByCif = async (token, cif) => {
    var bearer = 'Bearer ' + token;
   
    let response = await fetch('https://face-dracars.es:8443/v1/findClienteByCif?cif='.concat(cif),{
        method:'GET',
        cache:'no-cache',
        headers:{
            'Content-type': 'application/json',
            'Authorization': bearer
        }
    });
    
    return response.json();
}  

export const getAllProveedores = async (token) => {
    var bearer = 'Bearer ' + token;
        let response = await fetch('https://face-dracars.es:8443/v1/getAllProveedores',{
            method:'GET',
            cache:'no-cache',
            headers:{
                'Content-type': 'application/json',
                'Authorization': bearer
            }
        });
    return response.json();
 }
    
export const findProveedorById = async (token, id) => {
    var bearer = 'Bearer ' + token;
   
    let response = await fetch('https://face-dracars.es:8443/v1/findProveedorById?id='.concat(id),{
        method:'GET',
        cache:'no-cache',
        headers:{
            'Content-type': 'application/json',
            'Authorization': bearer
        }
    });
    
    return response.json();
}  

export const findProveedorByNombre = async (token, nombre) => {
    var bearer = 'Bearer ' + token;
   
    let response = await fetch('https://face-dracars.es:8443/v1/findProveedorByNombre?nombre='.concat(nombre),{
        method:'GET',
        cache:'no-cache',
        headers:{
            'Content-type': 'application/json',
            'Authorization': bearer
        }
    });
    
    return response.json();
}  

export const findProveedorByCif = async (token, cif) => {
    var bearer = 'Bearer ' + token;
   
    let response = await fetch('https://face-dracars.es:8443/v1/findProveedorByCif?cif='.concat(cif),{
        method:'GET',
        cache:'no-cache',
        headers:{
            'Content-type': 'application/json',
            'Authorization': bearer
        }
    });
    
    return response.json();
}  
export const createProveedor = async (token,proveedor) => {
    var bearer = 'Bearer ' + token;
    var body =  JSON.stringify({
        "nombre": proveedor.nombre,
        "nombreComercial": proveedor.nombreComercial,
        "cif": proveedor.cif,
        "direccion": proveedor.direccion,
        "cp": proveedor.cp,
        "provincia": proveedor.provincia,
        "poblacion": proveedor.poblacion,
        "tributacion":proveedor.tributacion,
        "tipoDePago":proveedor.tipoDePago,
        "movil":proveedor.movil,
        "telefono": proveedor.telefono,
        "email":proveedor.email,
        "horario":proveedor.horario,
        "contactoComercial":proveedor.contactoComercial,
        "numeroComercial":proveedor.numeroComercial,
        "observaciones":proveedor.observaciones
    });

    let response = await fetch('https://face-dracars.es:8443/v1/createProveedor',{
        method:'POST',
        cache:'no-cache',
        headers:{
            'Content-type': 'application/json',
            'Authorization': bearer
        },
        body
    });
    return response.json();
}

export const updateProveedor = async (token, id, key, value) => {
var bearer = 'Bearer ' + token;
var body =  JSON.stringify({
[key]:value
});
let response = await fetch('https://face-dracars.es:8443/v1/updateProveedor?id='.concat(id),{
    method:'PATCH',
    cache:'no-cache',
    headers:{
        'Content-type': 'application/json',
        'Authorization': bearer
    },
    body
});
return response;
}

export const findProveedores = async (token, textos) => {
    var bearer = 'Bearer ' + token;
    var body =  JSON.stringify(textos);
   
    let response = await fetch('https://face-dracars.es:8443/v1/findProveedores',{
        method:'POST',
        cache:'no-cache',
        headers:{
            'Content-type': 'application/json',
            'Authorization': bearer
        },
        body
    });
    
    return response.json();
}  

export const getAllAlbaranes = async (token) => {
    var bearer = 'Bearer ' + token;
        let response = await fetch('https://face-dracars.es:8443/v1/getAllAlbaranes',{
            method:'GET',
            cache:'no-cache',
            headers:{
                'Content-type': 'application/json',
                'Authorization': bearer
            }
        });
        return response.json();
    }

    export const getAlbaranesByClienteId = async (token, id) => {
        var bearer = 'Bearer ' + token;
            let response = await fetch('https://face-dracars.es:8443/v1/getAlbaranesByClienteId?id='.concat(id),{
                method:'GET',
                cache:'no-cache',
                headers:{
                    'Content-type': 'application/json',
                    'Authorization': bearer
                }
            });
            return response.json();
        }
    
    export const createAlbaran = async (token,albaran) => {
        var bearer = 'Bearer ' + token;
        var body =  JSON.stringify({
            "id": albaran.id,
            "numeroAlbaran": albaran.numeroAlbaran,
            "cliente": albaran.cliente,
            "fecha": albaran.fecha,
            "fechaEntrega": albaran.fechaEntrega,
            "estado": albaran.estado,
            "observaciones": albaran.observaciones,
            "articulos": albaran.articulos,
            "responsable": albaran.responsable
        });
            let response = await fetch('https://face-dracars.es:8443/v1/createAlbaran',{
                method:'POST',
                cache:'no-cache',
                headers:{
                    'Content-type': 'application/json',
                    'Authorization': bearer
                },
                body
            });
            return response.json();
        }
    
    export const updateAlbaran = async (token, id, albaran) => {
        var bearer = 'Bearer ' + token;
        var body =  JSON.stringify(albaran);
       console.log(body);
        let response = await fetch('https://face-dracars.es:8443/v1/updateAlbaran?id='.concat(id),{
            method:'PATCH',
            cache:'no-cache',
            headers:{
                'Content-type': 'application/json',
                'Authorization': bearer
            },
            body
        });
        
        return response;
    }     
    
    export const getAlbaran = async (token, id) => {
        var bearer = 'Bearer ' + token;
            let response = await fetch('https://face-dracars.es:8443/v1/getAlbaran?id='.concat(id),{
                method:'GET',
                cache:'no-cache',
                headers:{
                    'Content-type': 'application/json',
                    'Authorization': bearer
                }
            });
            return response.json();
        }

        export const deleteAlbaran = async (token, id) => {
            var bearer = 'Bearer ' + token;
                let response = await fetch('https://face-dracars.es:8443/v1/deleteAlbaran?id='.concat(id),{
                    method:'DELETE',
                    cache:'no-cache',
                    headers:{
                        'Content-type': 'application/json',
                        'Authorization': bearer
                    }
                });
                return response;
            }


            export const findAlbaranes = async (token, textos) => {
                var bearer = 'Bearer ' + token;
                var body =  JSON.stringify(textos);
               
                let response = await fetch('https://face-dracars.es:8443/v1/findAlbaranes',{
                    method:'POST',
                    cache:'no-cache',
                    headers:{
                        'Content-type': 'application/json',
                        'Authorization': bearer
                    },
                    body
                });
                
                return response.json();
            }    

    export const getAllFacturas = async (token) => {
    var bearer = 'Bearer ' + token;
        let response = await fetch('https://face-dracars.es:8443/v1/getAllFacturas',{
            method:'GET',
            cache:'no-cache',
            headers:{
                'Content-type': 'application/json',
                'Authorization': bearer
            }
        });
        return response.json();
    }

    export const getFacturasByClienteId = async (token, id) => {
        var bearer = 'Bearer ' + token;
            let response = await fetch('https://face-dracars.es:8443/v1/getFacturasByClienteId?id='.concat(id),{
                method:'GET',
                cache:'no-cache',
                headers:{
                    'Content-type': 'application/json',
                    'Authorization': bearer
                }
            });
            return response.json();
        }

    export const getFacturasPendientes = async (token) => {
        var bearer = 'Bearer ' + token;
            let response = await fetch('https://face-dracars.es:8443/v1/getFacturasPendientes',{
                method:'GET',
                cache:'no-cache',
                headers:{
                    'Content-type': 'application/json',
                    'Authorization': bearer
                }
            });
            return response.json();
        }

        export const getFacturasEmitidas = async (token) => {
            var bearer = 'Bearer ' + token;
                let response = await fetch('https://face-dracars.es:8443/v1/getFacturasEmitidas',{
                    method:'GET',
                    cache:'no-cache',
                    headers:{
                        'Content-type': 'application/json',
                        'Authorization': bearer
                    }
                });
                return response.json();
            }

        export const getFacturasPagadas = async (token) => {
            var bearer = 'Bearer ' + token;
                let response = await fetch('https://face-dracars.es:8443/v1/getFacturasPagadas',{
                    method:'GET',
                    cache:'no-cache',
                    headers:{
                        'Content-type': 'application/json',
                        'Authorization': bearer
                    }
                });
                return response.json();
            }
    
    export const createFactura = async (token,factura) => {
        
        var bearer = 'Bearer ' + token;
        var body =  JSON.stringify({
            "id":factura.id,
            "codigoFactura": factura.codigoFactura,
            "cliente":factura.cliente,
            "fecha": factura.fecha,
            "estado": factura.estado,
            "observaciones": factura.observaciones,
            "totalSinIva": factura.totalSinIva,
            "totalConIva": factura.totalConIva,
            "albaranes": factura.albaranes,
        });

            let response = await fetch('https://face-dracars.es:8443/v1/createFactura',{
                method:'POST',
                cache:'no-cache',
                headers:{
                    'Content-type': 'application/json',
                    'Authorization': bearer
                },
                body
            });
            return response.json();
        }
    
    export const updateFactura = async (token, id, factura) => {
        var bearer = 'Bearer ' + token;
        var body =  JSON.stringify(factura);
    
        let response = await fetch('https://face-dracars.es:8443/v1/updateFactura?id='.concat(id),{
            method:'PATCH',
            cache:'no-cache',
            headers:{
                'Content-type': 'application/json',
                'Authorization': bearer
            },
            body
        });
    
        return response;
    }

    export const findFacturas = async (token, textos) => {
        var bearer = 'Bearer ' + token;
        var body =  JSON.stringify(textos);
       
        let response = await fetch('https://face-dracars.es:8443/v1/findFacturas',{
            method:'POST',
            cache:'no-cache',
            headers:{
                'Content-type': 'application/json',
                'Authorization': bearer
            },
            body
        });
        
        return response.json();
    }  


    export const getAllAlbaranesP = async (token) => {
        var bearer = 'Bearer ' + token;
            let response = await fetch('https://face-dracars.es:8443/v1/getAllAlbaranesP',{
                method:'GET',
                cache:'no-cache',
                headers:{
                    'Content-type': 'application/json',
                    'Authorization': bearer
                }
            });
            return response.json();
        }
        
        export const createAlbaranP = async (token,albaran) => {
            var bearer = 'Bearer ' + token;
            var body =  JSON.stringify({
                "id": albaran.id,
                "numeroAlbaran": albaran.numeroAlbaran,
                "proveedor": albaran.proveedor,
                "fecha": albaran.fecha,
                "fechaEntrega": albaran.fechaEntrega,
                "estado": albaran.estado,
                "observaciones": albaran.observaciones,
                "articulos": albaran.articulos,
            });
            
                let response = await fetch('https://face-dracars.es:8443/v1/createAlbaranP',{
                    method:'POST',
                    cache:'no-cache',
                    headers:{
                        'Content-type': 'application/json',
                        'Authorization': bearer
                    },
                    body
                });
                return response.json();
            }
        
    export const updateAlbaranP = async (token, id, albaran) => {
        var bearer = 'Bearer ' + token;
        var body =  JSON.stringify(albaran);
       console.log(body);
        let response = await fetch('https://face-dracars.es:8443/v1/updateAlbaranP?id='.concat(id),{
            method:'PATCH',
            cache:'no-cache',
            headers:{
                'Content-type': 'application/json',
                'Authorization': bearer
            },
            body
        });
        
        return response;
    }     
        
        export const getAlbaranP = async (token, id) => {
            var bearer = 'Bearer ' + token;
                let response = await fetch('https://face-dracars.es:8443/v1/getAlbaranP?id='.concat(id),{
                    method:'GET',
                    cache:'no-cache',
                    headers:{
                        'Content-type': 'application/json',
                        'Authorization': bearer
                    }
                });
                return response.json();
            }

            export const deleteAlbaranP = async (token, id) => {
                var bearer = 'Bearer ' + token;
                    let response = await fetch('https://face-dracars.es:8443/v1/deleteAlbaranP?id='.concat(id),{
                        method:'DELETE',
                        cache:'no-cache',
                        headers:{
                            'Content-type': 'application/json',
                            'Authorization': bearer
                        }
                    });
                    return response;
                }
        
                export const findAlbaranesP = async (token, textos) => {
                    var bearer = 'Bearer ' + token;
                    var body =  JSON.stringify(textos);
                   
                    let response = await fetch('https://face-dracars.es:8443/v1/findAlbaranesP',{
                        method:'POST',
                        cache:'no-cache',
                        headers:{
                            'Content-type': 'application/json',
                            'Authorization': bearer
                        },
                        body
                    });
                    
                    return response.json();
                }  

        export const getAllFacturasP = async (token) => {
        var bearer = 'Bearer ' + token;
            let response = await fetch('https://face-dracars.es:8443/v1/getAllFacturasP',{
                method:'GET',
                cache:'no-cache',
                headers:{
                    'Content-type': 'application/json',
                    'Authorization': bearer
                }
            });
            return response.json();
        }
        
        
        export const createFacturaP = async (token,factura) => {
            var bearer = 'Bearer ' + token;
            var body =  JSON.stringify({
                "id":factura.id,
                "codigoFactura": factura.codigoFactura,
                "proveedor":factura.proveedor,
                "fecha": factura.fecha,
                "estado": factura.estado,
                "observaciones": factura.observaciones,
                "totalSinIva": factura.totalSinIva,
                "totalConIva": factura.totalConIva,
                "document": factura.document,
                "albaranes": factura.albaranes,
            });
                let response = await fetch('https://face-dracars.es:8443/v1/createFacturaP',{
                    method:'POST',
                    cache:'no-cache',
                    headers:{
                        'Content-type': 'application/json',
                        'Authorization': bearer
                    },
                    body
                });
                return response.json();
            }
        
        export const updateFacturaP = async (token, id, facturaP) => {
            var bearer = 'Bearer ' + token;
            var body =  JSON.stringify(facturaP);

            let response = await fetch('https://face-dracars.es:8443/v1/updateFacturaP?id='.concat(id),{
                method:'PATCH',
                cache:'no-cache',
                headers:{
                    'Content-type': 'application/json',
                    'Authorization': bearer
                },
                body
            });
        
            return response;
        }

        export const findFacturasP = async (token, textos) => {
            var bearer = 'Bearer ' + token;
            var body =  JSON.stringify(textos);
           
            let response = await fetch('https://face-dracars.es:8443/v1/findFacturasP',{
                method:'POST',
                cache:'no-cache',
                headers:{
                    'Content-type': 'application/json',
                    'Authorization': bearer
                },
                body
            });
            
            return response.json();
        }  

        export const generarFactura = async (token, id) => {
            var bearer = 'Bearer ' + token;
            let response = await fetch('https://face-dracars.es:8443/v1/generarFactura?id='.concat(id),{
                method:'GET',
                cache:'no-cache',
                headers:{
                    'Authorization': bearer
                }
            });
        
            return response;
        }
        
        export const generarAlbaran = async (token, id) => {
            var bearer = 'Bearer ' + token;
            let response = await fetch('https://face-dracars.es:8443/v1/generarAlbaran?id='.concat(id),{
                method:'GET',
                cache:'no-cache',
                headers:{
                    'Authorization': bearer
                }
            });
        
            return response;
        }

export const generarModelo347Clientes= async (token, year) => {
    var bearer = 'Bearer ' + token;
    let response = await fetch('https://face-dracars.es:8443/v1/generarModelo347Clientes?year='.concat(year),{
        method:'GET',
        cache:'no-cache',
        headers:{
            'Authorization': bearer
        }
    });

    return response;
}

export const generarModelo347Proveedores= async (token, year) => {
    var bearer = 'Bearer ' + token;
    let response = await fetch('https://face-dracars.es:8443/v1/generarModelo347Proveedores?year='.concat(year),{
        method:'GET',
        cache:'no-cache',
        headers:{
            'Authorization': bearer
        }
    });

    return response;
}

export const generarCierreEmitidas= async (token, year, trimestre) => {
    var bearer = 'Bearer ' + token;
    let response = await fetch('https://face-dracars.es:8443/v1/generarCierreEmitidas?year='.concat(year).concat('&').concat('trimestre=').concat(trimestre),{
        method:'GET',
        cache:'no-cache',
        headers:{
            'Authorization': bearer
        }
    });

    return response;
}

export const generarCierreRecibidas= async (token, year, trimestre) => {
    var bearer = 'Bearer ' + token;
    let response = await fetch('https://face-dracars.es:8443/v1/generarCierreRecibidas?year='.concat(year).concat('&').concat('trimestre=').concat(trimestre),{
        method:'GET',
        cache:'no-cache',
        headers:{
            'Authorization': bearer
        }
    });

    return response;
}

export const generarCierreCliente= async (token, cliente, year, trimestre) => {
    var bearer = 'Bearer ' + token;
    let response = await fetch('https://face-dracars.es:8443/v1/generarCierreCliente?id='.concat(cliente).concat('&').concat('year=').concat(year).concat('&').concat('trimestre=').concat(trimestre),{
        method:'GET',
        cache:'no-cache',
        headers:{
            'Authorization': bearer
        }
    });

    return response;
}

export const generarVistaPrevia= async (token, albaranes) => {
    var bearer = 'Bearer ' + token;
    var body = JSON.stringify(albaranes);

    let response = await fetch('https://face-dracars.es:8443/v1/generarVistaPrevia',{
        method:'POST',
        cache:'no-cache',
        headers:{
            'Authorization': bearer,
            'Content-type': 'application/json'
        },
        body
    });

    return response;
}

export const subirFacturaProveedor= async (token, id, formData) => {
    var bearer = 'Bearer ' + token;
    let response = await fetch('https://face-dracars.es:8443/v1/subirFacturaProveedor?id='.concat(id),{
        method:'POST',
        cache:'no-cache',
        headers:{
            'Authorization': bearer
        },
        body:formData
    });

    return response;
}

export const descargarFacturaProveedor= async (token, id) => {
    var bearer = 'Bearer ' + token;
    let response = await fetch('https://face-dracars.es:8443/v1/descargarFacturaProveedor?id='.concat(id),{
        method:'GET',
        cache:'no-cache',
        headers:{
            'Authorization': bearer
        }
    });

    return response;
}

export const getAllUsers = async (token) => {
    var bearer = 'Bearer ' + token;
        let response = await fetch('https://face-dracars.es:8443/v1/getAllUsers',{
            method:'GET',
            cache:'no-cache',
            headers:{
                'Content-type': 'application/json',
                'Authorization': bearer
            }
        });
    return response.json();
}

export const createUser = async (token,user) => {
    var bearer = 'Bearer ' + token;
    var body =  JSON.stringify({
        "id": user.id,
        "name": user.name,
        "usuario": user.usuario,
        "password": user.password,
        "email": user.email,
        "roles":"ADMIN"
    });

let response = await fetch('https://face-dracars.es:8443/v1/createUser',{
    method:'POST',
    cache:'no-cache',
    headers:{
        'Content-type': 'application/json',
        'Authorization': bearer
    },
    body
});
return response.json();
}

export const updateUser = async (token, id, key, value) => {
var bearer = 'Bearer ' + token;
var body =  JSON.stringify({
    [key]:value
});

    let response = await fetch('https://face-dracars.es:8443/v1/updateUser?id='.concat(id),{
        method:'PATCH',
        cache:'no-cache',
        headers:{
            'Content-type': 'application/json',
            'Authorization': bearer
        },
        body
    });
    return response;
}

export const getUser = async (token, username) => {
    var bearer = 'Bearer ' + token;

        let response = await fetch('https://face-dracars.es:8443/v1/getUser?username='.concat(username),{
            method:'GET',
            cache:'no-cache',
            headers:{
                'Content-type': 'application/json',
                'Authorization': bearer
            }
        });
        return response.json();
    }

    export const changePass = async (token, id, actual, nueva) => {
        var bearer = 'Bearer ' + token;
        var body =  JSON.stringify({
            "actual": actual,
            "nueva": nueva
        });
    
    let response = await fetch('https://face-dracars.es:8443/v1/changePass?id='.concat(id),{
        method:'POST',
        cache:'no-cache',
        headers:{
            'Content-type': 'application/json',
            'Authorization': bearer
        },
        body
    });
    return response.json();
    }

    export const findUsuarios = async (token, textos) => {
        var bearer = 'Bearer ' + token;
        var body =  JSON.stringify(textos);
       
        let response = await fetch('https://face-dracars.es:8443/v1/findUsuarios',{
            method:'POST',
            cache:'no-cache',
            headers:{
                'Content-type': 'application/json',
                'Authorization': bearer
            },
            body
        });
        
        return response.json();
    }  

    export const createEvento = async (token, evento, usuario, fecha) => {
        var bearer = 'Bearer ' + token;
        var body =  JSON.stringify({
            "evento": evento,
            "usuario": usuario,
            "fecha": fecha
        });
    let response = await fetch('https://face-dracars.es:8443/v1/createEvento',{
        method:'POST',
        cache:'no-cache',
        headers:{
            'Content-type': 'application/json',
            'Authorization': bearer
        },
        body
    });
    return response.json();
    }

    export const getAllEvents = async (token) => {
        var bearer = 'Bearer ' + token;
            let response = await fetch('https://face-dracars.es:8443/v1/getAllEvents',{
                method:'GET',
                cache:'no-cache',
                headers:{
                    'Content-type': 'application/json',
                    'Authorization': bearer
                }
            });
        return response.json();
    }

    export const findEventos = async (token, textos) => {
        var bearer = 'Bearer ' + token;
        var body =  JSON.stringify(textos);
       
        let response = await fetch('https://face-dracars.es:8443/v1/findEventos',{
            method:'POST',
            cache:'no-cache',
            headers:{
                'Content-type': 'application/json',
                'Authorization': bearer
            },
            body
        });
        
        return response.json();
    }  