import React, {useState, useEffect } from 'react';
import SidebarComponent from './sidebarComponent';
import PropTypes from 'prop-types';
import PedidosCComponent from './pedidosCComponent';
import PedidosPComponent from './pedidosPComponent';
import ArticulosComponent from './articulosComponent';
import ClientesComponent from './clientesComponent';
import ProveedoresComponent from './proveedoresComponent';
import InformesComponent from './informesComponent';
import UsuariosComponent from './usuariosComponent';
import PerfilComponent from './perfilComponent';
import RegistroComponent from './registroComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createEvento } from '../../services/fecthService';
import { render } from '@testing-library/react';


const ContainerComponent = ({data, actualizarMenu}) => {

     // eslint-disable-next-line no-restricted-globals
     const [screenWidth, setScreenWidth] = useState(screen.width);
     // eslint-disable-next-line no-restricted-globals
     const [screenHeight, setScreenHeight] = useState(screen.height);

     useEffect(() => {
        const actualizarDimensiones = () => {
          // eslint-disable-next-line no-restricted-globals
          setScreenWidth(screen.width);
              
          // eslint-disable-next-line no-restricted-globals
          setScreenHeight(screen.height);
  
        };
        // Agregamos un event listener para el evento de cambio de tamaño de la ventana
        window.addEventListener('resize', actualizarDimensiones);
        console.log('El atributo active de data ha cambiado:', data.active);

        // Limpieza del event listener cuando el componente se desmonta
        return () => {
          window.removeEventListener('resize', actualizarDimensiones);
        };
      }, [data.active]); 

    function toasting(mensaje, result){
        if(result == 'success'){
            toast.success((mensaje), {
                position: "bottom-right",
                autoClose: 1100,
                color:"green",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                width: "18vh",
                toastId: 'success2'
            });
        }else if(result == 'error'){
            toast.error((mensaje), {
                position: "bottom-right",
                autoClose: 1200,
                color:"green",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                toastId: 'success2'
            });
        }
    }

    function mostrarSeccionPrincipal(){
        if(data.active === 1){
            return <PedidosCComponent data = {data} toasting={toasting} registrarEvento={registrarEvento}></PedidosCComponent>;
        }else if(data.active === 2){
            return <ClientesComponent data = {data} toasting={toasting} registrarEvento={registrarEvento}></ClientesComponent>;
        }else if(data.active === 3){
            return <ArticulosComponent data = {data} toasting={toasting} registrarEvento={registrarEvento}></ArticulosComponent>;
        }else if(data.active === 4){
            return <PedidosPComponent data = {data} toasting={toasting} registrarEvento={registrarEvento}></PedidosPComponent>;
        }else if(data.active === 5){
            return <ProveedoresComponent data = {data} toasting={toasting} registrarEvento={registrarEvento}></ProveedoresComponent>;
        }else if(data.active === 6){
            return <InformesComponent data = {data} toasting={toasting} registrarEvento={registrarEvento}></InformesComponent>;
        }else if(data.active === 7){
            return <UsuariosComponent data = {data} toasting={toasting} registrarEvento={registrarEvento}></UsuariosComponent>;
        }else if(data.active === 8){
            return <PerfilComponent data = {data} toasting={toasting} registrarEvento={registrarEvento}></PerfilComponent>;
        }else if(data.active === 9){
            return <RegistroComponent data = {data} toasting={toasting}></RegistroComponent>;
        }else{
            return "";
        }
    }

    function registrarEvento(evento){
        createEvento(data.token, evento, data.username, new Date())
        .then((response) => {
        }) 
        .catch((error)=>{
            toasting('Error al registrar el evento', 'error');
        })
        .finally(() => {
        });
    }

    function renderContainer(){

        if(screenWidth < 758){
            return (
                <div className='col-12 content-container'>
                    {mostrarSeccionPrincipal()}
                </div>
            );
        }else if(screenWidth > 1300){
            return (
                <div className='container-fluid main-container'>
                    <div className='col-2 sidebar-container'>
                       <SidebarComponent actualizarMenu = {actualizarMenu}></SidebarComponent>
                     </div>
                    <div  className='col-10 content-container'>
                        {mostrarSeccionPrincipal()}
                    </div>
                    <ToastContainer></ToastContainer>
                </div>
            );
        }

        
    }

    return (
        renderContainer()
    );
};
ContainerComponent.propTypes = {

};
export default ContainerComponent;
