export class Albaran{
    
    id='';
    numeroAlbaran='';
    cliente='';
    fecha='';
    fechaEntrega='';
    estado='';
    observaciones='';

    articulos=[];
    responsable='';
    
    constructor(id, numeroAlbaran, cliente, fecha, fechaEntrega, estado, observaciones, articulos, responsable){
        this.id=id;
        this.numeroAlbaran=numeroAlbaran;
        this.cliente = cliente;
        this.fecha = fecha;
        this.fechaEntrega = fechaEntrega;
        this.estado = estado;
        this.observaciones = observaciones;
        this.articulos = articulos;
        this.responsable = responsable;
    }
}