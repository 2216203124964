import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { event } from 'jquery';
import Autocomplete from '@mui/material/Autocomplete';


const FacturaProveedorComponent = ({factura, checkboxChanged, active, modificarFactura, facturaarUno, facturarUno, pagar, articulos, subirFactura, descargarFactura, sayDatosFacturaProveedorModal}) => {

    function setStyle(){
        let style = {};

        if(factura.estado === 'Facturado')
            style['display'] = 'hidden';
            style['opacity'] = '0%';

        return style;
    }
    
    function estados(){
        if(factura.estado === "PENDIENTE"){
            return (<select className='selectEstados' onChange={() => enviarFactura()}>
                        <option value="PENDIENTE" selected>Pendiente de pago</option>
                        <option value="PAGADA">Pagada</option>
                    </select>);
        }else if(factura.estado === "PAGADA"){
            return ("Pagada");
        }
    }

    function enviarFactura(){
        modificarFactura(factura.id,{ 'estado': 'PAGADA'});
    }
   

    function pagarFactura(){
        pagar(factura.id);
    }
    function redondear(precio){
    
        if(precio == '' || precio == undefined){
            return '';
        }else{
            return parseFloat(precio).toFixed(2);
        }
    }

    function formatearFecha(item){
        var date = new Date(item);
        var split = date.toLocaleDateString();
        var split2 = split.split('/');
        
        var day = split2[0];
        var month = split2[1];
        var year = split2[2];
        if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
            day = '0'.concat(day);
        }
        if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
            month = '0'.concat(month);
        }
        return day.concat('-').concat(month).concat('-').concat(year);
    }

    function formatearFechaAlReves(item){
        var date = new Date(item);
        var split = date.toLocaleDateString();
        var split2 = split.split('/');
        
        var day = split2[0];
        var month = split2[1];
        var year = split2[2];
        if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
            day = '0'.concat(day);
        }
        if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
            month = '0'.concat(month);
        }
        return year.concat('-').concat(month).concat('-').concat(day);
    }

    function handleOnKeyUp(event, name){
        if (event.keyCode === 13) {
            modificarFactura(factura.id,{ name: event.target.value});
        }
    }

    function renderArticulos(){
        let articles = [];
        let elementosHTML = [];
        factura.albaranes.map((albaran) => {
            albaran.articulos.map((articulo) => {
            var a = articulo.id;
            elementosHTML.push(
                <div key={'pedido'.concat(factura.id).concat('CollapseRow').concat(a)} id={'pedido'.concat(factura.id).concat('CollapseRow').concat(a)} className={'pcarow w100 pedidoC'.concat(factura.id)}>
                    <input disabled className='pcarfield w7_5' value={albaran.id} id={'pcarfield'.concat(albaran.id).concat(a)} ></input>
                    <input disabled className='pcarfield w10' value={articulo.idArticulo} id={'pcarfieldId'.concat(a)} ></input>
                    <Autocomplete disabled
                            freeSolo
                            value={articulo.nombre}
                            style={{fontSize:'1.5vh', width:'20%', margin: '0 0.2vw 0 0.2vw'}}
                            id={'pcarfieldNombre'.concat(a)}
                            renderInput={(params) => (
                                <div style={{width:'100%'}} ref={params.InputProps.ref}>
                                    <input style={{fontSize:'1.5vh',border: '1px solid lightgrey', borderRadius: '5px', width: '100%'}} type="text" {...params.inputProps} 
                                    />
                                </div>
                            )}
                        />                    
                    <input disabled className='pcarfield w7_5' value={articulo.cantidad} id={'pcarfieldUnidades'.concat(a)}></input>
                    <input disabled className='pcarfield w7_5' value={redondear(articulo.precio)} id={'pcarfieldPrecio'.concat(a)} ></input>
                    <input disabled className='pcarfield w7_5' value={articulo.descuento} id={'pcarfieldDescuento'.concat(a)} ></input>
                    <input disabled className='pcarfield w7_5' value={articulo.porcentajeIva} id={'pcarfieldPorcentajeIva'.concat(a)} ></input>
                    <input disabled className='pcarfield w7_5' value={articulo.importeIva} id={'pcarfieldImporteIva'.concat(a)}></input>
                    <input disabled className='pcarfield w10' id={'pcarfieldSubtotal'.concat(a)} value={articulo.subtotal}></input>
                    
                </div>);
                articles.push(articulo);
            });
        });
            

        return elementosHTML;
    }

    function subirFacturaProveedor(){
        subirFactura(factura.id, document.getElementById('cargarArchivo'.concat(factura.id)).files[0]);
    }

    function descargarFacturaProveedor(){
        descargarFactura(factura.id, factura.codigoFactura);
    }  

    function cambiarModal(){
        sayDatosFacturaProveedorModal(factura);
    }

    return (
        <div>
            <tr key={factura.id} className='listado-row'>  
            <td>
                <div className="form-check">
                    <input className="form-check-input" disabled style = {setStyle()} type="checkbox" value="" id="flexCheckDefault" onClick={(event) => checkboxChanged(event, factura.id)}></input>
                </div>
            </td>     
                
            <td className='listado-field w7_5'>{factura.codigoFactura}</td>
                    <td className='listado-field w7_5'>{factura.proveedor.id}</td>
                    <td className='listado-field w40 bold'>{factura.proveedor.nombreComercial}</td>
                    <td className='listado-field w10'>{factura.fecha != null ? formatearFecha(factura.fecha):'-'}</td>
                    <td className='listado-field w15'>
                        {estados()}
                    </td>
                    <td className='listado-field w10'>{
                        factura.totalConIva.toFixed(2) + '€'
                     }</td>
                <div className='acciones'>
                    <i className="bi bi-eye hover" title="Ver datos" data-bs-toggle="modal" data-bs-target={'#datosFacturaProveedorModal'}  onClick={() => cambiarModal()} ></i>
                    {factura.document == 'SI' 
                    ? (<i className='bi bi-printer hover' title="Imprimir" onClick={() => descargarFacturaProveedor()}></i>)
                    : (<div class="file-select" id="src-file1" >
                        <i className='bi bi-upload hover' title="Cargar factura" onClick={() => document.getElementById('cargarArchivo'.concat(factura.id)).click()}></i>
                        <input type="file"  id={"cargarArchivo".concat(factura.id)} onChange={() => subirFacturaProveedor()}></input>
                    </div>)
                    }
                </div>
            </tr>
            <div className="collapse" id={'collapseExample'.concat(factura.id)}>
                <div className="card card-body">
                <h6 className='nuevo-proveedor-title'>Datos generales</h6>
                    <div className='collapse-row'>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Nº factura</label>
                            <input className='cfinput w65' id='cfCodigoFactura' name='observaciones' defaultValue={factura.codigoFactura} onKeyUp={(event) => handleOnKeyUp(event, 'codigoFactura')}></input>
                        </div>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Fecha</label>
                            <input name="fecha" defaultValue={factura.fecha != null ? formatearFechaAlReves(factura.fecha):undefined} className='cfinput w65' type='date'onKeyUp={(event) => handleOnKeyUp(event, 'fecha')} id='pedidoC-modal-field-fecha'></input>
                        </div> 
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Fecha contable</label>
                            <input name="fechaContable" defaultValue={factura.fechaContable != null ? formatearFechaAlReves(factura.fechaContable):undefined} className='cfinput w65' type='date' onKeyUp={(event) => handleOnKeyUp(event, 'fechaContable')} id='pedidoC-modal-field-fechaContable'></input>
                        </div> 
                    </div>

                    <div className='separator'></div>
                    <h6 className='collapse-title'>Datos de proveedor</h6>
                    <div className='collapse-row'>
                        <div className='collapse-field w100'>
                            <label className='cflabel w25'>Proveedor</label>
                            <Autocomplete
                                disabled
                                style={{width:'50%', fontSize:'1.5vh'}}
                                freeSolo
                                defaultValue={"Código: ".concat(factura.proveedor.id).concat(" | Nombre: ").concat(factura.proveedor.nombreComercial).concat(" | CIF: ").concat(factura.proveedor.cif)}
                                id="autocomplete-proveedor"
                                renderInput={(params) => (
                                    <div style={{width:'100%'}} ref={params.InputProps.ref}>
                                        <input style={{ border: '1px solid lightgrey', borderRadius: '3px', width: '100%'}} type="text" {...params.inputProps}/>
                                    </div>
                                )}
                            />                        
                        </div>
                    </div>
                    
                    <div className='separator'></div>

                    <h6 className='modal-title'>Otros</h6>
                <div className='modal-row'>
                    <label className='cflabel w8_75'>Observaciones</label>
                    <textarea disabled={factura.estado != 'PENDIENTE'} defaultValue={factura.observaciones} className='cftextarea w91_75' name='observaciones' onKeyUp={(event) => handleOnKeyUp(event, 'observaciones')} id='pedidoC-modal-field-observaciones'></textarea>   
                </div>

                <div className='separator'></div>
                    <h6 className='collapse-title'>Artículos</h6>
                <div className='pedido-modal-articulos-table w100'>
                    <div className='pcaheader w100'>
                        <label className='pcahfield w7_5'>Albarán</label>
                        <label className='pcahfield w10'>Cód. artículo</label>
                        <label className='pcahfield w20'>Nombre</label>
                        <label className='pcahfield w7_5'>Unidades</label>
                        <label className='pcahfield w7_5'>Precio</label>
                        <label className='pcahfield w7_5'>Descuento</label>
                        <label className='pcahfield w7_5'>IVA %</label>
                        <label className='pcahfield w7_5'>Importe IVA</label>
                        <label className='pcahfield w10'>Subtotal</label>
                        
                    </div>
                    {renderArticulos().map((item) => {
                        return item;
                    })}
                    </div>
                </div>
            </div>
        </div>
    );
};


FacturaProveedorComponent.propTypes = {

};


export default FacturaProveedorComponent;
