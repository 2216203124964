import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArticuloComponent from '../pure/articuloComponent';
import { Articulo } from '../../models/articulo.class';
import '../../styles/styles.scss'

const ModalArticuloComponent = ({saveNuevoarticulo}) => {

    function nuevoArticulo(){
        
        const articulo = new Articulo(
            "",
            document.getElementById("articulo-modal-field-nombre").value,
            document.getElementById("articulo-modal-field-familia").value,
            document.getElementById("articulo-modal-field-subfamilia").value,
            document.getElementById("articulo-modal-field-descripcion").value,
            document.getElementById("articulo-modal-field-stockMin").value,
            document.getElementById("articulo-modal-field-stockMax").value,
            document.getElementById("articulo-modal-field-unidades").value,
            document.getElementById("articulo-modal-field-observaciones").value,
            document.getElementById("articulo-modal-field-precio").value,
            document.getElementById("articulo-modal-field-iva").value,
            document.getElementById("articulo-modal-field-precioProveedor").value,
            document.getElementById("articulo-modal-field-publicable").value,
            document.getElementById("articulo-modal-field-rotura").value,
            document.getElementById("articulo-modal-field-descatalogado").value,
            document.getElementById("articulo-modal-field-portada").value,
            document.getElementById("articulo-modal-field-masVendido").value,
            document.getElementById("articulo-modal-field-novedades").value,
            document.getElementById("articulo-modal-field-codigoEAN13").value
            );

        saveNuevoarticulo(articulo);
        limpiarModal();
        document.getElementById('closeArticuloModal').click();
    }

    function calcularPrecioIva(){
        if(document.getElementById('articulo-modal-field-precio').value != '' && document.getElementById('articulo-modal-field-iva').value != ''){
            document.getElementById('articulo-modal-field-precioIva').value = (document.getElementById('articulo-modal-field-precio').value * (1+(document.getElementById('articulo-modal-field-iva').value/100))).toFixed(2);
        }
    }
    function modal(){
        return (<div class="modal fade" id='nuevoArticuloModal' tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-main-title" id="exampleModalLabel">Nuevo artículo</h1>
                <button id='closeArticuloModal' type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
                
            <div class="modal-body">
            <h6 className='modal-title'>Datos generales</h6>
            <div className='modal-row'>
                <div className='modal-field w50'>
                    <label className='mflabel w17_5'>Nombre</label>
                    <input className='mfinput w82_5' id='articulo-modal-field-nombre'></input>
                </div>
                <div className='modal-field w50'>
                    <label className='mflabel w17_5'>Descripción</label>
                    <input className='mfinput w82_5' id='articulo-modal-field-descripcion'></input>
                </div>
            </div>
            <div className='modal-row'>
                <div className='modal-field w25'>
                    <label className='mflabel w35'>Familia</label>
                    <input className='mfinput w65' id='articulo-modal-field-familia'></input>
                </div>
                <div className='modal-field w25'>
                    <label className='mflabel w35'>Subfamilia</label>
                    <input className='mfinput w65' id='articulo-modal-field-subfamilia'></input>
                </div>
            </div>
            

            <div className='separator'></div>
            
            <h6 className='modal-title'>Precios</h6>
            <div className='modal-row'>
                <div className='modal-field w25'>
                    <label className='mflabel w35'>Precio</label>
                    <input className='mfinput w65' id='articulo-modal-field-precio' onChange={ () => calcularPrecioIva()}></input>
                </div>
                <div className='modal-field w25'>
                    <label className='mflabel w35'>Precio prov.</label>
                    <input className='mfinput w65' id='articulo-modal-field-precioProveedor'></input>
                </div>
                <div className='modal-field w25'>
                    <label className='mflabel w35'>IVA</label>
                    <input className='mfinput w65' id='articulo-modal-field-iva' onChange={ () => calcularPrecioIva()}></input>
                </div>
                <div className='modal-field w25'>
                    <label className='mflabel w35'>Precio IVA</label>
                    <input className='mfinput w65' disabled  id='articulo-modal-field-precioIva'></input>
                </div>
                
            </div>

            <div className='separator'></div>
            
            <h6 className='modal-title'>Gestión de stock</h6>
            <div className='modal-row'>
                <div className='modal-field w25'>
                    <label className='mflabel w35'>Unidades</label>
                    <input className='mfinput w65' id='articulo-modal-field-unidades'></input>
                </div>
                <div className='modal-field w25'>
                    <label className='mflabel w35'>Stock mín</label>
                    <input className='mfinput w65' id='articulo-modal-field-stockMin'></input>
                </div>
                <div className='modal-field w25'>
                    <label className='mflabel w35'>Stock máx</label>
                    <input className='mfinput w65' id='articulo-modal-field-stockMax'></input>
                </div>
                <div className='modal-field w25'>
                    <label className='mflabel w35'>EAN13</label>
                    <input className='mfinput w65' id='articulo-modal-field-codigoEAN13'></input>
                </div>
            </div>
            
            <div className='separator'></div>
            
            <h6 className='modal-title'>Otros</h6>
            <div className='modal-row'>
                <div className='modal-field w25'>
                    <label className='mflabel w35'>Publicable</label>
                    <select className='mfselect w65' id="articulo-modal-field-publicable">
                        <option value="NO" selected="selected">NO</option>
                        <option value="SI">SI</option>
                    </select>
                </div> 
                <div className='modal-field w25'>
                    <label className='mflabel w35'>Rotura</label>
                    <select className='mfselect w65' id="articulo-modal-field-rotura">
                        <option value="NO" selected="selected">NO</option>
                        <option value="SI">SI</option>
                    </select>
                </div>
                <div className='modal-field w25'>
                    <label className='mflabel w50'>Descatalogado</label>
                    <select className='mfselect w50' id="articulo-modal-field-descatalogado">
                        <option value="NO" selected="selected">NO</option>
                        <option value="SI">SI</option>
                    </select>
                </div>
                <div className='modal-field w25'>
                    <label className='mflabel w35'>Portada</label>
                    <select className='mfselect w65' id="articulo-modal-field-portada">
                        <option value="NO" selected="selected">NO</option>
                        <option value="SI">SI</option>
                    </select>
                </div>
            </div>
            <div className='modal-row'>
                <div className='modal-field w25'>
                    <label className='mflabel w35'>Más vendido</label>
                    <select className='mfselect w65' id="articulo-modal-field-masVendido">
                        <option value="NO" selected="selected">NO</option>
                        <option value="SI">SI</option>
                    </select>
                </div>
                <div className='modal-field w25'>
                    <label className='mflabel w35'>Novedades</label>
                    <select className='mfselect w65' id="articulo-modal-field-novedades">
                        <option value="NO" selected="selected">NO</option>
                        <option value="SI">SI</option>
                    </select>
                </div>
            </div>
            <div className='modal-row'>
               
                    <label className='mflabel w8_75'>Observaciones</label>
                    <textarea className='mftextarea w91_75' id="articulo-modal-field-observaciones"></textarea>
                
            </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary modal-footer-button" data-bs-toggle="modal" data-bs-target="#nuevoArticuloModal" onClick={() => nuevoArticulo()}>Guardar artículo</button>
            </div>
            </div>
        </div>
        </div>);
    }

    function limpiarModal(){

        document.getElementById("articulo-modal-field-nombre").value = "";
        document.getElementById("articulo-modal-field-familia").value = "";
        document.getElementById("articulo-modal-field-subfamilia").value = "";
        document.getElementById("articulo-modal-field-descripcion").value = "";
        document.getElementById("articulo-modal-field-stockMin").value = "";
        document.getElementById("articulo-modal-field-stockMax").value = "";
        document.getElementById("articulo-modal-field-unidades").value = "";
        document.getElementById("articulo-modal-field-observaciones").value = "";
        document.getElementById("articulo-modal-field-precio").value = "";
        document.getElementById("articulo-modal-field-iva").value = "";
        document.getElementById("articulo-modal-field-publicable").value = "NO";
        document.getElementById("articulo-modal-field-rotura").value = "NO";
        document.getElementById("articulo-modal-field-descatalogado").value = "NO";
        document.getElementById("articulo-modal-field-portada").value = "NO";
        document.getElementById("articulo-modal-field-masVendido").value = "NO";
        document.getElementById("articulo-modal-field-novedades").value = "NO";
        document.getElementById("articulo-modal-field-codigoEAN13").value = "";

    }

    return (modal());
};


ModalArticuloComponent.propTypes = {

};


export default ModalArticuloComponent;
