import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ModalUsuarioComponent from './modalUsuarioComponent';
import UsuarioComponent from '../pure/usuarioComponent';
import { createUser, getAllUsers, updateUser, findUsuarios } from '../../services/fecthService';
import { Usuario } from '../../models/usuario.class';

const UsuariosComponent = ({data, toasting, registrarEvento}) => {

    const [filterButtonActive, setFilterButtonActive] = useState(0);

    const [usuarios, setUsuarios] = useState([]);

    useEffect(() => {
        obtainUsers();
    }, []);

    const obtainUsers = () => {
        getAllUsers(data.token)
        .then((response) => {
            let usuarios = [];
            
            response.map((item) => {
                var usuario = new Usuario(
                    item.id,
                    item.name,
                    item.email,
                    item.usuario,
                    item.password,
                    item.roles
                );
                usuarios.push(usuario);
            });
            setUsuarios(usuarios);

        }) 
        .catch(()=>{
            toasting('Error al recuperar usuarios', 'error');
        })
        .finally(() => {
        });
    }

    const actualizarUsuario = (id, key, value) => {
        updateUser(data.token, id, key, value)
        .then((response) => {
            toasting('Usuario actualizado con éxito', 'success');
            registrarEvento('Usuario '.concat(response.username).concat(' con ID ').concat(id).concat(' modificado: '.concat(key).concat('->').concat(value)));
        }) 
        .catch((error)=>{
            toasting('Error al actualizar usuario', 'error');
        })
        .finally(() => {
            obtainUsers();
        });
    }

    function guardarCambios(id, key, value){
        actualizarUsuario(id, key, value);
    }
    
    function filterItems(item){
        return <UsuarioComponent key = {'Usuario'.concat(item.id)} usuario={item} guardarCambios={guardarCambios} resetPass={resetPass}></UsuarioComponent>; 
        
    }

    function resetPass(id, nombre){

        updateUser(data.token, id, 'password', 'dracars')
        .then((response) => {
            toasting('Se ha reseteado la contraseña de '.concat(nombre), 'success');
            registrarEvento('Se ha reseteado la contraseña de '.concat(nombre));
        }) 
        .catch((error)=>{
            toasting('Error al resetear la contraseña', 'error');
        })
        .finally(() => {
        });
    }

    function saveNuevoUsuario(usuario){
        createUser(data.token,usuario)
        .then((response) => {
            toasting('Usuario '.concat(usuario.name).concat(' creado con éxito'), 'success');
            registrarEvento('Usuario '.concat(usuario.username).concat(' con ID ').concat(response.id).concat(' creado.'));

        }) 
        .catch(()=>{
            toasting('Error al crear el usuario', 'error');
        })
        .finally(() => {
            obtainUsers();
        });
    }

    function buscarUsuario(){

        let textoBuscado = document.getElementById("buscador-usuario").value;

        if(textoBuscado != '' && textoBuscado.length > 2){
            let split = textoBuscado.trim().split(' ');

            findUsuarios(data.token, split)
            .then((response) => {
                setUsuarios(response.reverse());
            }) 
            .catch(()=>{
                toasting('Error de búsqueda', 'error');

            })
            .finally(() => {
            });
        }else{
            obtainUsers();
        }
    }

    return (
        
        <div>
            <h2 className='page-title'>Usuarios</h2>
            <div className='upper-buttons'>
                <button type="button" id='nuevoUsuarioButton' className="btn btn-primary upper-button w15 bold" data-bs-toggle="modal" data-bs-target="#nuevoUsuarioModal">Nuevo usuario</button>
            </div>

            <ModalUsuarioComponent saveNuevoUsuario={saveNuevoUsuario}></ModalUsuarioComponent>
            <div className='table-container'>
                <div className='toolbar-listado'>
                    <div className='filtros-rapidos'>
                        <span className={filterButtonActive === 0 ? 'filtro-rapido filtro-activado': 'filtro-rapido'} onClick={() => {setFilterButtonActive(0);}}>Todo</span>
                    </div>
                    <div className='buscador'>
                        <input className='form-control me-2' id="buscador-usuario" type='search' placeholder='Buscar...' aria-label='Search' onChange={()=>buscarUsuario()}></input>
                    </div>
                </div>
                <table className='content-table'>
                            <thead  className='listado-header'> 
                                <th className='listado-header-field w7_5' style={{padding: '0 0 0 1vw'}}>Código</th>  
                                <th className='listado-header-field w15'>Nombre</th>
                                <th className='listado-header-field w15'>Email</th>
                                <th className='listado-header-field w15'>Usuario</th>
                                <th className='listado-header-field w15'>Roles</th>
                                <div className='acciones' style={{marginLeft: 'auto'}}>
                                    <label>Acciones</label>
                                </div>
                                
                            </thead>
                            <div className='wrapper-listado'>
                            {
                                usuarios.map((item) => { 
                                    return filterItems(item);
                                })
                            }
                            </div>                   
                        </table>
            </div>
        </div>
    );
};


UsuariosComponent.propTypes = {

};

export default UsuariosComponent;
