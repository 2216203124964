import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ModalClienteComponent from './modalClienteComponent';
import ClienteComponent from '../pure/clienteComponent';
import { createCliente, getAllClientes, updateCliente, findClientes, deleteCliente } from '../../services/fecthService';
import { Cliente } from '../../models/cliente.class';
import Paper from '@mui/material/Paper';


import Autocomplete from '@mui/material/Autocomplete';
const ClientesComponent = ({data, toasting, registrarEvento}) => {

    const [filterButtonActive, setFilterButtonActive] = useState(0);

    const [clientes, setClientes] = useState([]);
    const [clientesAux, setClientesAux] = useState([]);

    const [isMobile, setIsMobile] = useState(false);
    const [activeTab, setActiveTab] = useState('tab1');

        // Estado para controlar si el modal está abierto o cerrado
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isClienteModalOpen, setIsClienteModalOpen] = useState(false);

    useEffect(() => {
       
        obtainClientes();

        const handleResize = () => {
                 // eslint-disable-next-line no-restricted-globals
            setIsMobile(screen.width < 768);
        };

    // Ejecutar handleResize al cargar la página
    handleResize();

    // Agregar un event listener para manejar cambios en el tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Limpieza del event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    },[]);

    const obtainClientes = () => {
        getAllClientes(data.token)
        .then((response) => {
            let arts = [];
            let obs = "";
            response.map((item) => {
                if(item.observaciones != null) obs = item.observaciones;
                var cliente = new Cliente(
                    item.id,
                    item.nombre,
                    item.nombreComercial,
                    item.cif,
                    item.direccion,
                    item.cp,
                    item.poblacion,
                    item.provincia,
                    item.tributacion,
                    item.tipoDePago,
                    item.movil,
                    item.telefono,
                    item.email,
                    item.observaciones
                );
                arts.push(cliente);
                obs = "";
                
            });
            setClientesAux(arts);
            setClientes(arts);
        }) 
        .catch(()=>{
            toasting('Error al recuperar clientes', 'error');
        })
        .finally(() => {
        });
    }

    const openModal = () => {
    
        setIsModalOpen(true);
      };
    
      // Función para cerrar el modal
      const closeModal = () => {
        setIsModalOpen(false);
      };

    const actualizarCliente = (id, key, value) => {
        updateCliente(data.token, id, key, value)
        .then((response) => {
            toasting('Cliente actualizado con éxito', 'success');
            registrarEvento('Cliente '.concat(response.nombre).concat(' con ID ').concat(id).concat(' modificado: '.concat(key).concat('->').concat(value)));

        }) 
        .catch((error)=>{
            toasting('Error al actualizar cliente', 'error');
        })
        .finally(() => {
            obtainClientes();
        });
    }

    function guardarCambios(id, key, value){
        actualizarCliente(id, key, value);
    }
    
    function sayModalIsOpen(){
        setIsClienteModalOpen(true);
    }

    function sayModalIsNotOpen(){
        setIsClienteModalOpen(false);
    }

    function filterItems(item){
        if (filterButtonActive === 0 ||
            (filterButtonActive === 1 && item.unidades === 0)||
            (filterButtonActive === 2 && item.observaciones !== "")){
                return <ClienteComponent key = {'Cliente'.concat(item.id)} cliente={item} guardarCambios={guardarCambios} borrarCliente={borrarCliente} sayModalIsOpen={sayModalIsOpen} sayModalIsNotOpen={sayModalIsNotOpen}></ClienteComponent>; 
        }
    }

    function saveNuevoCliente(cliente){
        createCliente(data.token,cliente)
        .then((response) => {
            toasting('Cliente '.concat(cliente.nombreComercial).concat(' creado con éxito'), 'success');
            registrarEvento('Cliente '.concat(cliente.nombreComercial).concat(' con ID ').concat(response.id).concat(' creado.'));
        }) 
        .catch(()=>{
            toasting('Error al crear el cliente', 'error');
        })
        .finally(() => {
            obtainClientes();
        });
    }

    function buscarCliente(){

        let textoBuscado = document.getElementById("buscador-cliente").value;

        if(textoBuscado != '' && textoBuscado.length > 2){
            let split = textoBuscado.trim().split(' ');

            findClientes(data.token, split)
            .then((response) => {
                setClientes(response.reverse());
            }) 
            .catch(()=>{
                toasting('Error de búsqueda', 'error');

            })
            .finally(() => {
            });
        }else{
            obtainClientes();
        }
    }

    function borrarCliente(cliente){
        deleteCliente(data.token, cliente.id)
        .then((response) => {
            toasting('Cliente eliminado con éxito', 'success');
            registrarEvento('Cliente '.concat(' con ID ').concat(cliente.id).concat(' eliminado.'));

        }) 
        .catch(()=>{
            toasting('Error al eliminar el cliente', 'error');

        })
        .finally(() => {
            obtainClientes();
        });
    }

    function setStyleAddCliente(){
        var style = {};
        if(isClienteModalOpen){
            style.zIndex = '-1';
        }
        

        return style;
    }

    function renderClientes(){
        if(isMobile){
            console.log(isClienteModalOpen);
            return (
        
                <div style={{position:'relative', width: '100vw'}}>
                {isModalOpen && (
                    <Modal
                        onClose={closeModal} // Pasamos la función para cerrar el modal
                        data={data}
                        saveNuevoCliente={saveNuevoCliente}
                                          />
                
        
            )}
                    <h2 className='page-title'>Clientes</h2>
        
                    <div className='table-container'>
                        
                        <table className='content-table' style={{height:'71%'}}>
                                    <thead  className='listado-header' style={{borderTop:'0'}}> 
                                        <th className='listado-header-field' style={{margin:'0 0 0 2vw', width:'25%'}}>CIF</th>
                                        <th className='listado-header-field' style={{margin:'0 0 0 2vw', width:'55%'}}>Nombre</th>
                                        <div className='acciones' style={{width:'20%'}}>
                                            <label>Acciones</label>
                                        </div>
                                        
                                    </thead>
                                    <div className='wrapper-listado'>
                                    {clientes.length > 0 
                                        ? clientes.map((item) => { 
                                            return filterItems(item);
                                        })
                                        : 
                                        <div class="d-flex justify-content-center">
                                            <div class="spinner-border  spinner-border-sm  text-warning m-4" role="status">
                                                <span class="sr-only"></span>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                        
                                                                
                                </table>
                    </div>
                    {/* Barra de búsqueda */}
                <input type="text" className="search-bar"  style={setStyleAddCliente()} id='buscador-cliente' placeholder="Buscar..." onChange={()=>buscarCliente()}/>
                
                {/* Botón circular */}
                <button className="floating-button" style={setStyleAddCliente()} onClick={() => openModal()}>+</button>
            
                </div>
            );
        }else{
            return (
        
                <div>
                    <h2 className='page-title'>Clientes</h2>
                    <div className='upper-buttons'>
                        <button type="button" id='nuevoClienteButton' className="btn btn-primary upper-button w15 bold" data-bs-toggle="modal" data-bs-target="#nuevoClienteModal">Nuevo cliente</button>
                    </div>
        
                    <ModalClienteComponent saveNuevoCliente={saveNuevoCliente}></ModalClienteComponent>
                    <div className='table-container'>
                        <div className='toolbar-listado'>
                            <div className='filtros-rapidos'>
                                <span className={filterButtonActive === 0 ? 'filtro-rapido filtro-activado': 'filtro-rapido'} onClick={() => {setFilterButtonActive(0);}}>Todo</span>
                            </div>
                            <div className='buscador'>
                                <input className='form-control me-2' id="buscador-cliente" type='search' placeholder='Buscar...' aria-label='Search' onChange={()=>buscarCliente()}></input>
                            </div>
                        </div>
                        <table className='content-table'>
                                    <thead  className='listado-header'> 
                                        <th className='listado-header-field w7_5' style={{padding: '0 0 0 1vw'}}>Código</th>  
                                        <th className='listado-header-field w10'>CIF</th>
                                        <th className='listado-header-field w40 bold'>Nombre</th>
                                        <th className='listado-header-field w10'>Teléfono</th>
                                        <th className='listado-header-field w12_5'>Provincia</th>
                                        <th className='listado-header-field w15'>Población</th>
                                        <div className='acciones'>
                                            <label>Acciones</label>
                                        </div>
                                        
                                    </thead>
                                    <div className='wrapper-listado'>
                                    {clientes.length > 0 
                                        ? clientes.map((item) => { 
                                            return filterItems(item);
                                        })
                                        : 
                                        <div class="d-flex justify-content-center">
                                            <div class="spinner-border  spinner-border-sm  text-warning m-4" role="status">
                                                <span class="sr-only"></span>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                        
                                                                
                                </table>
                    </div>
                </div>
            );
        }
    }

    return (renderClientes());
};

const Modal = ({ onClose, data, saveNuevoCliente }) => {
    const [currentArticulosRows, setCurrentArticulosRows] = useState([]);

    useEffect(() => {
        
    }, [currentArticulosRows]);

    const [primeraCarga, setPrimeraCarga] = useState(true);

    const [clientesBuscados, setClientesBuscados] = useState([]);
    const [articulosBuscados, setArticulosBuscados] = useState([]);
    const [cliente, setCliente] = useState();

    function onSave(){

    }
    function nuevoCliente(){
        
        const cliente = new Cliente(
            "",
            document.getElementById("cliente-movil-field-nombre").value,
            document.getElementById("cliente-movil-field-nombreComercial").value,
            document.getElementById("cliente-movil-field-cif").value,
            document.getElementById("cliente-movil-field-direccion").value,
            document.getElementById("cliente-movil-field-cp").value,
            document.getElementById("cliente-movil-field-poblacion").value,
            document.getElementById("cliente-movil-field-provincia").value,
            document.getElementById("cliente-movil-field-tributacion").value,
            document.getElementById("cliente-movil-field-tipoDePago").value,
            document.getElementById("cliente-movil-field-movil").value,
            document.getElementById("cliente-movil-field-telefono").value,
            document.getElementById("cliente-movil-field-email").value,
            document.getElementById("cliente-movil-field-observaciones").value
            );

        saveNuevoCliente(cliente);
        document.getElementById('overlay').click();
    }
    return (
                    <div className="modal-overlay" id='overlay' onClick={onClose}>

        <div className="modal-content" style={{position:'relative'}} onClick={(e) => e.stopPropagation()}>
            <h3>Nuevo cliente</h3>
            <div style={{overflow: 'scroll', maxWidth:'100vw'}}>
                <p className='p-modal-movil'>Nombre</p>
                <input className='input-modal-movil' id='cliente-movil-field-nombre'></input>

                <p className='p-modal-movil'>Nombre comercial</p>
                <input className='input-modal-movil' id='cliente-movil-field-nombreComercial'></input>

                <p className='p-modal-movil'>CIF</p>
                <input className='input-modal-movil' id='cliente-movil-field-cif'></input>

                <p className='p-modal-movil'>Tributación</p>
                <select className='mfselect' style={{width:'100%'}} name='tributacion' id="cliente-movil-field-tributacion">
                            <option value="NACIONAL" selected="selected">Nacional</option>
                            <option value="CYM">Ceuta y Melilla</option>
                            <option value="CANARIAS">Canarias</option>
                            <option value="UE">Unión Europea</option>
                            <option value="EXTRACOMUNITARIO">Extracomunitario</option>
                        </select> 
                <p className='p-modal-movil'>Tipo de pago</p>
                <select className='mfselect' style={{width:'100%'}} name='tipoDePago' id="cliente-movil-field-tipoDePago">
                            <option value="CONTADO" selected="selected">Contado</option>
                            <option value="TARJETA">Tarjeta</option>
                            <option value="TRANSFERENCIA">Transferencia</option>
                            <option value="D10">Pago a 10 días</option>
                            <option value="D30">Pago a 30 días</option>
                            <option value="D45">Pago a 45 días</option>
                            <option value="D60">Pago a 60 días</option>
                        </select> 
                <p className='p-modal-movil'>Dirección</p>
                <input className='input-modal-movil' id='cliente-movil-field-direccion'></input>

                <p className='p-modal-movil'>Provincia</p>
                <input className='input-modal-movil' id='cliente-movil-field-provincia'></input>

                <p className='p-modal-movil'>Población</p>
                <input className='input-modal-movil' id='cliente-movil-field-poblacion'></input>

                <p className='p-modal-movil'>Código postal</p>
                <input className='input-modal-movil' id='cliente-movil-field-cp'></input>

                <p className='p-modal-movil'>Email</p>
                <input className='input-modal-movil' id='cliente-movil-field-email'></input>

                <p className='p-modal-movil'>Teléfono</p>
                <input className='input-modal-movil' id='cliente-movil-field-telefono'></input>

                <p className='p-modal-movil'>Móvil</p>
                <input className='input-modal-movil' id='cliente-movil-field-movil'></input>

                <p className='p-modal-movil'>Observaciones</p>
                <input className='input-modal-movil' id='cliente-movil-field-observaciones'></input>
            </div>
            
            <div className="modal-footer">
                <button onClick={onClose} style={{backgroundColor: 'rgb(219, 219, 219)'}}>Cerrar</button>
                <button onClick={() => nuevoCliente()}>Guardar cliente</button>
            </div>
        </div>
        </div>
    );
    
  };

ClientesComponent.propTypes = {

};

export default ClientesComponent;
