import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import EventoComponent from '../pure/eventoComponent';
import { getAllEvents, findEventos } from '../../services/fecthService';
import { Evento } from '../../models/evento.class';

const RegistroComponent = ({data, toasting}) => {

    const [filterButtonActive, setFilterButtonActive] = useState(0);

    const [eventos, setEventos] = useState([]);
    const [eventosAux, setEventosAux] = useState([]);

    useEffect(() => {
        obtainEventos();
    }, []);

    const obtainEventos = () => {
        getAllEvents(data.token)
        .then((response) => {
            let eves = [];
            
            response.map((item) => {
                var evento = new Evento(
                    item.id,
                    item.fecha,
                    item.evento,
                    item.usuario
                );
                eves.push(evento);
            });
            eves = eves.reverse();
            setEventosAux(eves);
            setEventos(eves);

        }) 
        .catch(()=>{
            toasting('Error al recuperar eventos', 'error');
        })
        .finally(() => {
        });
    }

    
    function filterItems(item){
        return <EventoComponent key = {'Evento'.concat(item.id)} evento={item}></EventoComponent>; 
        
    }

    function buscarEvento(){

        let textoBuscado = document.getElementById("buscador-evento").value;

        if(textoBuscado != '' && textoBuscado.length > 2){
            let split = textoBuscado.trim().split(' ');

            findEventos(data.token, split)
            .then((response) => {
                setEventos(response.reverse());
            }) 
            .catch(()=>{
                toasting('Error de búsqueda', 'error');

            })
            .finally(() => {
            });
        }else{
            obtainEventos();
        }
    }

    return (
        
        <div>
            <h2 className='page-title'>Registro de eventos</h2>

            <div className='table-container'>
                <div className='toolbar-listado'>
                    <div className='filtros-rapidos'>
                        <span className={filterButtonActive === 0 ? 'filtro-rapido filtro-activado': 'filtro-rapido'} onClick={() => {setFilterButtonActive(0);}}>Todo</span>
                    </div>
                    <div className='buscador'>
                        <input className='form-control me-2' id="buscador-evento" type='search' placeholder='Buscar...' aria-label='Search' onChange={()=>buscarEvento()}></input>
                    </div>
                </div>
                <table className='content-table'>
                            <thead  className='listado-header'> 
                                <th className='listado-header-field w7_5' style={{padding: '0 0 0 1vw'}}>Código</th>  
                                <th className='listado-header-field w10'>Fecha</th>
                                <th className='listado-header-field w10'>Usuario</th>
                                <th className='listado-header-field w75'>Evento</th>
                            </thead>
                            <div className='wrapper-listado'>
                            {
                                eventos.map((item) => { 
                                    return filterItems(item);
                                })
                            }
                            </div>                           
                        </table>
            </div>
        </div>
    );
};


RegistroComponent.propTypes = {

};

export default RegistroComponent;
