import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllClientes, findClientes } from '../../services/fecthService';
import { findArticulos } from '../../services/fecthService';
import { Cliente } from '../../models/cliente.class';
import { facturaContext } from './pedidosCComponent';

const ModalDatosFacturaComponent = ({data, actualizarFactura, clearFactura}) => {

    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState();

    const [clientesBuscados, setClientesBuscados] = useState([]);
    const [articulosBuscados, setArticulosBuscados] = useState([]);

    const[autocompleteState, setAutocompleteState] = useState(false);

    const [datosCargados, setDatosCargados] = useState(false);
    const [codigoFacturaValue, setCodigoFacturaValue] = useState('');
    const [responsableValue, setResponsableValue] = useState('');
    const [clienteIdValue, setClienteIdValue] = useState('');
    const [clienteNombreValue, setClienteNombreValue] = useState('');
    const [clienteCIFValue, setClienteCIFValue] = useState('');
    const [fechaValue, setFechaValue] = useState('');
    const [observacionesValue, setObservacionesValue] = useState('');
    const [articulosValues, setArticulosValues] = useState([]);

    const factura = useContext(facturaContext);

   useEffect(() => {
   
    var m = document.getElementById('datosFacturaModal');
    const handleC = () => {
        clearFactura();
        setDatosCargados(false);
    };
   
    m.addEventListener('hidden.bs.modal', handleC);
    
    return () => {
        m.removeEventListener('hidden.bs.modal', handleC);
    }
   }, []);

    function formatear3decimales(precio){
        if(precio != undefined){
            if (typeof precio !== 'string') {
            precio = precio.toString(); // Convertir a cadena si no lo es
            }
    
            // Verificar si hay más de tres decimales y truncarlos si es necesario
            if (/^\d*\.\d{4,}$/.test(precio)) {
                const parts = precio.split('.');
                precio = `${parts[0]}.${parts[1].substring(0, 3)}`;
            }
        }
        
      return precio;
    }

    function today(){
        var date = new Date();
        var split = date.toLocaleDateString();
        var split2 = split.split('/');
        
        var day = split2[0];
        var month = split2[1];
        var year = split2[2];
        if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
            day = '0'.concat(day);
        }
        if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
            month = '0'.concat(month);
        }
        return year.concat('-').concat(month).concat('-').concat(day);
    }

    function formatearFechaAlReves(item){
        console.log(item);
        if(item != undefined){
            var date = new Date(item);
            var split = date.toLocaleDateString();
            var split2 = split.split('/');
            
            var day = split2[0];
            var month = split2[1];
            var year = split2[2];
            if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
                day = '0'.concat(day);
            }
            if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
                month = '0'.concat(month);
            }
            return year.concat('-').concat(month).concat('-').concat(day);
        }else{
            return '';
        }
       
    }

    function cambiarValorInput(id, valor){
        if(id == 'observaciones'){
            setObservacionesValue(valor);
        }
    }
    
    function isClienteFilled(id){
        return ({ border: '1px solid lightgrey', borderRadius: '3px', width: '100%'});  
    }
    
    function modal(){

        if(factura != ''){
            if(!datosCargados){
                setDatosCargados(true);
                
                setCodigoFacturaValue(factura.codigoFactura);
                setResponsableValue(factura.responsable);
                setClienteIdValue(factura.cliente.id);
                setClienteNombreValue(factura.cliente.nombreComercial);
                setClienteCIFValue(factura.cliente.cif);
                setFechaValue(formatearFechaAlReves(factura.fecha));
                setObservacionesValue(factura.observaciones);
                var arts = [];
                var art;
                factura.albaranes.map((albaran) => {
                    albaran.articulos.map((articulo) => {
                        art = {};
                        art.albaran = albaran.id
                        art.id = articulo.id;
                        art.idArticulo = articulo.idArticulo;
                        art.nombre = articulo.nombre;
                        art.cantidad = articulo.cantidad;
                        art.precio = articulo.precio;
                        art.descuento = articulo.descuento;
                        art.porcentajeIva = articulo.porcentajeIva;
                        art.importeIva = articulo.importeIva;
                        art.subtotal = articulo.subtotal;
                        arts.push(art);
                    });
                });
                setArticulosValues(arts);
            }
        }
        
        return (<div class="modal fade" tabindex="-1" id='datosFacturaModal' aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div class="modal-content">
            <div class="modal-header">
                <h1 className='modal-main-title' id="exampleModalLabel">Factura {factura.codigoFactura}</h1>
                <button id='closeDatosFacturaModal' type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
                
            <div class="modal-body">
                <h6 className='modal-title'>Datos generales</h6>

                <div className='modal-row'>
                    <div className='modal-field w33'>
                        <label className='mflabel w35'>Nº factura</label>
                        <input className='mfinput w65'  disabled value={codigoFacturaValue}></input>
                    </div>
                    <div className='modal-field w33'>
                        <label className='mflabel w35'>Responsable</label>
                        <input className='mfinput w65'  disabled value={responsableValue}></input>
                    </div> 
                    <div className='modal-field w33'>
                        <label className='mflabel w35'>Fecha</label>
                        <input className='mfinput w65' disabled value={fechaValue} type='date' id='pedidoC-datosFactura-field-fecha'></input>
                    </div> 
                </div>

                <div className='separator'></div>

                <h6 className='modal-title'>Datos de cliente</h6>
                <div className='modal-row'>
                <div className='modal-field w20'>
                        <label className='mflabel w45'>Código</label>
                        <input disabled className='mfinput w55' style={isClienteFilled('clienteId')} value={clienteIdValue}></input>
                    </div>
                    <div className='modal-field w60'>
                        <label className='mflabel w20'>Nombre</label>
                        <Autocomplete
                            style={{width:'80%', fontSize:'1.5vh'}}
                            freeSolo
                            disabled
                            key={autocompleteState}
                            value={clienteNombreValue}                 
                            id="pedidoC-modal-field-cliente"
                            options={clientesBuscados.map((item) => "Código: ".concat(item.id).concat(" | Nombre: ").concat(item.nombreComercial).concat(" | CIF: ").concat(item.cif))}
                            renderInput={(params) => (
                                <div style={{width:'100%'}} ref={params.InputProps.ref}>
                                    <input style={isClienteFilled('clienteNombre')} type="text" {...params.inputProps}/>
                                </div>
                            )}
                        />
                    </div> 
                    <div className='modal-field w20'>
                        <label className='mflabel w35'>CIF</label>
                        <Autocomplete
                           style={{width:'65%', fontSize:'1.5vh'}}
                            freeSolo
                            disabled
                            key={autocompleteState}
                            value={clienteCIFValue}
                            id="pedidoC-modal-field-cliente"
                            options={clientesBuscados.map((item) => "Código: ".concat(item.id).concat(" | Nombre: ").concat(item.nombreComercial).concat(" | CIF: ").concat(item.cif))}
                            renderInput={(params) => (
                                <div style={{width:'100%'}} ref={params.InputProps.ref}>
                                    <input style={isClienteFilled('clienteNombre')} type="text" {...params.inputProps}/>
                                </div>
                            )}
                        />
                    </div> 
                   
                </div>


                <div className='separator'></div>


                <h6 className='modal-title'>Otros</h6>
                <div className='modal-row'>
                    <label className='mflabel w8_75'>Observaciones</label>
                    <textarea className='mftextarea w91_75' disabled={factura.estado != 'PENDIENTE'} name='observaciones' id='pedidoC-datosFactura-field-observaciones' value={observacionesValue} onChange={(evento) => cambiarValorInput('observaciones', evento.target.value)}></textarea>   
                </div>

                <div className='separator'></div>
                    
                <h6 className='modal-title'>Artículos</h6>
                <div className='pedido-modal-articulos-table'>
                    <div className='pmaheader w100'>
                        <label className='pmahfield w7_5'>Albarán</label>
                        <label className='pmahfield w10'>Código</label>
                        <label className='pmahfield w20'>Nombre</label>
                        <label className='pmahfield w10'>Unidades</label>
                        <label className='pmahfield w10'>Precio</label>
                        <label className='pmahfield w10'>Descuento</label>
                        <label className='pmahfield w10'>IVA %</label>
                        <label className='pmahfield w10'>Importe IVA</label>
                        <label className='pmahfield w10'>Subtotal</label>               
                        
                        
                    </div>
                    {renderArticulos().map((item) =>{
                        return item;
                    })}
               
                </div>
                
            </div>
            <div class="modal-footer">
                <p>Total: {calcularTotal()}€ </p>
                {factura.estado == 'PENDIENTE'
                ? <button type="button" class="btn btn-primary modal-footer-button" onClick={() => prepararActualizacion()}>Guardar cambios</button>
                :''}           
            </div>
            
            
            </div>
        </div>
        </div>);
    }

    function prepararActualizacion(){
        actualizarFactura(factura.id, 'observaciones', document.getElementById('pedidoC-datosFactura-field-observaciones').value);
        document.getElementById('closeDatosFacturaModal').click();
}

    function validarNumero(valor) {
        
        if (isNaN(valor)) {
          return "";
        } else {
          return valor;
        }
      }

    
      function renderArticulos(){
        let articles = [];
        let elementosHTML = [];
        if(factura != ''){
        articulosValues.map((articulo, a) => {

            elementosHTML.push(
                <div key={'pedido'.concat(factura.id).concat('CollapseRow').concat(a)} id={'pedido'.concat(factura.id).concat('CollapseRow').concat(a)} className={'pcarow w100 pedidoC'.concat(factura.id)}>
                    <input disabled className='pmarfield w7_5' value={articulo.albaran} id={'pcarfield'.concat(articulo).concat(a)} ></input>
                    <input disabled className='pmarfield w10' value={articulo.idArticulo} id={'pcarfieldId'.concat(a)} ></input>
                    <Autocomplete disabled
                            freeSolo
                            value={articulo.nombre}
                            style={{fontSize:'1.5vh', width:'20%', margin: '0 0.2vw 0 0.2vw'}}
                            renderInput={(params) => (
                                <div style={{width:'100%'}} ref={params.InputProps.ref}>
                                   <input style={{fontSize:'1.5vh',border: '1px solid lightgrey', borderRadius: '5px', width: '100%'}} type="text" {...params.inputProps} 
                                    />
                                </div>
                            )}
                        />                    
                    <input disabled className='pmarfield w10' value={articulo.cantidad}></input>
                    <input disabled className='pmarfield w10' value={formatear3decimales(articulo.precio)}  ></input>
                    <input disabled className='pmarfield w10' value={articulo.descuento} ></input>
                    <input disabled className='pmarfield w10' value={articulo.porcentajeIva}  ></input>
                    <input disabled className='pmarfield w10' value={articulo.importeIva} ></input>
                    <input disabled className='pmarfield w10' value={formatear3decimales(articulo.subtotal)}></input>
                    
                </div>);
                articles.push(articulo);

        });
    }
            
        return elementosHTML;
    }

    function calcularTotal(){
        var total = 0.00;
        articulosValues.map((articulo) => {
            if(articulo.importeIva && articulo.subtotal){
                total = parseFloat(total) + parseFloat(articulo.importeIva) + parseFloat(articulo.subtotal);
            }
        });

        if(!isNaN(total)){
            return parseFloat(total).toFixed(2);
        }
        else{
            return '0.00';
        }
    }

    return (modal());
};


ModalDatosFacturaComponent.propTypes = {

};


export default ModalDatosFacturaComponent;
