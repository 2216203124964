import './styles/App.scss';
import MainComponent from './components/container/mainComponent';

document.title = 'Dracars';
function App() {
  return (
    <div className="App">
      <MainComponent></MainComponent>
    </div>
  );
}

export default App;
