import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { event } from 'jquery';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';

const FacturaComponent = ({factura, checkboxChanged, active, modificarFactura, generarDocumentoFactura, pagar, articulos,  data, sayModalIsOpen, sayModalIsNotOpen, sayDatosFacturaModal}) => {

    const [isMobile, setIsMobile] = useState(false);
    // Estado para controlar si el modal está abierto o cerrado
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Datos que quieres pasar al modal
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    const handleResize = () => {
        // eslint-disable-next-line no-restricted-globals
   setIsMobile(screen.width < 768);
};

// Ejecutar handleResize al cargar la página
handleResize();

// Agregar un event listener para manejar cambios en el tamaño de la ventana
window.addEventListener('resize', handleResize);

// Limpieza del event listener cuando el componente se desmonta
return () => {
window.removeEventListener('resize', handleResize);
};
}, []);

const openModal = (data) => {
    
    setModalData(data);
    sayModalIsOpen();
    setIsModalOpen(true);
  };

    function setStyle(){
        let style = {};

        if(factura.estado === 'Facturado')
            style['display'] = 'hidden';
            style['opacity'] = '0%';

        return style;
    }

    const closeModal = () => {
        setIsModalOpen(false);
        sayModalIsNotOpen();
      };
    
    
    function estados(){
        if(factura.estado === "PENDIENTE"){
            return (<select className='selectEstados' onChange={() => enviarFactura()}>
                        <option value="PENDIENTE" selected>Sin emitir</option>
                        <option value="EMITIDA">Pendiente de pago</option>
                    </select>);
        }else if(factura.estado === "EMITIDA"){
            return (<select className='selectEstados' onChange={() => pagarFactura()}>
                        <option value="EMITIDA" selected="selected">Pendiente de pago</option>
                        <option value="PAGADA">Pagada</option>
                    </select>);
        }else if(factura.estado === "PAGADA"){
            return ("Pagada");
        }
    }

    function enviarFactura(){
        modificarFactura(factura.id, {'estado': 'EMITIDA'});
    }

    function pagarFactura(){
        pagar(factura.id);
    }
    function formatear3decimales(precio){
        if (typeof precio !== 'string') {
            precio = precio.toString(); // Convertir a cadena si no lo es
        }
    
        // Verificar si hay más de tres decimales y truncarlos si es necesario
        if (/^\d*\.\d{4,}$/.test(precio)) {
            const parts = precio.split('.');
            precio = `${parts[0]}.${parts[1].substring(0, 3)}`;
        }
    
      return precio;
    }

    function formatearFecha(item){
        var date = new Date(item);
        var split = date.toLocaleDateString();
        var split2 = split.split('/');
        
        var day = split2[0];
        var month = split2[1];
        var year = split2[2];
        if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
            day = '0'.concat(day);
        }
        if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
            month = '0'.concat(month);
        }
        return day.concat('-').concat(month).concat('-').concat(year);
    }

    function formatearFechaAlReves(item){
        var date = new Date(item);
        var split = date.toLocaleDateString();
        var split2 = split.split('/');
        
        var day = split2[0];
        var month = split2[1];
        var year = split2[2];
        if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
            day = '0'.concat(day);
        }
        if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
            month = '0'.concat(month);
        }
        return year.concat('-').concat(month).concat('-').concat(day);
    }

    

    function renderArticulos(){
        let articles = [];
        let elementosHTML = [];

        factura.albaranes.map((albaran) => {
            albaran.articulos.map((articulo) => {
            var a = articulo.id;
            elementosHTML.push(
                <div key={'pedido'.concat(factura.id).concat('CollapseRow').concat(a)} id={'pedido'.concat(factura.id).concat('CollapseRow').concat(a)} className={'pcarow w100 pedidoC'.concat(factura.id)}>
                    <input disabled className='pcarfield w7_5' value={albaran.numeroAlbaran} id={'pcarfield'.concat(albaran).concat(a)} ></input>
                    <input disabled className='pcarfield w10' value={articulo.idArticulo} id={'pcarfieldId'.concat(a)} ></input>
                    <Autocomplete disabled
                            freeSolo
                            value={articulo.nombre}
                            style={{fontSize:'1.5vh', width:'20%', margin: '0 0.2vw 0 0.2vw'}}
                            id={'pcarfieldNombre'.concat(a)}
                            renderInput={(params) => (
                                <div style={{width:'100%'}} ref={params.InputProps.ref}>
                                   <input style={{fontSize:'1.5vh',border: '1px solid lightgrey', borderRadius: '5px', width: '100%'}} type="text" {...params.inputProps} 
                                    />
                                </div>
                            )}
                        />                    
                    <input disabled className='pcarfield w7_5' value={articulo.cantidad} id={'pcarfieldUnidades'.concat(a)}></input>
                    <input disabled className='pcarfield w7_5' value={formatear3decimales(articulo.precio)} id={'pcarfieldPrecio'.concat(a)} ></input>
                    <input disabled className='pcarfield w7_5' value={articulo.descuento} id={'pcarfieldDescuento'.concat(a)} ></input>
                    <input disabled className='pcarfield w7_5' value={articulo.porcentajeIva} id={'pcarfieldPorcentajeIva'.concat(a)} ></input>
                    <input disabled className='pcarfield w7_5' value={articulo.importeIva} id={'pcarfieldImporteIva'.concat(a)}></input>
                    <input disabled className='pcarfield w10' id={'pcarfieldSubtotal'.concat(a)} value={formatear3decimales(articulo.subtotal)}></input>
                    
                </div>);
                articles.push(articulo);
            });
        });
            

        return elementosHTML;
    }

    function cambiarModal(){
        sayDatosFacturaModal(factura);
    }
    
    const exampleData = {
        title: 'Ejemplo de Modal',
        content: 'Este es un ejemplo de contenido para el modal.',
      };
    function renderFactura(){

        if(isMobile){
            return (
                <div>
                 {/* Renderizado condicional del modal */}
                    {isModalOpen && (
                        <Modal
                        data={factura}
                        onClose={closeModal} // Pasamos la función para cerrar el modal
                        formatearFechaAlReves={formatearFechaAlReves}
                        />
                    )}
                    
                    <tr key={factura.id} className='listado-row'>  
                       
                        <td className='listado-field' style={{margin:'0 0 0 2vw', width:'25%'}}>{factura.codigoFactura}</td>
                        <td className='listado-field bold' style={{maxWidth:'55%', width:'55%'}}>{factura.cliente.nombreComercial}</td>    
                        <div className='acciones' style={{width:'20%'}}>
                            <i className="bi bi-eye" title="Ver datos" onClick={() => openModal(exampleData)}></i>
                            <i className='bi bi-printer hover' title="Imprimir" onClick={() => generarDocumentoFactura(factura.id, factura.numeroAlbaran)}></i>
                        </div>
                    </tr>
                </div>
            );
        }else{
            return(<div>
                <tr key={factura.id} className='listado-row'>  
                <td>
                    <div className="form-check">
                        <input className="form-check-input" disabled style = {setStyle()} type="checkbox" value="" id="flexCheckDefault" onClick={(event) => checkboxChanged(event, factura.id)}></input>
                    </div>
                </td>     
                    
                        <td className='listado-field w7_5'>{factura.codigoFactura}</td>
                        <td className='listado-field w7_5'>{factura.cliente.id}</td>
                        <td className='listado-field w32_5 bold'>{factura.cliente.nombreComercial}</td>
                        <td className='listado-field w10'>{formatearFecha(factura.fecha)}</td>
                        <td className='listado-field w15'>
                            {estados()}
                        </td>
                        <td className='listado-field w10'>{
                             factura.totalConIva.toFixed(2) + '€'
                         }</td>
                         <td className='listado-field w7_5'>-</td>
    
                    <div className='acciones'>
                        <i className="bi bi-eye hover" title="Ver datos" data-bs-toggle="modal" data-bs-target={'#datosFacturaModal'}  onClick={() => cambiarModal()} ></i>
                        <i className='bi bi-printer hover' title="Imprimir" onClick={() => generarDocumentoFactura(factura.id, factura.codigoFactura)}></i>
                    </div>
                </tr>
                </div>
                );
        }
    }

    return (renderFactura());
};


const Modal = ({ onClose, data, formatearFechaAlReves}) => {
    const [currentArticulosRows, setCurrentArticulosRows] = useState(data.articulos);

    useEffect(() => {
        
    }, [currentArticulosRows]);

    const [primeraCarga, setPrimeraCarga] = useState(true);

    const [clientesBuscados, setClientesBuscados] = useState([]);
    const [articulosBuscados, setArticulosBuscados] = useState([]);
    const [cliente, setCliente] = useState();

    function onSave(){

    }

    function addModalRow(){
        var arts = [...currentArticulosRows];
        arts.push({
            'id':'',
            'nombre': '',
            'cantidad': '',
            'precio': '',
            'descuento': 0,
            'porcentajeIva':''
        });
        setCurrentArticulosRows(arts);
    }

    function removeModalRow(y){
        var arts = [];
        console.log(currentArticulosRows);
            for(var i = 0; i < currentArticulosRows.length; i++){
                var a = i;

                if(i != y){
    
                    var articuloToSave={};
                    articuloToSave['id'] = currentArticulosRows[i].idArticulo;
                    articuloToSave['nombre'] = currentArticulosRows[i].nombre;
                    articuloToSave['cantidad'] = currentArticulosRows[i].cantidad;
                    articuloToSave['precio'] = currentArticulosRows[i].precio;
                    articuloToSave['descuento'] = 0;
                    articuloToSave['porcentajeIva'] = currentArticulosRows[i].porcentajeIva;
                    
                    arts.push(articuloToSave);
                }
            }
        console.log(arts);
        setCurrentArticulosRows(arts);
    }

    function rellenarArticulo(filtro, value, a){
        
        var index = articulosBuscados.findIndex((item) => item.nombre == value);

        if(value != null){
            if(filtro == 'nombre'){
                
                document.getElementById('pcarfieldPrecio'.concat(a)).value = articulosBuscados[index].precio;

            }
        }
        let b = [...currentArticulosRows];
        for(var i = 0; i < currentArticulosRows.length; i++){
            var x = i;
            if(b[i].id == ''){
                b[i].id = articulosBuscados[index].idArticulo;
                b[i].porcentajeIva = articulosBuscados[index].porcentajeIva;
            }
            
            b[i].nombre = document.getElementById('pcarfieldNombre'.concat(x)).value;
            b[i].cantidad = document.getElementById('pcarfieldUnidades'.concat(x)).value;
            b[i].precio = document.getElementById('pcarfieldPrecio'.concat(x)).value;

        }
        setCurrentArticulosRows(b);
    }

    function renderArticulos(){
        var arts = [];
        data.albaranes.map((albaran) => {
            albaran.articulos.map((articulo, index) => {
                arts.push(renderArticulo(articulo, index, albaran.numeroAlbaran));
            });
        });
        return arts;
    }

    function renderArticulo(articulo,index, numeroAlbaran){
        return(
            <div key={'pedidoModalRow'.concat(index)} className='pedido' style={{ borderRadius:'1vw', border:'1px solid rgb(219, 219, 219)', margin:'0 0 1vh 0'}}>
                <div className='articulo-modal-header-movil' style={{margin:'1vh 0 0 0'}}>
                    <label style={{width:'78%', margin:'0 1% 0 1%'}}>Artículo del albarán {numeroAlbaran}</label>
                    <i style={{fontWeight:'bold',textAlign:'right',width:'30%', margin:'0 2% 0 1%'}} class="bi bi-x-lg hover" title="Eliminar" onClick={() => removeModalRow(index)}></i>
                </div>
                <div style={{display:'flex', justifyContent:'flex-start', width:'100%'}}>
                    <Autocomplete
                        PaperComponent={({ children }) => (
                        <Paper
                            elevation={3}
                            style={{
                                maxWidth: '100vw'
                            }}
                            >
                            {children}
                        </Paper>)}
                        disabled
                        style={{maxWidth:'100%', fontSize:'1.5vh', textAlign:'left', width:'98%', borderRadius:'1vw', border:'none', margin:'0 1% 0 1%'}}
                        onChange={(event, value) => rellenarArticulo('nombre', value, index)}
                        freeSolo
                        value={articulo.nombre}
                        id={'pcarfieldNombre'.concat(index)}
                        options={articulosBuscados.map((item) => item.nombre)}
                        renderInput={(params) => (
                            <div style={{}} ref={params.InputProps.ref}>
                                <input style={{ border: '1px solid lightgrey', borderRadius: '3px', width:'100%'}} type="text" {...params.inputProps}/>
                            </div>
                        )}
                    />  
                </div>
                <div className='articulo-modal-header-movil' style={{margin:'0.5vh 0 0 0'}}>
                    <label style={{ textAlign:'left', width:'48.5%', margin:'0 0.5% 0 1%'}}>Precio sin iva</label>
                    <label style={{ textAlign:'left', width:'48.5%', margin:'0 1% 0 0.5%'}}>Unidades</label>
                </div>
                
                <div style={{display:'flex', justifyContent:'flex-start', width:'100%', margin:'0 0 2vh 0'}}>
                    <input id={'pcarfieldPrecio'.concat(index)} disabled style={{fontSize:'1.5vh', textAlign:'left', width:'48.5%', borderRadius:'1vw', border:'1px solid rgb(219, 219, 219)', margin:'0 0.5% 0 1%'}} value={articulo.precio} onChange={(event) => handleInputChange(event, index, 'precio')}></input>
                    <input id={'pcarfieldUnidades'.concat(index)} disabled style={{fontSize:'1.5vh', textAlign:'left', width:'48.5%', borderRadius:'1vw', border:'1px solid rgb(219, 219, 219)', margin:'0 1% 0 0.5%'}} value={articulo.cantidad} onChange={(event) => handleInputChange(event, index, 'unidades')}></input>
                </div>
            </div>
            
            
        );
        
    }

    function handleInputChange(event, a, name){

        let rows = [...currentArticulosRows];
        for(var i = 0; i < currentArticulosRows.length; i++){
            rows[i].nombre = document.getElementById('pcarfieldNombre'.concat(i)).value;
            rows[i].unidades = document.getElementById('pcarfieldUnidades'.concat(i)).value;
            rows[i].precio = document.getElementById('pcarfieldPrecio'.concat(i)).value;
        }

        if(name == 'nombre'){
            rows[a].nombre = event.target.value;
        }
        if(name == 'unidades'){
            rows[a].unidades = event.target.value;
        }
        if(name == 'precio'){
            rows[a].precio = event.target.value;
        }

        setCurrentArticulosRows(rows);
    }

    
    return (
      <div className="modal-overlay" id='overlay' onClick={onClose}>
        <div className="modal-content" style={{position:'relative'}} onClick={(e) => e.stopPropagation()}>
            <h3>Factura {data.codigoFactura}</h3>
            <div style={{overflow: 'scroll', maxWidth:'100vw'}}>
                <p className='p-modal-movil'>Número factura</p>
                <input className='input-modal-movil' id='pedidoC-movil-field-numeroAlbaran' disabled value={data.codigoFactura}></input> 
                <p className='p-modal-movil'>Cliente</p>
                <input className='input-modal-movil' id='pedidoC-movil-field-cliente' disabled value={data.cliente.nombreComercial}></input> 
                <p className='p-modal-movil'>Importe</p>
                <input className='input-modal-movil' id='pedidoC-movil-field-importe'  disabled value={(data.totalConIva).toFixed(2).concat('€')}></input>
                <p className='p-modal-movil'>Fecha</p>
                <input className='input-modal-movil' id='pedidoC-movil-field-fecha' type='date' disabled value={formatearFechaAlReves(data.fecha)}></input>
                <p className='p-modal-movil'>Observaciones</p>
                <input className='input-modal-movil' id='pedidoC-movil-field-observaciones' disabled value={data.observaciones}></input>
                <p className='p-modal-movil'>Artículos</p>
                {renderArticulos()}
                <p style={{textAlign:'center', width:'100%', fontSize:'2vh', fontWeight:'bold'}} onClick={() => addModalRow()}>+</p>
            </div>
            
            <div className="modal-footer">
                <button onClick={onClose} style={{backgroundColor: 'rgb(219, 219, 219)'}}>Cerrar</button>
            </div>
        </div>
      </div>
    );
    
  };


FacturaComponent.propTypes = {

};


export default FacturaComponent;
