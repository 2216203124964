import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ModalArticuloComponent from './modalArticuloComponent';
import ArticuloComponent from '../pure/articuloComponent';
import { createArticulo, getAllArticulos, updateArticulo, findArticulos } from '../../services/fecthService';
import { Articulo } from '../../models/articulo.class';
import '../../styles/styles.scss';


const ArticulosComponent = ({data, toasting, registrarEvento}) => {

    const [filterButtonActive, setFilterButtonActive] = useState(0);

    const [articulos, setArticulos] = useState([]);

    useEffect(() => {
        obtainArticulos();
    }, []);

    const obtainArticulos = () => {
        getAllArticulos(data.token)
        .then((response) => {
            let arts = [];
            let obs = "";
            response.map((item) => {
                if(item.observaciones != null) obs = item.observaciones;
                var articulo = new Articulo(
                    item.id,
                    item.nombre,
                    item.familia,
                    item.subfamilia,
                    item.descripcion,
                    item.stockMin,
                    item.stockMax,
                    item.unidades,
                    obs,
                    item.precio,
                    item.iva,
                    item.precioProveedor,
                    item.publicable,
                    item.rotura,
                    item.descatalogado,
                    item.portada,
                    item.masVendido,
                    item.novedades,
                    item.codigoEAN13
                );
    
                arts.push(articulo);
                obs = "";
                
            });
            setArticulos(arts);
        }) 
        .catch(()=>{
            toasting('Error al recuperar artículos', 'error');
        })
        .finally(() => {
        });
    }
    
    function guardarCambios(id, key, value){
        actualizarArticulo(id, key, value);
    }

    function filterItems(item){

        if (filterButtonActive === 0 ||
            (filterButtonActive === 1 && (item.unidades <= item.stockMin))){
                return <ArticuloComponent key = {'Articulo'.concat(item.id)} articulo={item} guardarCambios={guardarCambios} ></ArticuloComponent>; 
        }
    }

    function saveNuevoarticulo(articulo){
        createArticulo(data.token,articulo)
        .then((response) => {
            toasting('Artículo '.concat(articulo.nombre).concat(' creado con éxito'), 'success');
            registrarEvento('Artículo '.concat(articulo.nombre).concat(' con ID ').concat(response.id).concat(' creado.'));
            
        }) 
        .catch(()=>{
            toasting('Error al crear el artículo', 'error');
        })
        .finally(() => {
            obtainArticulos();
        });
    }

    const actualizarArticulo = (id, key, value) => {
        updateArticulo(data.token, id, key, value)
        .then((response) => {
            toasting('Artículo actualizado con éxito', 'success');
            registrarEvento('Artículo '.concat(response.nombre).concat(' con ID ').concat(id).concat(' modificado: '.concat(key).concat('->').concat(value)));

        }) 
        .catch((error)=>{
            toasting('Error al actualizar artículo', 'error');
        })
        .finally(() => {
            obtainArticulos();
        });
    }

    function buscarArticulo(){
        let textoBuscado = document.getElementById("buscador-articulo").value;

        
        if(textoBuscado != '' && textoBuscado.length > 2){
            let split = textoBuscado.trim().split(' ');
    
                findArticulos(data.token, split)
                .then((response) => {
                    setArticulos(response.reverse());
                }) 
                .catch(()=>{
                    toasting('Error de búsqueda', 'error');
    
                })
                .finally(() => {
                });
    
            
            }else{
                obtainArticulos();
            }
        }
        

    

    return (
        
        <div>
            <h2 className='page-title'>Artículos</h2>
            <div className='upper-buttons'>
                <button type="button" id='nuevoArticuloButton' className="btn btn-primary upper-button w15 bold" data-bs-toggle="modal" data-bs-target="#nuevoArticuloModal">Nuevo artículo</button>
            </div>

            

            <ModalArticuloComponent saveNuevoarticulo={saveNuevoarticulo}></ModalArticuloComponent>
            <div className='table-container'>
                <div className='toolbar-listado'>
                    <div className='filtros-rapidos'>
                        <span className={filterButtonActive === 0 ? 'filtro-rapido filtro-activado': 'filtro-rapido'} onClick={() => {setFilterButtonActive(0);}}>Todo</span>
                        <span className={filterButtonActive === 1 ? 'filtro-rapido filtro-activado': 'filtro-rapido'} onClick={() => {setFilterButtonActive(1);}}>Pocas unidades</span>                        </div>
                    <div className='buscador'>
                        <input className='form-control me-2' id="buscador-articulo" type='search' placeholder='Buscar...' aria-label='Search' onChange={()=>buscarArticulo()}></input>
                    </div>
                </div>
                <table className='content-table'>
                            <thead className='listado-header'>   
                                <th className='listado-header-field w7_5' style={{padding: '0 0 0 1vw'}}>Código</th>
                                <th className='listado-header-field w40'>Nombre</th>
                                <th className='listado-header-field w15'>Familia</th>
                                <th className='listado-header-field w15'>Subfamilia</th>
                                <th className='listado-header-field w10'>Unidades</th>
                                <div className='acciones'>
                                    <label>Acciones</label>
                                </div>
                                
                            </thead>
                            <div className='wrapper-listado'>
                            {articulos.length > 0 
                            ? articulos.map((item) => { 
                                return filterItems(item);
                            })
                            : <div class="d-flex justify-content-center">
                                    <div class="spinner-border  spinner-border-sm  text-warning m-4" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div>
                            }
                            </div>                            
                        </table>
            </div>
        </div>
    );
};


ArticulosComponent.propTypes = {

};


export default ArticulosComponent;
