export class Usuario{
    
    id='';
    name='';
    email='';
    usuario='';
    password='';
    roles='';
    
    constructor(id, name, email, usuario, password, roles){
            this.id=id;
            this.name=name;
            this.email=email;
            this.usuario=usuario;
            this.password=password;
            this.roles=roles;
    }
}