export class Articulo{
    
    id='';
    nombre='';
    familia='';
    subfamilia='';
    descripcion='';
    stockMin='';
    stockMax='';
    unidades='';
    observaciones='';
    precio='';
    iva='';
    precioProveedor='';
    publicable='';
    rotura='';
    descatalogado='';
    portada='';
    masVendido='';
    novedades='';
    codigoEAN13='';
    
    constructor(id, nombre, familia, subfamilia, descripcion, stockMin, stockMax, 
        unidades, observaciones, precio, iva, precioProveedor, publicable, rotura, descatalogado, portada, masVendido, novedades, codigoEAN13){
            this.id=id;
            this.familia=familia;
            this.subfamilia=subfamilia;
            this.nombre=nombre;
            this.descripcion=descripcion;
            this.stockMin=stockMin;
            this.stockMax=stockMax;
            this.unidades=unidades;
            this.observaciones=observaciones;
            this.precio=precio;
            this.iva=iva;
            this.precioProveedor=precioProveedor;
            this.publicable=publicable;
            this.rotura=rotura;
            this.descatalogado=descatalogado;
            this.portada=portada;
            this.masVendido=masVendido;
            this.novedades=novedades;
            this.codigoEAN13=codigoEAN13;
    }
}