import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UsuarioComponent from '../pure/usuarioComponent';
import { Usuario } from '../../models/usuario.class';


const ModalUsuarioComponent = ({saveNuevoUsuario}) => {

    function nuevoUsuario(){
        
        const usuario = new Usuario(
            "",
            document.getElementById("usuario-modal-field-nombre").value,
            document.getElementById("usuario-modal-field-email").value,
            document.getElementById("usuario-modal-field-usuario").value,
            document.getElementById("usuario-modal-field-password").value,
            "ADMIN"
            );

        saveNuevoUsuario(usuario);
        limpiarModal();
        document.getElementById('closeUsuarioModal').click();
    }

    function modal(){
        return (<div class="modal fade" id='nuevoUsuarioModal' tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-main-title" id="exampleModalLabel">Nuevo usuario</h1>
                <button id='closeUsuarioModal' type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
                
            <div class="modal-body">
                <h6 className='modal-title'>Datos generales</h6>
                <div className='modal-row'>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Nombre</label>
                        <input className='mfinput w65' name='name' id='usuario-modal-field-nombre'></input>
                    </div>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Usuario</label>
                        <input className='mfinput w65' name='username' id='usuario-modal-field-usuario'></input>
                    </div>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Contraseña</label>
                        <input className='mfinput w65' name='password' id='usuario-modal-field-password'></input>
                    </div>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Repetir contraseña</label>
                        <input className='mfinput w65' id='usuario-modal-field-password2'></input>
                    </div>
                </div>
                <div className='modal-row'>
                    <div className='modal-field w50'>
                        <label className='cflabel w17_5'>Email</label>
                        <input className='cfinput w82_5' name='email' id='usuario-modal-field-email'></input>
                    </div>
                </div>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary modal-footer-button" data-bs-toggle="modal" data-bs-target="#nuevoUsuarioModal" onClick={() => nuevoUsuario()}>Guardar usuario</button>
            </div>
            </div>
        </div>
        </div>);
    }

    function limpiarModal(){

        document.getElementById("usuario-modal-field-nombre").value="";
        document.getElementById("usuario-modal-field-usuario").value="";
        document.getElementById("usuario-modal-field-password").value="";
        document.getElementById("usuario-modal-field-password2").value="";
        document.getElementById("usuario-modal-field-email").value="";

    }

    return (modal());
};


ModalUsuarioComponent.propTypes = {

};


export default ModalUsuarioComponent;