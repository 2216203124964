export class FacturaProveedor{
    
    id='';
    codigoFactura='';
    proveedor='';
    fecha='';
    fechaContable='';
    estado='';
    observaciones='';
    totalSinIva='';
    totalConIva='';
    document='';
    albaranes=[];
    
    constructor(id, codigoFactura, proveedor, fecha, fechaContable, estado, observaciones, totalSinIva, totalConIva, document, albaranes){
        this.id=id;
        this.codigoFactura = codigoFactura;
        this.proveedor = proveedor;
        this.fecha = fecha;
        this.fechaContable = fechaContable;
        this.estado = estado;
        this.observaciones = observaciones;
        this.totalSinIva = totalSinIva;
        this.totalConIva = totalConIva;
        this.document = document;
        this.albaranes = albaranes;
    }
}