import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ModalProveedorComponent from './modalProveedorComponent';
import ProveedorComponent from '../pure/proveedorComponent';
import { createProveedor, getAllProveedores, updateProveedor, findProveedores } from '../../services/fecthService';
import { Proveedor } from '../../models/proveedor.class';

const ProveedoresComponent = ({data, toasting, registrarEvento}) => {

    const [filterButtonActive, setFilterButtonActive] = useState(0);

    const [proveedores, setProveedores] = useState([]);

    useEffect(() => {
        obtainProveedores();
    }, []);

    const obtainProveedores = () => {
        getAllProveedores(data.token)
        .then((response) => {
            let arts = [];
            let obs = "";
            response.map((item) => {
                if(item.observaciones != null) obs = item.observaciones;
                var proveedor = new Proveedor(
                    item.id,
                    item.nombre,
                    item.nombreComercial,
                    item.cif,
                    item.direccion,
                    item.cp,
                    item.poblacion,
                    item.provincia,
                    item.tributacion,
                    item.tipoDePago,
                    item.movil,
                    item.telefono,
                    item.email,
                    item.horario,
                    item.contactoComercial,
                    item.numeroComercial,
                    item.observaciones
                );
                arts.push(proveedor);
                obs = "";
                
            });
            setProveedores(arts);
        }) 
        .catch(()=>{
            toasting('Error al cargar los proveedores', 'error');
        })
        .finally(() => {
        });
    }

    const actualizarProveedor = (id, key, value) => {
        updateProveedor(data.token, id, key, value)
        .then((response) => {
            toasting('Proveedor actualizado con éxito', 'success');
            registrarEvento('Proveedor '.concat(response.nombre).concat(' con ID ').concat(id).concat(' modificado: '.concat(key).concat('->').concat(value)));

        }) 
        .catch((error)=>{
            toasting('Error al actualizar proveedor', 'error');
        })
        .finally(() => {
            obtainProveedores();
        });
    }

    function guardarCambios(id, key, value){
        actualizarProveedor(id, key, value);
    }
    
    function filterItems(item){
        if (filterButtonActive === 0 ||
            (filterButtonActive === 1 && item.unidades === 0)||
            (filterButtonActive === 2 && item.observaciones !== "")){
                return <ProveedorComponent key = {'Proveedor'.concat(item.id)} proveedor={item} guardarCambios={guardarCambios} ></ProveedorComponent>; 
        }
    }

    function saveNuevoproveedor(proveedor){
        createProveedor(data.token,proveedor)
        .then((response) => {
            toasting('Proveedor '.concat(proveedor.nombreComercial).concat(' creado con éxito'), 'success');
            registrarEvento('Cliente '.concat(proveedor.nombreComercial).concat(' con ID ').concat(response.id).concat(' creado.'));
        }) 
        .catch(()=>{
            toasting('Error al crear el proveedor', 'error');
        })
        .finally(() => {
            obtainProveedores();
        });
    }

    function buscarProveedor(){

        let textoBuscado = document.getElementById("buscador-proveedor").value;

        if(textoBuscado != '' && textoBuscado.length > 2){
            let split = textoBuscado.trim().split(' ');

            findProveedores(data.token, split)
            .then((response) => {
                setProveedores(response.reverse());
            }) 
            .catch(()=>{
                toasting('Error de búsqueda', 'error');

            })
            .finally(() => {
            });
        }else{
            obtainProveedores();
        }
    }

    return (
        
        <div>
        
            <h2 className='page-title'>Proveedores</h2>
            <div className='upper-buttons'>
                <button type="button" id='nuevoProveedorButton' className="btn btn-primary upper-button w15 bold" data-bs-toggle="modal" data-bs-target="#nuevoProveedorModal">Nuevo proveedor</button>
            </div>

            <ModalProveedorComponent saveNuevoproveedor={saveNuevoproveedor}></ModalProveedorComponent>
            <div className='table-container'>
                <div className='toolbar-listado'>
                        <div className='filtros-rapidos'>
                            <span className={filterButtonActive === 0 ? 'filtro-rapido filtro-activado': 'filtro-rapido'} onClick={() => {setFilterButtonActive(0);}}>Todo</span>
                        </div>
                        <div className='buscador'>
                            <input className='form-control me-2' id="buscador-proveedor" type='search' placeholder='Buscar...' aria-label='Search' onChange={()=>buscarProveedor()}></input>
                        </div>
                    
                    
                </div>
                <table className='content-table'>
                            <thead className='listado-header'>  
                                <th className='listado-header-field w7_5' style={{padding: '0 0 0 1vw'}}>Código</th> 
                                <th className='listado-header-field w10'>CIF</th>
                                <th className='listado-header-field w40'>Nombre comercial</th>
                                <th className='listado-header-field w10'>Teléfono</th>
                                <th className='listado-header-field w12_5'>Provincia</th>
                                <th className='listado-header-field w15'>Población</th>
                                <div className='acciones'>
                                    <label>Acciones</label>
                                </div>
                                
                            </thead>
                            <div className='wrapper-listado'>
                            {proveedores.length > 0 
                            ? proveedores.map((item) => { 
                                return filterItems(item);
                            })
                            :<div class="d-flex justify-content-center">
                                    <div class="spinner-border  spinner-border-sm  text-warning m-4" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div>
                            }
                            </div>                      
                        </table>
            </div>
        </div>
    );
};


ProveedoresComponent.propTypes = {

};

export default ProveedoresComponent;
