import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllProveedores, findProveedorById, findProveedorByNombre, findProveedorByCif } from '../../services/fecthService';
import { getAllArticulos, findArticulos } from '../../services/fecthService';
import { Proveedor } from '../../models/proveedor.class';
import { Articulo } from '../../models/articulo.class';
import { albaranProveedorContext } from './pedidosPComponent';

const ModalDatosAlbaranProveedorComponent = ({ data, actualizarAlbaran, clearAlbaran}) => {

    const [proveedores, setProveedores] = useState([]);
    const [proveedor, setProveedor] = useState();

    const [proveedoresBuscados, setProveedoresBuscados] = useState([]);
    const [articulosBuscados, setArticulosBuscados] = useState([]);

    const[autocompleteState, setAutocompleteState] = useState(false);

    const [datosCargados, setDatosCargados] = useState(false);
    const [numeroAlbaranValue, setNumeroAlbaranValue] = useState('');
    const [proveedorIdValue, setProveedorIdValue] = useState('');
    const [proveedorNombreValue, setProveedorNombreValue] = useState('');
    const [proveedorCIFValue, setProveedorCIFValue] = useState('');
    const [fechaValue, setFechaValue] = useState('');
    const [fechaEntregaValue, setFechaEntregaValue] = useState('');
    const [observacionesValue, setObservacionesValue] = useState('');
    const [articulosValues, setArticulosValues] = useState([]);
    const [formularioCompleto, setFormularioCompleto ] = useState(true);

    const albaran = useContext(albaranProveedorContext);

   useEffect(() => {
        var m = document.getElementById('datosAlbaranProveedorModal');
        const handleC = () => {
            clearAlbaran();
            setDatosCargados(false);
        };
       
        m.addEventListener('hidden.bs.modal', handleC);
        
        return () => {
            m.removeEventListener('hidden.bs.modal', handleC);
        }
   }, [albaran]);

      const obtainProveedores = () => {
        
        if(proveedores.length == 0){
            getAllProveedores(data.token)
            .then((response) => {
                let arts = [];
                let obs = "";
                response.map((item) => {
                    if(item.observaciones != null) obs = item.observaciones;
                    var proveedor = new Proveedor(
                        item.id,
                        item.nombre,
                        item.nombreComercial,
                        item.cif,
                        item.direccion,
                        item.cp,
                        item.poblacion,
                        item.provincia,
                        item.tributacion,
                        item.tipoDePago,
                        item.movil,
                        item.telefono,
                        item.email,
                        item.observaciones
                    );
                    arts.push(proveedor);
                    obs = "";
                    
                });
                setProveedores(arts);
            }) 
            .catch(()=>{
                alert("Error al recuperar proveedores");
            })
            .finally(() => {
                
            });
        }
        
    }



    function handleProveedor(id, evento){
        if(evento != null){
            if(id == 'id'){
                setProveedorIdValue(evento.target.value);

                if(evento.target.value == ''){
                    setProveedor('');
                    setProveedorNombreValue('');
                    setProveedorCIFValue('');
                }else{
                    findProveedorById(data.token, evento.target.value)
                    .then((response) => {
                        if(response != null){
                            setProveedor(response);
                            setProveedorNombreValue(response.nombreComercial);
                            setProveedorCIFValue(response.cif);
                        }else{
                            setProveedor('');
                            setProveedorIdValue(evento.target.value);
                            setProveedorNombreValue('');
                            setProveedorCIFValue('');
                        }
                    }) 
                    .catch(()=>{
                    })
                    .finally(() => {
                    });
                }
            }else if(id == 'nombre'){
                setProveedor('');
                setProveedorIdValue('');
                setProveedorNombreValue(evento.target.value);
                setProveedorCIFValue('');
                findProveedorByNombre(data.token,  evento.target.value)
                .then((response) => {
                    setProveedoresBuscados(response);
                }) 
                .catch(()=>{
                })
                .finally(() => {
                });
            }else if(id == 'cif'){
                setProveedor('');
                setProveedorIdValue('');
                setProveedorNombreValue('');
                setProveedorCIFValue(evento.target.value);
                findProveedorByCif(data.token,  evento.target.value)
                .then((response) => {
                    setProveedoresBuscados(response);
                }) 
                .catch(()=>{
                })
                .finally(() => {
                });
            }
        }
    }

    function handleArticulo(event){
        var ar = [];
        if(event != null){
            ar.push(event.target.value);
            findArticulos(data.token, ar)
                .then((response) => {
    
                    setArticulosBuscados(response.sort((a, b) => a.nombre.localeCompare(b.nombre)));
                }) 
                .catch(()=>{
                })
                .finally(() => {
                });
        }
       
    }

    function rellenarProveedor(id, evento, valor){
        if(evento != null){
            var split = valor.split('|');
            var idC = split[0].split(':')[1].trim();
            var nombre = split[1].split(':')[1].trim();
            var cif = split[2].split(':')[1].trim();

            setProveedor(proveedoresBuscados.find((item) => item.id == idC));
            setProveedorIdValue(idC);
            setProveedorNombreValue(nombre);
            setProveedorCIFValue(cif);
            
        }   
    }

    function rellenarArticulo(id, index, valor){
        
        var arts = [...articulosValues];
        if(id == 'inventariable'){
            if(arts[index].inventariable){
                arts[index].inventariable = false;
            }else{
                arts[index].inventariable = true;
            }
        }else if(id == 'nombre'){
            var art = articulosBuscados.find((item) => item.nombre == valor);
            if(art != undefined){
                arts[index].idArticulo = art.id;
                arts[index].nombre = art.nombre;
                arts[index].precio = formatear3decimales(art.precioProveedor);
                arts[index].descuento = 0;
                arts[index].porcentajeIva = art.iva;
                arts[index].importeIva = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100))*arts[index].porcentajeIva/100);
                arts[index].subtotal = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100)));

            }else{
                arts[index].nombre = valor;
            }
            
        }else if(id == 'unidades'){
            arts[index].cantidad = valor;
            arts[index].importeIva = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100))*arts[index].porcentajeIva/100);
            arts[index].subtotal = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100)));

        }else if(id == 'precio'){
            arts[index].precio = formatear3decimales(valor);
            arts[index].importeIva = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100))*arts[index].porcentajeIva/100);
            arts[index].subtotal = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100)));

        }else if(id == 'descuento'){
            arts[index].descuento = valor;
            arts[index].importeIva = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100))*arts[index].porcentajeIva/100);
            arts[index].subtotal = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100)));

        }else if(id == 'porcentajeIva'){
            arts[index].porcentajeIva = valor;
            arts[index].importeIva = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100))*arts[index].porcentajeIva/100);
            arts[index].subtotal = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100)));

        }

        setArticulosValues(arts);
    }


    function formatear3decimales(precio){
        if(isNaN(precio)){
            precio = '0';
        }
        if (typeof precio !== 'string') {
            precio = precio.toString(); // Convertir a cadena si no lo es
        }
    
        // Verificar si hay más de tres decimales y truncarlos si es necesario
        if (/^\d*\.\d{4,}$/.test(precio)) {
            const parts = precio.split('.');
            precio = `${parts[0]}.${parts[1].substring(0, 3)}`;
        }
    
      return precio;
    }


    function today(){
        var date = new Date();
        var split = date.toLocaleDateString();
        var split2 = split.split('/');
        
        var day = split2[0];
        var month = split2[1];
        var year = split2[2];
        if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
            day = '0'.concat(day);
        }
        if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
            month = '0'.concat(month);
        }
        return year.concat('-').concat(month).concat('-').concat(day);
    }
    function formatearFecha(item){
        var date = new Date(item);
        var split = date.toLocaleDateString();
        var split2 = split.split('/');
        
        var day = split2[0];
        var month = split2[1];
        var year = split2[2];
        if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
            day = '0'.concat(day);
        }
        if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
            month = '0'.concat(month);
        }
        return day.concat('/').concat(month).concat('/').concat(year);
    }

    function formatearFechaAlReves(item){
        var date = new Date(item);
        var split = date.toLocaleDateString();
        var split2 = split.split('/');
        
        var day = split2[0];
        var month = split2[1];
        var year = split2[2];
        if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
            day = '0'.concat(day);
        }
        if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
            month = '0'.concat(month);
        }
        return year.concat('-').concat(month).concat('-').concat(day);
    }
    function mostrarTituloModal(){
       if(albaran != ''){
        if(albaran.estado == "PRESUPUESTADO"){
            return "Presupuesto ".concat(albaran.numeroAlbaran);
        }else if(albaran.estado == "ALBARANADO" || albaran.estado == "FACTURADO"){
            return "Albarán ".concat(albaran.numeroAlbaran);
        }
       }
        
  
    }

    function cambiarValorInput(id, valor){
        
        if(id == 'numeroAlbaran'){
            setNumeroAlbaranValue(valor);
        }else if(id == 'proveedorId'){
            setProveedorIdValue(valor);
        }else if(id == 'proveedorNombre'){
            setProveedorNombreValue(valor);
        }else if(id == 'proveedorCIF'){
            setProveedorCIFValue(valor);
        }else if(id == 'fecha'){
            setFechaValue(valor);
        }else if(id == 'fechaEntrega'){
            setFechaEntregaValue(valor);
        }else if(id == 'observaciones'){
            setObservacionesValue(valor);
        }
    }

    function isArticuloFilled(id, index){
        if(!formularioCompleto){
            if(id == 'nombre'){
                if(articulosValues[index].nombre == ''){
                    return ("1px solid red");
                }else{
                    return ("1px solid ligthgrey");
                }
            }else if(id == 'unidades'){
                if(articulosValues[index].unidades == ''){
                    return ({border: 'solid 1px red'});
                }else{
                    return ({});
                }
            }else if(id == 'precio'){
                if(articulosValues[index].precio == ''){
                    return ({border: 'solid 1px red'});
                }else{
                    return ({});
                }
            }else if(id == 'porcentajeIva'){
                if(articulosValues[index].porcentajeIva == ''){
                    return ({border: 'solid 1px red'});
                }else{
                    return ({});
                }
            }
        }
        
        
    }


    function isProveedorFilled(id){
        
        if(formularioCompleto){
            return ({ border: '1px solid lightgrey', borderRadius: '3px', width: '100%'});
        }else{
            if(id == 'proveedorId'){
                if(proveedorIdValue == ''){
                    return ({ border: '1px solid red', borderRadius: '3px', width: '100%'});
                }else{
                    return ({ border: '1px solid lightgrey', borderRadius: '3px', width: '100%'});
                }
            }else if(id == 'proveedorNombre'){
                if(proveedorNombreValue == ''){
                    return ({ border: '1px solid red', borderRadius: '3px', width: '100%'});
                }else{
                    return ({ border: '1px solid lightgrey', borderRadius: '3px', width: '100%'});
                }
            }if(id == 'proveedorCIF'){
                if(proveedorCIFValue == ''){
                    return ({ border: '1px solid red', borderRadius: '3px', width: '100%'});
                }else{
                    return ({ border: '1px solid lightgrey', borderRadius: '3px', width: '100%'});
                }
            }
            
        }
    }
    function modal(){
        
        if(albaran != ''){
            if(!datosCargados){
                setDatosCargados(true);
                setNumeroAlbaranValue(albaran.numeroAlbaran);
                setProveedorIdValue(albaran.proveedor.id);
                setProveedorNombreValue(albaran.proveedor.nombreComercial);
                setProveedorCIFValue(albaran.proveedor.cif);                setFechaValue(formatearFechaAlReves(albaran.fecha));
                setFechaEntregaValue(formatearFechaAlReves(albaran.fechaEntrega));
                setObservacionesValue(albaran.observaciones);
                setArticulosValues(albaran.articulos);
            }
        }

        

        return (<div class="modal fade" tabindex="-1" id='datosAlbaranProveedorModal' aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div class="modal-content">
            <div class="modal-header">
                <h1 className='modal-main-title' id="exampleModalLabel">{mostrarTituloModal()}</h1>
                <button id='closeDatosAlbaranProveedorModal' type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
                
            <div class="modal-body">
                <h6 className='modal-title'>Datos generales</h6>

                <div className='modal-row'>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Tipo</label>
                        <select className='mfselect w65' disabled={albaran.estado == 'FACTURADO'} id="pedidoC-datos-field-tipo">
                            <option value="PRESUPUESTADO" selected={albaran.estado == 'PRESUPUESTADO'}>Presupuesto</option>
                            <option value="ALBARANADO" selected={albaran.estado == 'ALBARANADO' || albaran.estado == 'FACTURADO'}>Albarán</option>
                        </select>                    
                    </div>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Nº albarán</label>
                        <input className='mfinput w65'  disabled={albaran.estado == 'FACTURADO'} value={numeroAlbaranValue} id='pedidoC-datos-field-numeroAlbaran' onChange={(event) => cambiarValorInput('numeroAlbaran', event.target.value)}></input>
                    </div>
                </div>

                <div className='separator'></div>

                <h6 className='modal-title'>Datos de proveedor</h6>
                <div className='modal-row'>
                <div className='modal-field w20'>
                        <label className='mflabel w45'>Código</label>
                        <input className='mfinput w55' style={isProveedorFilled('proveedorId')} value={proveedorIdValue} onChange={(evento) => handleProveedor('id', evento)}></input>
                    </div>
                    <div className='modal-field w60'>
                        <label className='mflabel w20'>Nombre</label>
                        <Autocomplete
                            style={{width:'80%', fontSize:'1.5vh'}}
                            freeSolo
                            key={autocompleteState}
                            value={proveedorNombreValue}
                            onInputChange={(evento) => handleProveedor('nombre', evento)} 
                            onChange={(event,value) => rellenarProveedor('nombre', event, value)}                  
                            options={proveedoresBuscados.map((item) => "Código: ".concat(item.id).concat(" | Nombre: ").concat(item.nombreComercial).concat(" | CIF: ").concat(item.cif))}
                            renderInput={(params) => (
                                <div style={{width:'100%'}} ref={params.InputProps.ref}>
                                    <input style={isProveedorFilled('proveedorNombre')} type="text" {...params.inputProps}/>
                                </div>
                            )}
                        />
                    </div> 
                    <div className='modal-field w20'>
                        <label className='mflabel w35'>CIF</label>
                        <Autocomplete
                           style={{width:'65%', fontSize:'1.5vh'}}
                            freeSolo
                            key={autocompleteState}
                            value={proveedorCIFValue}
                            onInputChange={(evento) => handleProveedor('cif', evento)} 
                            onChange={(event,value) => rellenarProveedor('cif', event, value)}                  
                            options={proveedoresBuscados.map((item) => "Código: ".concat(item.id).concat(" | Nombre: ").concat(item.nombreComercial).concat(" | CIF: ").concat(item.cif))}
                            renderInput={(params) => (
                                <div style={{width:'100%'}} ref={params.InputProps.ref}>
                                    <input style={isProveedorFilled('proveedorCIF')} type="text" {...params.inputProps}/>
                                </div>
                            )}
                        />
                    </div> 
                   
                </div>

                <div className='separator'></div>

                <h6 className='modal-title'>Datos de gestión</h6>
                <div className='modal-row'>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Fecha</label>
                        <input className='mfinput w65' disabled={albaran.estado == 'FACTURADO'} value={fechaValue} type='date' id='pedidoC-datos-field-fecha' onChange={(event) => cambiarValorInput('fecha', event.target.value)}></input>
                    </div> 
                    <div className='modal-field w25'>
                        <label className='mflabel w50'>Fecha de entrega</label>
                    <input className='mfinput w50' disabled={albaran.estado == 'FACTURADO'} value={fechaEntregaValue} type='date' id='pedidoC-datos-field-fechaEntrega' onChange={(event) => cambiarValorInput('fechaEntrega', event.target.value)}></input>
                    </div>
                </div>

                <div className='separator'></div>

                <h6 className='modal-title'>Otros</h6>
                <div className='modal-row'>
                    <label className='mflabel w8_75'>Observaciones</label>
                    <textarea className='mftextarea w91_75' id='pedidoC-datos-field-observaciones' value={observacionesValue} onChange={(event) => cambiarValorInput('observaciones', event.target.value)}></textarea>   
                </div>

                <div className='separator'></div>
                    
                <h6 className='modal-title'>Artículos</h6>
                <div className='pedido-modal-articulos-table'>
                    <div className='pmaheader w100'>
                        <label className='pmahfield w4'>Stock</label>
                        <label className='pmahfield w7_5'>Código</label>
                        <label className='pmahfield w20'>Nombre</label>
                        <label className='pmahfield w10'>Unidades</label>
                        <label className='pmahfield w10'>Precio</label>
                        <label className='pmahfield w10'>Descuento</label>
                        <label className='pmahfield w10'>IVA %</label>
                        <label className='pmahfield w10'>Importe IVA</label>
                        <label className='pmahfield w10'>Subtotal</label>
                        { albaran.estado != "FACTURADO" 
                ?<div className='acciones'>
                            <label>Acciones</label>
                        </div>
                    : <div className='acciones'>
                            <label style={{display: 'none'}}>Acciones</label>
                        </div>
                }
                        
                        
                    </div>
                    {renderArticulos().map((item) =>{
                        return item;
                    })}
                { albaran.estado != "FACTURADO" 
                ?<div className='modal-add w100'>
                        <button className='addRowButton' id="modalAddRow" onClick={()=>addModalRow()}><h3>+</h3></button>
                    </div>
                    : ''
                }
                    
                </div>
                
            </div>
            {albaran.estado != 'FACTURADO'
            ?<div class="modal-footer">
                <p>Total: {calcularTotal()}€ </p>
                <button type="button" class="btn btn-primary modal-footer-button" onClick={() => prepararActualizacion()}>Guardar cambios</button>
            </div>
            :<div class="modal-footer">
                <p>Total: {calcularTotal()}€ </p>
            </div>}
            
            </div>
        </div>
        </div>);
        
    }

    function isArticuloFilled(id, index){
        if(!formularioCompleto){
            if(id == 'nombre'){
                if(articulosValues[index].nombre == ''){
                    return ("1px solid red");
                }else{
                    return ("1px solid ligthgrey");
                }
            }else if(id == 'unidades'){
                if(articulosValues[index].cantidad == ''){
                    return ({border: 'solid 1px red'});
                }else{
                    return ({});
                }
            }else if(id == 'precio'){
                if(articulosValues[index].precio == ''){
                    return ({border: 'solid 1px red'});
                }else{
                    return ({});
                }
            }else if(id == 'porcentajeIva'){
                if(articulosValues[index].porcentajeIva == ''){
                    return ({border: 'solid 1px red'});
                }else{
                    return ({});
                }
            }
        }
        
        
    }
    
    function prepararActualizacion(){
        var a = {
            'numeroAlbaran': numeroAlbaranValue,
            'proveedor': proveedor,
            'fecha': fechaValue,
            'fechaEntrega': fechaEntregaValue,
            'observaciones': observacionesValue,
            'articulos': articulosValues
        };
        actualizarAlbaran(albaran.id, a);
        
        document.getElementById('closeDatosAlbaranProveedorModal').click();
    }

    function validarNumero(valor) {
        
        if (isNaN(valor)) {
          return "";
        } else {
          return valor;
        }
      }

      
      
    function renderArticulos(){
        let articles = [];
        for(var i = 0; i < articulosValues.length; i++){
        let a = i;

            articles.push(
                <div key={'pedidoModalRow'.concat(a)} id={'pedidoModalRow'.concat(a)} className='pmarow w100'>
                    <div className="form-check-modal w4">
                        <input className="form-check-input" type="checkbox" id={'pmarfieldStock'.concat(a)} checked={articulosValues[a].inventariable ? 'checked' :''} onChange={(event)=>rellenarArticulo('inventariable', a, event.target.value)}></input>
                    </div>
                    <input className='pmarfield w7_5' id={'pmarfieldId'.concat(a)} disabled value={validarNumero(articulosValues[a].idArticulo)}></input>
                    <Autocomplete
                            freeSolo
                            value={articulosValues[a].nombre}
                            onInputChange={(evento) => handleArticulo(evento)} 
                            onChange={(event, value) => rellenarArticulo('nombre', a, value)}
                            style={{fontSize:'1.5vh', width:'20%', margin: '0 0.2vw 0 0.2vw'}}
                            id={'pmarfieldNombre'.concat(a)}
                            options={articulosBuscados.map((item) => item.nombre)}
                            renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                    <input style={formularioCompleto || articulosValues[a].nombre != ''  ?{fontSize:'1.5vh',border: '1px solid lightgrey', borderRadius: '5px', width: '100%'}: {fontSize:'1.5vh',border: '1px solid red', borderRadius: '5px', width: '100%'}} type="text" {...params.inputProps}
                                />
                                </div>
                            )}
                        />                    
                    <input className='pmarfield w10' style={isArticuloFilled('unidades', a)} id={'pmarfieldUnidades'.concat(a)} value={articulosValues[a].cantidad} onChange={(event)=>rellenarArticulo('unidades', a, event.target.value)}></input>
                    <input className='pmarfield w10' style={isArticuloFilled('precio', a)} id={'pmarfieldPrecio'.concat(a)} value={articulosValues[a].precio} onChange={(event)=>rellenarArticulo('precio', a, event.target.value)}></input>
                    <input className='pmarfield w10' id={'pmarfieldDescuento'.concat(a)} value={articulosValues[a].descuento} onChange={(event)=>rellenarArticulo('descuento', a, event.target.value)}></input>
                    <input className='pmarfield w10' style={isArticuloFilled('porcentajeIva', a)} id={'pmarfieldPorcentajeIva'.concat(a)} value={articulosValues[a].porcentajeIva} onChange={(event)=>rellenarArticulo('porcentajeIva', a, event.target.value)}></input>
                    <input className='pmarfield w10' disabled id={'pmarfieldImporteIva'.concat(a)} value={articulosValues[a].importeIva}></input>
                    <input className='pmarfield w10' disabled id={'pmarfieldSubtotal'.concat(a)}  value={articulosValues[a].subtotal}></input>
                    <div className='acciones'>
                        <i className="bi bi-trash3" title='Eliminar' onClick={() => removeModalRow(a)}></i>
                    </div>
                </div>)
        }
        return articles;
    }

    function addModalRow(){

        let b = [...articulosValues];
        
        b.push({
            id:'',
            inventariable:true,
            idArticulo:'',
            nombre: '',
            unidades: '',
            precio: '',
            descuento: '',
            porcentajeIva:'',
            importeIva:'',
            subtotal: ''
        });
        setArticulosValues(b);

    }

    function removeModalRow(index){

        var arts = [...articulosValues];

        arts.splice(index, 1);

        setArticulosValues(arts);
    }

    function calcularTotal(){
        var total = 0;
        articulosValues.map((articulo) => {
            total = parseFloat(total) + parseFloat(articulo.importeIva) + parseFloat(articulo.subtotal);
        });
        return total.toFixed(2);
    }

    return (modal());
};


ModalDatosAlbaranProveedorComponent.propTypes = {

};


export default ModalDatosAlbaranProveedorComponent;
