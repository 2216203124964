import React from 'react';
import PropTypes from 'prop-types';
import { Articulo } from '../../models/articulo.class';
import '../../styles/styles.scss'

const ArticuloComponent = ({articulo, guardarCambios}) => {

    function handleOnChange(event){}
    
      function handleOnKeyUp(event){
        if (event.keyCode === 13) {
            guardarCambios(articulo.id, document.activeElement.name, event.target.value)
        }
      }
      function calcularPrecioIva(){
        if(document.getElementById('articulo-collapse-input-precio').value != '' && document.getElementById('articulo-collapse-input-iva').value != ''){
            document.getElementById('articulo-collapse-input-precioIva').value = document.getElementById('articulo-collapse-input-precio').value * (1+(document.getElementById('articulo-collapse-input-iva').value/100));
        }else{
            document.getElementById('articulo-collapse-input-precioIva').value ='';
        }
    }
    return (
        <div>
        <tr key={articulo.id} className='listado-row'>
            <td className='listado-field w7_5' style={{padding: '0 0 0 1vw'}}>{articulo.id}</td>
            <td className='listado-field w40 bold'>{articulo.nombre}</td>
            <td className='listado-field w15'>{articulo.familia}</td>
            <td className='listado-field w15'>{articulo.subfamilia}</td>
            <td className='listado-field w10'>{articulo.unidades == undefined ? "-" : articulo.unidades}</td>

            <div className='acciones'>
                <i className="bi bi-caret-down aicon" title="Ver datos" data-bs-toggle="collapse" data-bs-target={'#collapseExample'.concat(articulo.id)} aria-expanded="true" aria-controls="collapseExample"></i>
            </div>
        </tr>
            <div className="collapse" id={'collapseExample'.concat(articulo.id)}>
                <div className="card card-body">
                <h6 className='collapse-title'>Datos generales</h6>
                    <div className='collapse-row'>
                        <div className='collapse-field w50'>
                            <label className='cflabel w17_5'>Nombre</label>
                            <input className='cfinput w82_5' defaultValue={articulo.nombre} name='nombre' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>                       
                        </div>
                        <div className='collapse-field w50'>
                            <label className='cflabel w17_5'>Descripción</label>
                            <input className='cfinput w82_5' defaultValue={articulo.descripcion}  name='descripcion' onKeyUp={(event)=>handleOnKeyUp(event)} onChange={(event)=>handleOnChange(event)} ></input>
                        </div>
                    </div>
                    <div className='collapse-row'>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Cód. artículo</label>
                            <input className='cfinput w65' disabled value={articulo.id} ></input>
                        </div>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Familia</label>
                            <input className='cfinput w65' defaultValue={articulo.familia}  name='familia' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        </div>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Subfamilia</label>
                            <input className='cfinput w65' defaultValue={articulo.subfamilia}  name='subfamilia' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        </div>
                    </div>
                    
                    <div className='separator'></div>

                        <h6 className='collapse-title'>Precios</h6>
                    <div className='collapse-row'>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Precio</label>
                            <input className='cfinput w65' defaultValue={articulo.precio}  name='precio'  id='articulo-collapse-input-precio' onChange={() => calcularPrecioIva()} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        </div>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Precio prov.</label>
                            <input className='cfinput w65' defaultValue={articulo.precioProveedor}  name='precioProveedor' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        </div>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>IVA</label>
                            <input className='cfinput w65' defaultValue={articulo.iva}  name='iva'  id='articulo-collapse-input-iva' onChange={() => calcularPrecioIva()} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        </div>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Precio IVA</label>
                            <input className='cfinput w65' disabled defaultValue={(articulo.precio * (1+(articulo.iva/100))).toFixed(2)}  name='precioIva' id='articulo-collapse-input-precioIva'></input>
                        </div>
                        
                    </div>
                        <div className='separator'></div>

                        <h6 className='collapse-title'>Gestión de stock</h6>
                        <div className='collapse-row'>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Unidades</label>
                            <input disabled className='cfinput w65' defaultValue={articulo.unidades}  name='unidades' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        </div>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Stock mín</label>
                            <input className='cfinput w65' defaultValue={articulo.stockMin}  name='stockMin' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        </div>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Stock máx</label>
                            <input className='cfinput w65' defaultValue={articulo.stockMax}  name='stockMax' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        </div>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>EAN13</label>
                            <input className='cfinput w65' defaultValue={articulo.codigoEAN13}  name='codigoEAN13' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        </div>
                    </div>

                    <div className='separator'></div>

                    <h6 className='collapse-title'>Otros</h6>
                    <div className='collapse-row'>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Publicable</label>
                            <select className='cfselect w65' name='publicable' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}>
                                <option value="NO" selected="selected">NO</option>
                                <option value="SI">SI</option>
                            </select>
                        </div>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Rotura</label>
                            <select className='cfselect w65'  name='rotura' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}>
                                <option value="NO" selected="selected">NO</option>
                                <option value="SI">SI</option>
                            </select>
                        </div>
                        <div className='collapse-field w25'>
                            <label className='cflabel w50'>Descatalogado</label>
                            <select className='cfselect w50'  name='descatalogado' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}>
                                <option value="NO" selected="selected">NO</option>
                                <option value="SI">SI</option>
                            </select>                        
                            </div>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Portada</label>
                            <select className='cfselect w65' name='portada' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}>
                                <option value="NO" selected="selected">NO</option>
                                <option value="SI">SI</option>
                            </select>                       
                             </div>
                    </div>
                    <div className='collapse-row'>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Más vendido</label>
                            <select className='cfselect w65' name='masVendido' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}>
                                <option value="NO" selected="selected">NO</option>
                                <option value="SI">SI</option>
                            </select>
                        </div>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Novedades</label>
                            <select className='cfselect w65' name='novedades' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}>
                                <option value="NO" selected="selected">NO</option>
                                <option value="SI">SI</option>
                            </select>
                        </div>
                        
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Portada</label>
                            <select className='cfselect w65' name='portada' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}>
                                <option value="NO" selected="selected">NO</option>
                                <option value="SI">SI</option>
                            </select>
                        </div>
                    </div>
                    <div className='modal-row'>
                        
                            <label className='cflabel w8_75'>Observaciones</label>
                            <textarea className='cftextarea w91_75' defaultValue={articulo.observaciones}  name='observaciones' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></textarea>
                        </div>
                    
                </div>
            </div>
        </div>
    );
};


ArticuloComponent.propTypes = {
    articulo: PropTypes.instanceOf(Articulo)
}


export default ArticuloComponent;
