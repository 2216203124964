import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { getUser, updateUser, changePass } from '../../services/fecthService';

const PerfilComponent = ({data, toasting, registrarEvento}) => {

    const [usuario, setUsuario] = useState({});

    useEffect(() => {
        show();
     }, []);

    function show(){
        getUser(data.token, data.username)
            .then((response) => {
                setUsuario(response);
            })
            .catch(()=>{
                toasting('Error de autenticación. Recargue la página para continuar', 'error')
            })
            .finally(() => {
                
            });
    }

    function handleOnChange(event){}
      function handleOnKeyUp(event){
        if (event.keyCode === 13) {
        updateUser(data.token, usuario.id, document.activeElement.name, event.target.value)
        .then((response) => {
            toasting('Tus datos se han actualizado correctamente', 'success');
            registrarEvento('Datos del usuario '.concat(data.username).concat(' modificados: ').concat(document.activeElement.name).concat('->').concat(event.target.value));
        }) 
        .catch((error)=>{
            toasting('Error al actualizar datos', 'error');
        })
        .finally(() => {
            show();
        });
        }
      }

      function cambiarPassword(){
        var actual = document.getElementById('actual').value;
        var nueva = document.getElementById('nueva').value;
        var confirmarNueva = document.getElementById('confirmar-nueva').value;

        if(nueva != confirmarNueva){
            toasting('Las contraseñas no coinciden', 'error');
        }
        else{
            changePass(data.token, usuario.id, actual, nueva)
            .then((response) => {
                console.log(response);
                if(response == 0){
                    toasting('Contraseña actualizada correctamente', 'success');
                    registrarEvento('Contraseña del usuario '.concat(data.username).concat(' modificada.'));
                }else{
                    toasting('La contraseña no es correcta', 'error');
                }
            }) 
            .catch((error)=>{
                toasting('Error al cambiar la contraseña', 'error');
            })
            .finally(() => {
            });
        }
      }

    return (
        
        <div>
            <h2 className='page-title'>Perfil</h2>
            <fieldset className='fieldset-styled'>
                <legend className='bold' style={{fontSize:'2vh'}}>Datos</legend>
                
                    <div className='row mb20 mt15'>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Nombre</label>
                            <input className='cfinput w65' name='name' defaultValue={usuario.name} onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>                       
                        </div>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Usuario</label>
                            <input className='cfinput w65' name='usuario' defaultValue={usuario.usuario} onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        </div>
                        <div className='collapse-field w50'>
                            <label className='cflabel w17_5'>Email</label>
                            <input className='cfinput w82_5' name='email' defaultValue={usuario.email} onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        </div>
                    </div>
                
            </fieldset>
            <fieldset className='fieldset-styled'>
                <legend className='bold' style={{fontSize:'2vh'}}>Cambiar contraseña</legend>
                <div className='row mb20 mt15'>
                    <div className='collapse-field w33'>
                        <label className='cflabel w35'>Actual</label>
                        <input type='password' className='cfinput w65' id='actual'></input>                       
                    </div>
                    <div className='collapse-field w33'>
                        <label className='cflabel w35'>Nueva</label>
                        <input type='password' className='cfinput w65' id='nueva'></input>
                    </div>
                    <div className='collapse-field w33'>
                        <label className='cflabel w35'>Confirmar nueva</label>
                        <input type='password' className='cfinput w65' id='confirmar-nueva'></input>
                    </div>
                </div>

                <div className='row mb20 mt15 flex-center'>
                    <button className='btn btn-warning w15'  style={{fontSize:'1.7vh'}} onClick={()=>cambiarPassword()}>Cambiar contraseña</button>
                </div>
            </fieldset>
        </div>
    );
};


PerfilComponent.propTypes = {

};

export default PerfilComponent;
