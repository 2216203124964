import React, { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { Albaran } from '../../models/albaran.class';
import { Factura } from '../../models/factura.class';
import { Cliente } from '../../models/cliente.class';
import { Articulo } from '../../models/articulo.class';
import AlbaranComponent from '../pure/albaranComponent';
import FacturaComponent from '../pure/facturaComponent';
import ModalAlbaranComponent from './modalAlbaranComponent';
import { findClientes } from '../../services/fecthService';
import { findArticulos } from '../../services/fecthService';
import Paper from '@mui/material/Paper';

import Autocomplete from '@mui/material/Autocomplete';

import { createAlbaran, createFactura, getAllAlbaranes, getAllFacturas, getAllClientes, getAllArticulos, updateAlbaran, updateFactura, updateArticulo, getAlbaran, getArticulo, generarAlbaran, generarFactura, generarVistaPrevia, deleteAlbaran, findAlbaranes, findFacturas, getFacturasPagadas, getFacturasPendientes, getFacturasEmitidas, getAlbaranesByClienteId, getFacturasByClienteId } from '../../services/fecthService';
import ModalDatosAlbaranComponent from './modalDatosAlbaranComponent';
import ModalDatosFacturaComponent from './modalDatosFacturaComponent';


export const albaranContext = React.createContext();
export const facturaContext = React.createContext();

const PedidosCComponent = ({data, toasting, registrarEvento}) => {

    const [numberListItemsSelected, setNumberListItemsSelected] = useState(0);
    const [listItemsSelected, setListItemsSelected] = useState([]);

    const [filterButtonActive, setFilterButtonActive] = useState(0);
    
    const [albaranes, setAlbaranes] = useState([]);
    const [facturas, setFacturas] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [articulos, setArticulos] = useState([]);

    const [isMobile, setIsMobile] = useState(false);
    const [activeTab, setActiveTab] = useState('tab1');

        // Estado para controlar si el modal está abierto o cerrado
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAlbaranModalOpen, setIsAlbaranModalOpen] = useState(false);
    const [datosAlbaran, setDatosAlbaran] = useState('');
    const [datosFactura, setDatosFactura] = useState('');

    const [filtroEstado, setFiltroEstado] = useState("TODAS");
    const [filtroCliente, setFiltroCliente] = useState('1');



    useEffect(() => {
        obtainAlbaranes();
        obtainFacturas();
        obtainClientes();
        obtainArticulos();

        const handleResize = () => {
                 // eslint-disable-next-line no-restricted-globals
            setIsMobile(screen.width < 768);
        };
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
              cambiarFiltroCliente();
            }
        }
        if(!isMobile){
            const inputElement = document.getElementById('filtroCliente');
            inputElement.addEventListener('keydown', handleKeyPress);
            handleResize();
            window.addEventListener('resize', handleResize);
        
            const modal = document.getElementById('filtroClienteModal');
        
            const handleModalShown = () => {
              const inputElement = document.getElementById('filtroCliente');
        
              inputElement.focus();
            };
        }
    const inputElement = document.getElementById('filtroCliente');
    inputElement.addEventListener('keydown', handleKeyPress);
    handleResize();
    window.addEventListener('resize', handleResize);

    const modal = document.getElementById('filtroClienteModal');

    const handleModalShown = () => {
      const inputElement = document.getElementById('filtroCliente');

      inputElement.focus();
    };

    modal.addEventListener('shown.bs.modal', handleModalShown);


    return () => {
        if(!isMobile){
            inputElement.removeEventListener('keydown', handleKeyPress);

        }
        window.removeEventListener('resize', handleResize);
        modal.removeEventListener('shown.bs.modal', handleModalShown);
    };
    },[]);

    function clearAlbaran(){
        setDatosAlbaran('');
    }
    function clearFactura(){
        setDatosFactura('');
    }

    function today(){
        var date = new Date();
        var split = date.toLocaleDateString();
        var split2 = split.split('/');
        
        var day = split2[0];
        var month = split2[1];
        var year = split2[2];
        if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
            day = '0'.concat(day);
        }
        if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
            month = '0'.concat(month);
        }
        return year.concat('-').concat(month).concat('-').concat(day);
    }

    const obtainAlbaranes = () => {
        getAllAlbaranes(data.token)
        .then((response) => {
            let albs = [];
            response.map((item) => {
               
                var albaran = new Albaran(
                    item.id,
                    item.numeroAlbaran,
                    item.cliente,
                    item.fecha,
                    item.fechaEntrega,
                    item.estado,
                    item.observaciones,
                    item.articulos,
                    item.responsable
                );
                
                albs.push(albaran);                
            });
            setAlbaranes(albs);
        }) 
        .catch(()=>{
            toasting('Error al cargar los albaranes', 'error');
        })
        .finally(() => {
        });
    }

    function obtainAlbaran(id){
        getAlbaran(data.token, id)
        .then((response) => {
            return response;
        }) 
        .catch(()=>{
        })
        .finally(() => {
            obtainAlbaranes();
        });
    }

    const obtainFacturas = () => {
        getAllFacturas(data.token)
        .then((response) => {
            let facts = [];
            response.map((item) => {
               
                var factura = new Factura(
                    item.id,
                    item.codigoFactura,
                    item.cliente,
                    item.fecha,
                    item.estado,
                    item.observaciones,
                    item.totalSinIva,
                    item.totalConIva,
                    item.albaranes
                );
                
                facts.push(factura);
            });

            setFacturas(facts);
        }) 
        .catch(()=>{
            toasting('Error al cargar las facturas', 'error');
        })
        .finally(() => {
        });
    }

    const obtainFacturasPendientes = () => {
        getFacturasPendientes(data.token)
        .then((response) => {
            let facts = [];
            response.map((item) => {
               
                var factura = new Factura(
                    item.id,
                    item.codigoFactura,
                    item.cliente,
                    item.fecha,
                    item.estado,
                    item.observaciones,
                    item.totalSinIva,
                    item.totalConIva,
                    item.albaranes
                );
                
                facts.push(factura);
            });

            setFacturas(facts);
        }) 
        .catch(()=>{
            toasting('Error al cargar las facturas', 'error');
        })
        .finally(() => {
        });
    }

    const obtainFacturasEmitidas = () => {
        getFacturasEmitidas(data.token)
        .then((response) => {
            let facts = [];
            response.map((item) => {
               
                var factura = new Factura(
                    item.id,
                    item.codigoFactura,
                    item.cliente,
                    item.fecha,
                    item.estado,
                    item.observaciones,
                    item.totalSinIva,
                    item.totalConIva,
                    item.albaranes
                );
                
                facts.push(factura);
            });

            setFacturas(facts);
        }) 
        .catch(()=>{
            toasting('Error al cargar las facturas', 'error');
        })
        .finally(() => {
        });
    }

    const obtainFacturasPagadas = () => {
        getFacturasPagadas(data.token)
        .then((response) => {
            let facts = [];
            response.map((item) => {
               
                var factura = new Factura(
                    item.id,
                    item.codigoFactura,
                    item.cliente,
                    item.fecha,
                    item.estado,
                    item.observaciones,
                    item.totalSinIva,
                    item.totalConIva,
                    item.albaranes
                );
                
                facts.push(factura);
            });

            setFacturas(facts);
        }) 
        .catch(()=>{
            toasting('Error al cargar las facturas', 'error');
        })
        .finally(() => {
        });
    }

    const obtainClientes = () => {
        
        if(clientes.length == 0){
            getAllClientes(data.token)
            .then((response) => {
                let arts = [];
                response.map((item) => {
                    var cliente = new Cliente(
                        item.id,
                        item.nombre,
                        item.nombreComercial,
                        item.cif,
                        item.direccion,
                        item.cp,
                        item.poblacion,
                        item.provincia,
                        item.tributacion,
                        item.tipoDePago,
                        item.movil,
                        item.telefono,
                        item.email,
                        item.observaciones
                    );
                    arts.push(cliente);
                    
                });
                setClientes(arts);
            }) 
            .catch(()=>{
                toasting('Error al cargar los clientes', 'error');
            })
            .finally(() => {
                
            });
        }
        
    }

    const obtainArticulos = () => {
        getAllArticulos(data.token)
        .then((response) => {
            let arts = [];
            let obs = "";
            response.map((item) => {
                if(item.observaciones != null) obs = item.observaciones;
                var articulo = new Articulo(
                    item.id,
                    item.nombre,
                    item.familia,
                    item.subfamilia,
                    item.descripcion,
                    item.stockMin,
                    item.stockMax,
                    item.unidades,
                    obs,
                    item.precio,
                    item.iva,
                    item.publicable,
                    item.rotura,
                    item.descatalogado,
                    item.portada,
                    item.masVendido,
                    item.novedades,
                    item.codigoEAN13
                );
                arts.push(articulo);
                obs = "";
                
            });
            setArticulos(arts);
            
        }) 
        .catch(()=>{
            toasting('Error al cargar los artículos', 'error');
        })
        .finally(() => {
        });
    }

    const openModal = () => {
    
        setIsModalOpen(true);
      };
    
      // Función para cerrar el modal
      const closeModal = () => {
        setIsModalOpen(false);
      };
      
   function generarDocumentoFactura(id,cod){
    
        generarFactura(data.token, id)
        .then((response) => {
            registrarEvento('Documento generado para la factura '.concat(cod).concat('.'));
            return response.blob();
        })
        .then((blob) => {
            var url = URL.createObjectURL(blob);
            var myWindow = window.open(url);
            myWindow.print();
            return url;
        })
        .then((href) => {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = href;
        a.download = "Factura ".concat(cod);
        a.click();
        })
        .catch((error)=>{
            toasting('Error al generar la factura', 'error');
        })
        .finally(() => {
            obtainFacturas();
        });
    }
    function generarDocumentoAlbaran(id,cod){
    
        generarAlbaran(data.token, id)
        .then((response) => {
            registrarEvento('Documento generado para el albarán '.concat(cod).concat('.'));
            return response.blob();
        })
        .then((blob) => {
            var url = URL.createObjectURL(blob);
            var myWindow = window.open(url);
            myWindow.print();
            return url;
        })
        .then((href) => {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = href;
        a.download = "Albarán ".concat(cod);
        a.click();
        })
        .catch((error)=>{
            toasting('Error al generar el albarán', 'error');
        })
        .finally(() => {
        });
    }


    function setTitle(){
        let i = filterButtonActive;

        if(i === 0){
            return "Albaranes";
        }else if(i === 1){
            return "Facturas";
        }
    }

    function sayModalIsOpen(){
        setIsAlbaranModalOpen(true);
    }

    function sayModalIsNotOpen(){
        setIsAlbaranModalOpen(false);
    }

    function sayDatosAlbaranModal(albaran){
        setDatosAlbaran(albaran);
    }
    function sayDatosFacturaModal(factura){
        setDatosFactura(factura);
    }

    function mostrarPedidos(){
        if(albaranes.length > 0){
            if(isMobile){
                if(activeTab === 'tab1'){
                    var albs = [];
                    console.log(albaranes);
                    albaranes.map((item,index) => {
                        albs.push(
                            <AlbaranComponent key = {'albaran'.concat(item.id)} albaran={item} clientes={clientes} articulos={articulos} active={filterButtonActive} toasting={toasting} modificarAlbaran={modificarAlbaran} obtainAlbaran={obtainAlbaran} checkboxChanged={checkboxChanged} facturarUno = {facturarUno} generarDocumentoAlbaran={generarDocumentoAlbaran} borrarAlbaran={borrarAlbaran} data={data} sayModalIsOpen={sayModalIsOpen} sayModalIsNotOpen={sayModalIsNotOpen} sayDatosAlbaranModal={sayDatosAlbaranModal}  setDatosAlbaran={setDatosAlbaran}></AlbaranComponent>
                        );
                    });

                    return albs;
                }
                else{
                    var facts = [];

                    facturas.map((item,index) => {
                        facts.push(
                            <FacturaComponent key = {'factura'.concat(item.id)} factura={item} active={filterButtonActive} articulos={articulos} modificarFactura={modificarFactura} pagar = {pagar} generarDocumentoFactura={generarDocumentoFactura} data={data} sayModalIsOpen={sayModalIsOpen} sayModalIsNotOpen={sayModalIsNotOpen}/*checkboxChanged={checkboxChanged} modificarAlbaran={modificarAlbaran} albaranarUno = {albaranarUno} facturarUno = {facturarUno} */></FacturaComponent>
                        );
                    });
                    return facts;
                }
            }else{
                if(filterButtonActive === 0){
                    var albs = [];
                    
                    albaranes.map((item,index) => {
                        albs.push(
                            <AlbaranComponent key = {'albaran'.concat(item.id)} albaran={item} clientes={clientes} articulos={articulos} active={filterButtonActive} toasting={toasting} modificarAlbaran={modificarAlbaran} obtainAlbaran={obtainAlbaran} checkboxChanged={checkboxChanged} facturarUno = {facturarUno} generarDocumentoAlbaran={generarDocumentoAlbaran} borrarAlbaran={borrarAlbaran} data={data} sayModalIsOpen={sayModalIsOpen} sayModalIsNotOpen={sayModalIsNotOpen} sayDatosAlbaranModal={sayDatosAlbaranModal}  setDatosAlbaran={setDatosAlbaran}></AlbaranComponent>
                        );
                    });
    
                    return albs;
                }else if(filterButtonActive === 1){
                    var facts = [];
    
                    facturas.map((item,index) => {
                        facts.push(
                            <FacturaComponent key = {'factura'.concat(item.id)} factura={item} active={filterButtonActive} articulos={articulos} modificarFactura={modificarFactura} pagar = {pagar} generarDocumentoFactura={generarDocumentoFactura} data={data} sayModalIsOpen={sayModalIsOpen} sayModalIsNotOpen={sayModalIsNotOpen} sayDatosFacturaModal={sayDatosFacturaModal} /*checkboxChanged={checkboxChanged} modificarAlbaran={modificarAlbaran} albaranarUno = {albaranarUno} facturarUno = {facturarUno} */></FacturaComponent>
                        );
                    });
                    return facts;
                }
            }
            
        }else{
            return (
                <div class="d-flex justify-content-center">
                                    <div class="spinner-border  spinner-border-sm  text-warning m-4" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div>
            );
        }
    }
    function gestionarStockAlbaran(albaran, operacion){
        if(operacion == '+'){
            albaran.articulos.map((articulo) => {

                var id = articulos.find((item) => item.nombre == articulo.nombre).id;

                getArticulo(data.token, id)
                    .then((response) => {
                        updateArticulo(data.token, id, 'unidades', parseInt(response.unidades) + parseInt(articulo.cantidad))
                            .then((response) => {
                                toasting('Artículo actualizado con éxito', 'success');
                            }) 
                            .catch((error)=>{
                                toasting('Error al actualizar artículo', 'error');
                            })
                            .finally(() => {
                                obtainArticulos();
                            });
                }) 
                .catch(()=>{
        
                })
                .finally(() => {
                });
            });
        }else{
            console.log(albaran);
            albaran.articulos.map((articulo) => {

                getArticulo(data.token, articulo.idArticulo)
                    .then((response) => {
                        updateArticulo(data.token, articulo.idArticulo, 'unidades', parseInt(response.unidades) - parseInt(articulo.cantidad))
                            .then((response) => {
                                toasting('Artículo actualizado con éxito', 'success');
                            }) 
                            .catch((error)=>{
                                toasting('Error al actualizar artículo', 'error');
                            })
                            .finally(() => {
                                obtainArticulos();
                            });
                }) 
                .catch(()=>{
        
                })
                .finally(() => {
                });
            });
        }
    }

    function saveNuevoAlbaran(albaran){
        createAlbaran(data.token,albaran)
        .then((response) => {
            toasting('Albarán creado con éxito', 'success');
            gestionarStockAlbaran(albaran, '-');
            registrarEvento('Albarán '.concat(' con ID ').concat(response.id).concat(' creado.'));

        }) 
        .catch(()=>{
            toasting('Error al crear el albarán', 'error');

        })
        .finally(() => {
            obtainAlbaranes();
        });
    }

    function modificarAlbaran(id, albaran){

        updateAlbaran(data.token, id, albaran)
        .then((response) => {
            obtainAlbaranes();
            toasting('Albarán actualizado con éxito', 'success');
            /*if(key != 'cliente'){
                registrarEvento('Albarán con ID '.concat(id).concat(' modificado: '.concat(key).concat(' -> ').concat(value)));
            }else{
                registrarEvento('Albarán con ID '.concat(id).concat(' modificado: '.concat(key).concat(' -> ').concat(value.nombreComercial)));
            }*/
            
        }) 
        .catch((error)=>{
            toasting('Error al actualizar albarán', 'error');
        })
        .finally(() => {
            obtainAlbaranes();
        });
    }

    function borrarAlbaran(albaran){
        deleteAlbaran(data.token, albaran.id)
        .then((response) => {
            toasting('Albarán eliminado con éxito', 'success');
            gestionarStockAlbaran(albaran, '+');
            registrarEvento('Albarán '.concat(' con ID ').concat(albaran.id).concat(' eliminado.'));

        }) 
        .catch(()=>{
            toasting('Error al eliminar el albarán', 'error');

        })
        .finally(() => {
            obtainAlbaranes();
        });
    }

    function modificarFactura(id, factura){

        updateFactura(data.token, id, factura)
        .then((response) => {
            toasting('Factura actualizada con éxito', 'success');
            /*registrarEvento('Factura con ID '.concat(id).concat(' modificada: '.concat(factura.).concat('->').concat(value)));*/

        }) 
        .catch((error)=>{
            toasting('Error al actualizar factura', 'error');
        })
        .finally(() => {
            obtainAlbaranes();
            obtainFacturas();
        });
    }

    function formatearFecha(item){
        var date = new Date(item);
        var split = date.toLocaleDateString();
        var split2 = split.split('/');
        
        var day = split2[0];
        var month = split2[1];
        var year = split2[2];
        if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
            day = '0'.concat(day);
        }
        if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
            month = '0'.concat(month);
        }
        return day.concat('-').concat(month).concat('-').concat(year);
    }

    function buscar(){
        let textoBuscado = document.getElementById("buscador").value;
        

        if(textoBuscado != ''){
        let split = textoBuscado.trim().split(' ');
        
        if(filterButtonActive == 0){

            findAlbaranes(data.token, split)
            .then((response) => {
                setAlbaranes(response.reverse());
            }) 
            .catch(()=>{
                toasting('Error de búsqueda', 'error');

            })
            .finally(() => {
            });

        }else{
            findFacturas(data.token, split)
            .then((response) => {
                setFacturas(response.reverse());
            }) 
            .catch(()=>{
                toasting('Error de búsqueda', 'error');

            })
            .finally(() => {
            });

        }
        }else{
            obtainAlbaranes();
            obtainFacturas();
        }
        
    }

    function saveNuevaFactura(factura){
        createFactura(data.token,factura)
        .then((response) => {
            toasting('Factura creada con éxito', 'success');
            registrarEvento('Factura con ID '.concat(response.id).concat(' creado.'));
            return true;

        }) 
        .catch(()=>{
            toasting('Error al crear la factura', 'error');
            return false;
        })
        .finally(() => {
            obtainFacturas();
        });
    }

    function checkboxChanged(event, id){
        let number = numberListItemsSelected;
        
        var itemSelected = [...listItemsSelected];
        if(event.target.checked){
            number = number + 1;
            itemSelected.push(id);
        }
        else {
            itemSelected = itemSelected.filter((item) => item != id);
            number = number - 1;
        }

        updateSelected(number, itemSelected);
    }

    function updateSelected (number, itemSelected){
        setListItemsSelected(itemSelected);
        setNumberListItemsSelected(number);
    }
/*
    function selectAllCheckboxes(event){
        if(event.target.checked){
            resetAllCheckboxes();
            
            var itemSelected = [];
            pedidosC.map((item) => {
                itemSelected.push(item.id);
            });
            setNumberListItemsSelected(itemSelected.length);
            setListItemsSelected(itemSelected);

            document.getElementById('flexCheckDefaultAll').checked = false;
            [].slice.call(document.getElementsByClassName('form-check-input')).map((item)=>{
                item.checked=true;
            });

        }else{
            resetAllCheckboxes();
        }
    }
*/

/*
    function resetAllCheckboxes(){
        if(filterButtonActive !== 0)
            document.getElementById('flexCheckDefaultAll').checked = false;
        [].slice.call(document.getElementsByClassName('form-check-input')).map((item)=>{
            item.checked=false;
        });
        setNumberListItemsSelected(0);
        setListItemsSelected([]);
    }
    */

    function cambiarFiltroEstado(){
        setFiltroCliente('1');
        var estado = document.getElementById('filtroEstado').value;
        if(estado == "TODAS"){
            obtainFacturas();
        }else if(estado == "SIN EMITIR"){
            obtainFacturasPendientes();
        }
        else if(estado == "PENDIENTE DE PAGO"){
            obtainFacturasEmitidas();
        }else if(estado == "PAGADA"){
            obtainFacturasPagadas();
        }
        setFiltroEstado(estado);
        
    }

    function mostrarFiltroEstado(){
        if(filtroEstado === "TODAS"){
            return (<select id='filtroEstado' onChange={() => cambiarFiltroEstado()}>
                        <option value="TODAS" selected>Todas</option>
                        <option value="SIN EMITIR">Sin emitir</option>
                        <option value="PENDIENTE DE PAGO">Pendiente de pago</option>
                        <option value="PAGADA">Pagada</option>
                    </select>);
        }else if(filtroEstado === "SIN EMITIR"){
            return (<select id='filtroEstado' onChange={() => cambiarFiltroEstado()}>
                        <option value="TODAS" >Todas</option>
                        <option value="SIN EMITIR" selected>Sin emitir</option>
                        <option value="PENDIENTE DE PAGO">Pendiente de pago</option>
                        <option value="PAGADA">Pagada</option>
                    </select>);
        }else if(filtroEstado === "PENDIENTE DE PAGO"){
            return (<select id='filtroEstado' onChange={() => cambiarFiltroEstado()}>
                        <option value="TODAS" >Todas</option>
                        <option value="SIN EMITIR" >Sin emitir</option>
                        <option value="PENDIENTE DE PAGO" selected>Pendiente de pago</option>
                        <option value="PAGADA">Pagada</option>
                    </select>);
        }
        else if(filtroEstado === "PAGADA"){
            return (<select id='filtroEstado' onChange={() => cambiarFiltroEstado()}>
                        <option value="TODAS" >Todas</option>
                        <option value="SIN EMITIR" >Sin emitir</option>
                        <option value="PENDIENTE DE PAGO" >Pendiente de pago</option>
                        <option value="PAGADA" selected>Pagada</option>
                    </select>);
        }
    }

    function facturar(){
        var totalSinIva = 0;
        var totalConIva = 0;
        let albs = [];
        let cliente = albaranes.find(x => x.id == listItemsSelected[0]).cliente.id;
        var same = true;


        listItemsSelected.map((item) => {
            let alb = albaranes.find(x => x.id == item);
            if(alb.cliente.id != cliente){
                same = false;
            }
            albs.push(alb);
            alb.articulos.map((articulo) => {
                totalSinIva = totalSinIva + (articulo.cantidad * articulo.precio);
                totalConIva = totalConIva + ((articulo.cantidad * articulo.precio) * (1+(articulo.porcentajeIva/100)))
            });
        });
        if(same){
            var date = new Date().toISOString();        

        var factura = new Factura(
            '',
            '1',
            albs[0].cliente,
            date,
            'PENDIENTE',
            '',
            totalSinIva,
            totalConIva,
            albs
        );

        saveNuevaFactura(factura);
        listItemsSelected.map((item) => {
            modificarAlbaran(item, {'estado': 'FACTURADO'});
        });
        setListItemsSelected([]);
        setNumberListItemsSelected(0);
        }else{
            toasting('Facturación con distintos clientes', 'error');
        }
        
    }

    function facturarUno(albaran){
        
        var totalSinIva = 0;
        var totalConIva = 0;

        albaran.articulos.map((articulo) => {
            totalSinIva = totalSinIva + (articulo.cantidad * articulo.precio);
            totalConIva = totalConIva + ((articulo.cantidad * articulo.precio) * (1+(articulo.porcentajeIva/100)));
            
        });


        var albs = [];
        albs.push(albaran); 

        let factura = new Factura(
            '',
            '',
            albaran.cliente,
            new Date(),
            'PENDIENTE',
            '',
            totalSinIva,
            totalConIva,
            albs
        );

        saveNuevaFactura(factura);
        modificarAlbaran(albaran.id,{ 'estado': 'FACTURADO'});  

    }

    function pagar(id){
        modificarFactura(id, { 'estado': 'PAGADA'});
    }

    function vistaPrevia(){
        let albs = [];
        var same = true;
        var cliente = albaranes.find(x => x.id == listItemsSelected[0]).cliente.id;
        
        listItemsSelected.map((item) => {
            let alb = albaranes.find(x => x.id == item);
            if(cliente != alb.cliente.id){
                same = false;
            }
            albs.push(alb);
        });

        if(same){
            generarVistaPrevia(data.token,albs)
            .then((response) => response.blob())
                .then((blob) => URL.createObjectURL(blob))
                .then((href) => {
                const a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = href;
                a.download = "Vista previa";
                a.click();
                })
                .catch((error)=>{
                    toasting('Error al generar el documento', 'error');
                })
                .finally(() => {
                });
        }else{
            toasting('Vista previa con distintos clientes', 'error');
        }
        

        
        //setListItemsSelected([]);
        //setNumberListItemsSelected(0);
    }
    const TabsComponent = () => {
        const [activeTab, setActiveTab] = useState('tab1');
      
        const handleTabClick = (tab) => {
          setActiveTab(tab);
        };
    }

    const handleTabClick = (tab) => {
        setActiveTab(tab);
      };

      function cambiarFiltroCliente(){
        if(filtroCliente === '1'){
            let albs = [];
            let facts = [];
            var id = document.getElementById('filtroCliente').value;
            getAlbaranesByClienteId(data.token, id)
            .then((response) => {
            
            response.map((item) => {
               
                var albaran = new Albaran(
                    item.id,
                    item.numeroAlbaran,
                    item.cliente,
                    item.fecha,
                    item.fechaEntrega,
                    item.estado,
                    item.observaciones,
                    item.articulos,
                    item.responsable
                );
                
                albs.push(albaran);                
            });

                getFacturasByClienteId(data.token, id)
                .then((response) => {
                
                response.map((item) => {
                
                    var factura = new Factura(
                        item.id,
                        item.codigoFactura,
                        item.cliente,
                        item.fecha,
                        item.estado,
                        item.observaciones,
                        item.totalSinIva,
                        item.totalConIva,
                        item.albaranes
                    );
                    
                    facts.push(factura);               
                });
                setAlbaranes(albs);
                setFacturas(facts);
                setFiltroCliente(id);
            }) 
            .catch(()=>{
                toasting('Error al filtrar las facturas', 'error');
            })
            .finally(() => {
            });
           
        }) 
        .catch(()=>{
            toasting('Error al filtrar los albaranes', 'error');
        })
        .finally(() => {
        });
        
        document.getElementById('closeFiltroClienteModal').click();            
        }else{
            document.getElementById('filtroCliente').value = '';
            setFiltroCliente('1');
            obtainAlbaranes();
            obtainFacturas();
        }
      }

      function mostrarFiltroCliente(){
        if(filtroCliente == '1'){
            return(
                <i style={{padding: '3px 0 0 3px'}} class="bi bi-filter hover" data-bs-toggle="modal" data-bs-target="#filtroClienteModal" ></i>
            );
        }else{
            return(
                <i style={{padding: '3px 0 0 3px'}} class="bi bi-x hover" onClick={() => cambiarFiltroCliente()}></i>
            );
        }
      }
    
    function renderPedidosC() {
    if(!isMobile){
    
        return(
            <div>
            
            <ModalAlbaranComponent saveNuevoAlbaran={saveNuevoAlbaran} data={data}></ModalAlbaranComponent>
            <albaranContext.Provider value={datosAlbaran}>
                <ModalDatosAlbaranComponent data={data} actualizarAlbaran={modificarAlbaran} clearAlbaran={clearAlbaran}></ModalDatosAlbaranComponent>
            </albaranContext.Provider>
            <facturaContext.Provider value={datosFactura}>
                <ModalDatosFacturaComponent data={data} actualizarFactura={modificarFactura} clearFactura={clearFactura}></ModalDatosFacturaComponent>
            </facturaContext.Provider>

            <h2 className='page-title'>Pedidos de clientes - {setTitle()}</h2>
            <div className='upper-buttons'>
                {(numberListItemsSelected > 0 && filterButtonActive === 0) 
                ? 
                <>
                    <button type="button" className="btn btn-primary upper-button" onClick={() => vistaPrevia()}>Vista previa</button>
                    <button type="button" className="btn btn-primary upper-button" onClick={() => facturar()}>Facturar</button>
                </>
                : '' }
                <button type="button" id='nuevoPedidoButton' className="btn btn-primary upper-button" data-bs-toggle="modal" data-bs-target="#nuevoPedidoModal">Nuevo pedido</button>
            </div>

            <div className='table-container'>
                        <div className='toolbar-listado'>
                            <div className='filtros-rapidos'>
                                <span className={filterButtonActive === 0 ? 'filtro-rapido filtro-activado': 'filtro-rapido'} onClick={() => {/*resetAllCheckboxes();*/ document.getElementById('buscador').value=''; setFilterButtonActive(0);}}>Albaranes</span>
                                <span className={filterButtonActive === 1 ? 'filtro-rapido filtro-activado': 'filtro-rapido'} onClick={() => {/*resetAllCheckboxes();*/ document.getElementById('buscador').value='';setFilterButtonActive(1);}}>Facturas</span>
                            </div>
                            <div className='buscador'>
                                <input className='form-control me-2' id="buscador" type='search' placeholder='Buscar...' aria-label='Search' onChange={()=>buscar()}></input>
                            </div>
                        </div>
                        <table className='content-table'>
                            <thead className='listado-header'>
                                <th>
                                    <div className="form-check">
                                        <input className="form-check-input" style = {{opacity: '0%'}} type="checkbox" value="" id="flexCheckDefaultAll" /*onChange={(event) => selectAllCheckboxes(event)}*/></input>
                                    </div>
                                </th>
                                <th className='listado-header-field w7_5'>
                                    {filterButtonActive == 0 
                                        ? 'Nº albarán'
                                        : 'Nº factura'
                                    }
                                </th>
                                
                                    <div class="modal fade" id='filtroClienteModal' tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-s">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h6  id="exampleModalLabel">Filtrar cliente</h6>
                                                    <button id='closeFiltroClienteModal' type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                
                                                <div class="modal-body">
                                                    <label style={{fontSize:'1.5vh'}}>Nº cliente: </label>
                                                    <input style={{fontSize:'1.5vh',borderRadius:'5px', border:'1px solid grey',margin: '0 0 0 4px'}} id='filtroCliente'></input> 
                                                </div>
                                                <div class="modal-footer">
                                                    <div class="d-grid gap-2 col-4 mx-auto">
                                                        <button class="btn btn-warning" type="button" onClick={() => cambiarFiltroCliente()}>Aplicar filtro</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                <th className='listado-header-field w7_5'>Nº cliente {mostrarFiltroCliente()}</th>
                                <th className='listado-header-field w32_5'>Cliente</th>
                                <th className='listado-header-field w10'>Fecha</th>
                                <th className='listado-header-field w15'>Estado {filterButtonActive == 1 ? mostrarFiltroEstado(): ''}</th>
                                <th className='listado-header-field w10'>Importe</th>
                                <th className='listado-header-field w7_5'>Responsable</th>
                                <div className='acciones'>
                                    <label>Acciones</label>
                                </div>
                                
                            </thead>
                            <div className='wrapper-listado'>
                            {mostrarPedidos()}

                            </div>
                            
                    
                                                 
                        </table>
                    </div>
        </div>
        );
    }
    else{
        return(
                         // eslint-disable-next-line no-restricted-globals  
            <div style={{position:'relative', width: '100vw'}}>
            <albaranContext.Provider value={datosAlbaran}>
                <ModalDatosAlbaranComponent data={data} actualizarAlbaran={modificarAlbaran} clearAlbaran={clearAlbaran}></ModalDatosAlbaranComponent>
            </albaranContext.Provider>
            <facturaContext.Provider value={datosFactura}>
                <ModalDatosFacturaComponent data={data} actualizarFactura={modificarFactura} clearFactura={clearFactura}></ModalDatosFacturaComponent>
            </facturaContext.Provider>
             {isModalOpen && (
                    <Modal
                        onClose={closeModal} // Pasamos la función para cerrar el modal
                        data={data}
                        saveNuevoAlbaran={saveNuevoAlbaran}
                    />
                
        
            )}
                <ModalAlbaranComponent saveNuevoAlbaran={saveNuevoAlbaran} data={data}></ModalAlbaranComponent>
                
                <h2 className='page-title'>Pedidos de clientes</h2>

                <div className='table-container'>
                    <div className="tabs-container">
                        {/* Pestañas */}
                        <div className={`tab ${activeTab === 'tab1' ? 'active' : ''}`} onClick={() => handleTabClick('tab1')}>
                            Albaranes
                        </div>
                        <div className={`tab ${activeTab === 'tab2' ? 'active' : ''}`} onClick={() => handleTabClick('tab2')}>
                            Facturas
                        </div>
                        
                    </div>
                    <div className="tab-content">
                            {activeTab === 'tab1' && 
                                <div style={{width:'100%'}}>
                                    <div className='toolbar-listado'>
                                    </div>
                                    <table className='content-table'>
                                        <thead className='listado-header'>
                                            <th className='listado-header-field' style={{margin:'0 0 0 2vw', width:'25%'}}>
                                                {activeTab === 'tab1' 
                                                    ? 'Nº albarán'
                                                    : 'Nº factura'
                                                }
                                            </th>
                                            <th className='listado-header-field' style={{width:'55%'}}>Cliente</th>
                                            <div className='acciones' style={{width:'20%'}}>
                                                <label>Acciones</label>
                                            </div>
                                            
                                        </thead>
                                        <div className='wrapper-listado'>
                                            {mostrarPedidos()}
                                        </div>
                                    </table>
                                </div>
                            }
                            {activeTab === 'tab2' && 
                            <div style={{width:'100%'}}>
                                    <div className='toolbar-listado'>
                                    </div>
                                    <table className='content-table' style={{height:'65%'}}>
                                        <thead className='listado-header'>
                                            <th className='listado-header-field' style={{margin:'0 0 0 2vw', width:'25%'}}>
                                                {activeTab === 'tab1' 
                                                    ? 'Nº albarán'
                                                    : 'Nº factura'
                                                }
                                            </th>
                                            <th className='listado-header-field' style={{width:'55%'}}>Cliente</th>
                                            <div className='acciones' style={{width:'20%'}}>
                                                <label>Acciones</label>
                                            </div>
                                            
                                        </thead>
                                        <div className='wrapper-listado'>
                                            {mostrarPedidos()}
                                        </div>
                                    </table>
                                </div>
                            }
                    </div>
                </div>
                {/* Barra de búsqueda */}
                <input type="text" className="search-bar"  style={isAlbaranModalOpen ? {zIndex:'-1'}: {}} id='buscador' placeholder="Buscar..." onChange={()=>buscar()}/>
                
                {/* Botón circular */}
                <button className="floating-button" disabled={activeTab === 'tab1'? false : true} style={setStyleAddAlbaran()} onClick={() => openModal()}>+</button>
            </div>
        );
    
    }

        
       
    }
    function setStyleAddAlbaran(){
        var style = {};
        if(isAlbaranModalOpen){
            style.zIndex = '-1';
        }
        if(activeTab === 'tab2'){
            style.opacity = '0';
        }

        return style;
    }

    return (
        renderPedidosC()
    );

};
const Modal = ({ onClose, data, saveNuevoAlbaran }) => {
    const [currentArticulosRows, setCurrentArticulosRows] = useState([]);

    useEffect(() => {
        
    }, [currentArticulosRows]);

    const [primeraCarga, setPrimeraCarga] = useState(true);

    const [clientesBuscados, setClientesBuscados] = useState([]);
    const [articulosBuscados, setArticulosBuscados] = useState([]);
    const [cliente, setCliente] = useState();

    function onSave(){

    }

    function addModalRow(){
        var arts = [...currentArticulosRows];
        arts.push({
            'id':'',
            'nombre': '',
            'unidades': '',
            'precio': ''
        });
        setCurrentArticulosRows(arts);
    }

    function removeModalRow(y){
        var arts = [];
            for(var i = 0; i < currentArticulosRows.length; i++){
                var a = i;

                if(i != y){
    
                    var articuloToSave={};
                    articuloToSave['nombre'] = document.getElementById('pcarfieldNombre'.concat(a)).value;
                    articuloToSave['unidades'] = parseInt(document.getElementById('pcarfieldUnidades'.concat(a)).value);
                    articuloToSave['precio'] = parseFloat(document.getElementById('pcarfieldPrecio'.concat(a)).value);
                    arts.push(articuloToSave);
                }
            }

        setCurrentArticulosRows(arts);
    }

    function rellenarArticulo(filtro, value, a){
        
        var index = articulosBuscados.findIndex((item) => item.nombre == value);

        if(value != null){
            if(filtro == 'nombre'){
                
                document.getElementById('pcarfieldPrecio'.concat(a)).value = articulosBuscados[index].precio;

            }
        }
        let b = [...currentArticulosRows];
        for(var i = 0; i < currentArticulosRows.length; i++){
            var x = i;
            if(b[i].id == ''){
                b[i].id = articulosBuscados[index].id;
                b[i].porcentajeIva = articulosBuscados[index].iva;
            }
            
            b[i].nombre = document.getElementById('pcarfieldNombre'.concat(x)).value;
            b[i].unidades = document.getElementById('pcarfieldUnidades'.concat(x)).value;
            b[i].precio = document.getElementById('pcarfieldPrecio'.concat(x)).value;

        }
        setCurrentArticulosRows(b);
    }

    function renderArticulo(articulo,index){
        return(
            <div key={'pedidoModalRow'.concat(index)} className='pedido' style={{ borderRadius:'1vw', border:'1px solid rgb(219, 219, 219)', margin:'0 0 1vh 0'}}>
                <div className='articulo-modal-header-movil' style={{margin:'1vh 0 0 0'}}>
                    <label style={{width:'78%', margin:'0 1% 0 1%'}}>Artículo</label>
                    <i style={{fontWeight:'bold',textAlign:'right',width:'17%', margin:'0 2% 0 1%'}} class="bi bi-x-lg hover" title="Eliminar" onClick={() => removeModalRow(index)}></i>
                </div>
                <div style={{display:'flex', justifyContent:'flex-start', width:'100%'}}>
                    <Autocomplete
                        PaperComponent={({ children }) => (
                        <Paper
                            elevation={3}
                            style={{
                                maxWidth: '100vw'
                            }}
                            >
                            {children}
                        </Paper>)}
                        style={{maxWidth:'100%', fontSize:'1.5vh', textAlign:'left', width:'98%', borderRadius:'1vw', border:'none', margin:'0 1% 0 1%'}}
                        onInputChange={(evento) => handleArticulo(evento)} 
                        onChange={(event, value) => rellenarArticulo('nombre', value, index)}
                        freeSolo
                        defaultValue={articulo.nombre}
                        id={'pcarfieldNombre'.concat(index)}
                        options={articulosBuscados.map((item) => item.nombre)}
                        renderInput={(params) => (
                            <div style={{}} ref={params.InputProps.ref}>
                                <input style={{ border: '1px solid lightgrey', borderRadius: '3px', width:'100%'}} type="text" {...params.inputProps}/>
                            </div>
                        )}
                    />  
                </div>
                <div className='articulo-modal-header-movil' style={{margin:'0.5vh 0 0 0'}}>
                    <label style={{ textAlign:'left', width:'48.5%', margin:'0 0.5% 0 1%'}}>Precio sin iva</label>
                    <label style={{ textAlign:'left', width:'48.5%', margin:'0 1% 0 0.5%'}}>Unidades</label>
                </div>
                
                <div style={{display:'flex', justifyContent:'flex-start', width:'100%', margin:'0 0 2vh 0'}}>
                    <input id={'pcarfieldPrecio'.concat(index)} style={{fontSize:'1.5vh', textAlign:'left', width:'48.5%', borderRadius:'1vw', border:'1px solid rgb(219, 219, 219)', margin:'0 0.5% 0 1%'}} value={articulo.precio} onChange={(event) => handleInputChange(event, index, 'precio')}></input>
                    <input id={'pcarfieldUnidades'.concat(index)} style={{fontSize:'1.5vh', textAlign:'left', width:'48.5%', borderRadius:'1vw', border:'1px solid rgb(219, 219, 219)', margin:'0 1% 0 0.5%'}} value={articulo.unidades} onChange={(event) => handleInputChange(event, index, 'unidades')}></input>
                </div>
            </div>
            
            
        );
        
    }

    function renderArticulos(){
        var arts = [];
        currentArticulosRows.map((item, index) => {
            arts.push(renderArticulo(item, index));
        });
        return arts;
    }

    function handleCliente(event){
        if(event != null){
            var cl = [];
        
            cl.push(event.target.value);
            findClientes(data.token, cl)
                .then((response) => {
                    setClientesBuscados(response.reverse());
                }) 
                .catch(()=>{
                })
                .finally(() => {
                });
        }
    }

    function handleArticulo(event){
        if(event != null){
            var ar = [];
            ar.push(event.target.value);
            findArticulos(data.token, ar)
                .then((response) => {
                    setArticulosBuscados(response.reverse());
                }) 
                .catch(()=>{
                })
                .finally(() => {
                });
        }
        
    }

    function rellenarCliente(value){
        if(value != null){
            var split = value.split('|');
            setCliente(clientesBuscados.find((item) => item.id == split[2]));
        }   
    }

    function handleInputChange(event, a, name){

        let rows = [...currentArticulosRows];
        for(var i = 0; i < currentArticulosRows.length; i++){
            rows[i].nombre = document.getElementById('pcarfieldNombre'.concat(i)).value;
            rows[i].unidades = document.getElementById('pcarfieldUnidades'.concat(i)).value;
            rows[i].precio = document.getElementById('pcarfieldPrecio'.concat(i)).value;
        }

        if(name == 'nombre'){
            rows[a].nombre = event.target.value;
        }
        if(name == 'unidades'){
            rows[a].unidades = event.target.value;
        }
        if(name == 'precio'){
            rows[a].precio = event.target.value;
        }

        setCurrentArticulosRows(rows);
    }

    function nuevoAlbaran(){
        
        var articulosToSave = [];
        for(var i = 0; i < currentArticulosRows.length; i++){
            var articuloToSave ={};

            articuloToSave['idArticulo'] = currentArticulosRows[i].id;
            articuloToSave['nombre'] = document.getElementById('pcarfieldNombre'.concat(i)).value;
            articuloToSave['precio'] = document.getElementById('pcarfieldPrecio'.concat(i)).value;
            articuloToSave['cantidad'] = document.getElementById('pcarfieldUnidades'.concat(i)).value;
            articuloToSave['descuento'] = 0;
            articuloToSave['porcentajeIva'] = currentArticulosRows[i].porcentajeIva;
            articuloToSave['importeIva'] =
                (
                    (
                        ( articuloToSave['precio']*articuloToSave['cantidad'] )
                        *
                        ( 1-articuloToSave['descuento']/100 )
                    )
                    *
                    articuloToSave['porcentajeIva']/100
                ).toFixed(2);

            articuloToSave['subtotal'] = 
                (
                    (
                        (
                            ( articuloToSave['precio']*articuloToSave['cantidad'] )
                            *
                            ( 1-articuloToSave['descuento']/100 )
                        )
                    ) 
                    + 
                    (
                        ( articuloToSave['precio']*articuloToSave['cantidad'] )
                        *
                        ( 1-articuloToSave['descuento']/100 )
                    )
                    *
                    articuloToSave['porcentajeIva']/100
                ).toFixed(2)


            articulosToSave.push(articuloToSave);
        }
        
        const albaran = new Albaran(
            "",
            document.getElementById("pedidoC-modal-field-numeroAlbaran").value,
            cliente,
            document.getElementById("pedidoC-modal-field-fecha").value,
            document.getElementById("pedidoC-modal-field-fechaEntrega").value,
            'ALBARANADO',
            document.getElementById("pedidoC-modal-field-observaciones").value,
            articulosToSave,
            data.username
        );
        saveNuevoAlbaran(albaran);
       // limpiarModal();
        document.getElementById('overlay').click();
    }

    return (
                    <div className="modal-overlay" id='overlay' onClick={onClose}>

        <div className="modal-content" style={{position:'relative'}} onClick={(e) => e.stopPropagation()}>
            <h3>Nuevo albarán</h3>
            <div style={{overflow: 'scroll', maxWidth:'100vw'}}>
                <p className='p-modal-movil'>Número albarán</p>
                <input className='input-modal-movil' id='pedidoC-modal-field-numeroAlbaran'></input> 
                <p className='p-modal-movil'>Cliente</p>
                <Autocomplete
                    PaperComponent={({ children }) => (
                    <Paper
                        elevation={3}
                        style={{
                            maxWidth: '100vw'
                        }}
                        >
                        {children}
                    </Paper>)}
                    style={{maxWidth:'100%', fontSize:'1.5vh'}}
                    onInputChange={(evento) => handleCliente(evento)} 
                    onChange={(event,value) => rellenarCliente(value)}                  
                    freeSolo
                    id="autocomplete-cliente"
                    options={clientesBuscados.map((item) => item.nombreComercial.concat(" | ").concat(item.cif).concat(" | ").concat(item.id))}
                    renderInput={(params) => (
                        <div style={{}} ref={params.InputProps.ref}>
                            <input style={{ border: '1px solid lightgrey', borderRadius: '3px', width:'100%'}} type="text" {...params.inputProps}/>
                        </div>
                    )}
                />  
                <p className='p-modal-movil'>Fecha</p>
                <input className='input-modal-movil' id='pedidoC-modal-field-fecha' type='date' defaultValue={new Date().toISOString().split('T')[0]}></input>
                <p className='p-modal-movil'>Fecha entrega</p>
                <input className='input-modal-movil' id='pedidoC-modal-field-fechaEntrega' type='date' defaultValue={new Date().toISOString().split('T')[0]}></input>
                <p className='p-modal-movil'>Observaciones</p>
                <input className='input-modal-movil' id='pedidoC-modal-field-observaciones'></input>
                <p className='p-modal-movil'>Artículos</p>
                {renderArticulos()}
                <p style={{textAlign:'center', width:'100%', fontSize:'2vh', fontWeight:'bold'}} onClick={() => addModalRow()}>+</p>
            </div>
            
            <div className="modal-footer">
                <button onClick={onClose} style={{backgroundColor: 'rgb(219, 219, 219)'}}>Cerrar</button>
                <button onClick={() => nuevoAlbaran()}>Guardar cambios</button>
            </div>
        </div>
        </div>
    );
    
  };


// Paso 1: Definir el contexto
const MyContext = createContext();

// Paso 2: Componente proveedor de contexto
function MyContextProvider({ children }) {
  // Estado para almacenar el valor del contexto
  const [contextValue, setContextValue] = useState(null);

  // Función para actualizar el valor del contexto
  const updateContextValue = (value) => {
    setContextValue(value);
  };

  return (
    <MyContext.Provider value={{ contextValue, updateContextValue }}>
      {children}
    </MyContext.Provider>
  );
}
  
PedidosCComponent.propTypes = {

};
export default PedidosCComponent;