import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { Proveedor } from '../../models/proveedor.class';

const ProveedorComponent = ({proveedor, guardarCambios}) => {

    useEffect(() => {
        console.log(proveedor.horario);
      }, []);

      function handleOnChange(event){}
      function handleOnKeyUp(event){
        if (event.keyCode === 13) {
            if(document.activeElement.name == 'horario'){
                guardarCambios(proveedor.id, document.activeElement.name,
                    document.getElementById("proveedor-collapse-field-horasApertura").value
                    .concat(':')
                    .concat(document.getElementById("proveedor-collapse-field-minutosApertura").value)
                    .concat('-')
                    .concat(document.getElementById("proveedor-collapse-field-horasCierre").value)
                    .concat(':')
                    .concat(document.getElementById("proveedor-collapse-field-minutosCierre").value)
            )}else{
                guardarCambios(proveedor.id, document.activeElement.name, event.target.value)
            }
        }
      }


    return (
        <div>
        <tr key={proveedor.id} className='listado-row'>
            <td className='listado-field w7_5' style={{padding: '0 0 0 1vw'}}>{proveedor.id}</td>
            <td className='listado-field w10'>{proveedor.cif}</td>
            <td className='listado-field w40 bold'>{proveedor.nombreComercial}</td>
            <td className='listado-field w10'>{proveedor.telefono}</td>
            <td className='listado-field w12_5'>{proveedor.provincia}</td>
            <td className='listado-field w15'>{proveedor.poblacion}</td>

            <div className='acciones'>
                <i className="bi bi-caret-down" title="Ver datos" data-bs-toggle="collapse" data-bs-target={'#collapseExample'.concat(proveedor.id)} aria-expanded="true" aria-controls="collapseExample"></i>
            </div>
        </tr>
            <div className="collapse" id={'collapseExample'.concat(proveedor.id)}>
                <div className="card card-body">
                <h6 className='collapse-title'>Datos generales</h6>
                <div className='collapse-row'>
                   <div className='collapse-field w50'>
                        <label className='cflabel w17_5'>Nombre</label>
                        <input className='cfinput w82_5' defaultValue={proveedor.nombre} name='nombre' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>                       
                    </div>
                    <div className='collapse-field w50'>
                        <label className='cflabel w30'>Nombre comercial</label>
                        <input className='cfinput w70' defaultValue={proveedor.nombreComercial} name='nombreComercial' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                    </div>
                </div>
                <div className='collapse-row'>
                    <div className='collapse-field w25'>
                        <label className='cflabel w35'>CIF</label>
                        <input className='cfinput w65' defaultValue={proveedor.cif} name='cif' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                    </div>
                </div>
                      
                <div className='separator'></div>
                
                <h6 className='collapse-title'>Datos económicos</h6>
                <div className='collapse-row'>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Tributación</label>
                            <select className='cfselect w65' id="proveedor-modal-field-tributacion"  name='tributacion' defaultValue={proveedor.tipoDePago} onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}>
                                <option value="NACIONAL" selected="selected">Nacional</option>
                                <option value="CYM">Ceuta y Melilla</option>
                                <option value="CANARIAS">Canarias</option>
                                <option value="UE">Unión Europea</option>
                                <option value="EXTRACOMUNITARIO">Extracomunitario</option>
                            </select>                        </div>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Tipo de pago</label>
                            <select className='cfselect w65' id="proveedor-modal-field-tipoDePago"  name='tipoDePago' defaultValue={proveedor.tipoDePago} onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}>
                                <option value="CONTADO" selected="selected">Contado</option>
                                <option value="TARJETA">Tarjeta</option>
                                <option value="TRANSFERENCIA">Transferencia</option>
                                <option value="D10">Pago a 10 días</option>
                                <option value="D30">Pago a 30 días</option>
                                <option value="D45">Pago a 45 días</option>
                                <option value="D60">Pago a 60 días</option>
                            </select>
                        </div>
                </div>
                    
                <div className='separator'></div>
                
                <h6 className='collapse-title'>Localización</h6>
                <div className='collapse-row'>
                    <div className='collapse-field w50'>
                        <label className='cflabel w17_5'>Dirección</label>
                        <input className='cfinput w82_5' defaultValue={proveedor.direccion}  name='direccion' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                    </div>
                    <div className='collapse-field w25'>
                        <label className='cflabel w35'>Provincia</label>
                        <input className='cfinput w65' defaultValue={proveedor.provincia}  name='provincia' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                    </div>
                    <div className='collapse-field w25'>
                        <label className='cflabel w35'>Población</label>
                        <input className='cfinput w65' defaultValue={proveedor.poblacion}  name='poblacion' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                    </div>
                </div>
                <div className='collapse-row'>
                    <div className='collapse-field w25'>
                        <label className='cflabel w35'>Código postal</label>
                        <input className='cfinput w65' defaultValue={proveedor.cp}  name='cp' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                    </div>  
                </div>
            
                <div className='separator'></div>
            
                <h6 className='collapse-title'>Contacto</h6>
                <div className='collapse-row'>
                        <div className='collapse-field w50'>
                            <label className='cflabel w17_5'>Email</label>
                            <input className='cfinput w82_5' defaultValue={proveedor.email}  name='email' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        </div>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Teléfono</label>
                            <input className='cfinput w65' defaultValue={proveedor.telefono}  name='telefono' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        </div>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Móvil</label>
                            <input className='cfinput w65' defaultValue={proveedor.movil}  name='movil' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        </div>
                </div>
                <div className='collapse-row'>
                    <div className='collapse-field w25'>
                        <label className='cflabel w35'>Comercial</label>
                        <input className='cfinput w65' defaultValue={proveedor.contactoComercial} name='contactoComercial' id='proveedor-collapse-field-contactoComercial' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                    </div>
                    <div className='collapse-field w25'>
                        <label className='cflabel w35'><i class="bi bi-telephone" style={{margin:'0 2px 0 2px'}}></i>Comercial</label>
                        <input className='cfinput w65' defaultValue={proveedor.numeroComercial} name='numeroComercial' id='proveedor-collapse-field-numeroComercial' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                    </div>
                    <div className='modal-field w25'>{
                    /*
                    <label className='cflabel w35'>Horario</label>
                            <input className='mfinput center w10' defaultValue={((proveedor.horario != null) &&  (proveedor.horario != 'undefined')) ? proveedor.horario.split('-')[0].split(':')[0] : ''} name='horario' id='proveedor-collapse-field-horasApertura' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                            <label className='cflschedule w3'>:</label>
                            <input className='mfinput center w10' defaultValue={((proveedor.horario != null) &&  (proveedor.horario != 'undefined')) ? proveedor.horario.split('-')[0].split(':')[1] : ''} name='horario' id='proveedor-collapse-field-minutosApertura' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                            <label className='cflschedule w5'>-</label>
                            <input className='mfinput center w10' defaultValue={((proveedor.horario != null) &&  (proveedor.horario != 'undefined')) ? proveedor.horario.split('-')[1].split(':')[0] : ''} name='horario' id='proveedor-collapse-field-horasCierre' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                            <label className='cflschedule w3'>:</label>
                            <input className='mfinput center w10' defaultValue={((proveedor.horario != null) &&  (proveedor.horario != 'undefined')) ? proveedor.horario.split('-')[1].split(':')[1] : ''} name='horario' id='proveedor-collapse-field-minutosCierre' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        
                    */
                    }
                    <label className='cflabel w35'>Horario</label>
                            <input className='mfinput center w10'></input>
                            <label className='cflschedule w3'>:</label>
                            <input className='mfinput center w10'></input>                            <label className='cflschedule w5'>-</label>
                            <input className='mfinput center w10'></input>                            <label className='cflschedule w3'>:</label>
                            <input className='mfinput center w10'></input>                        
                            </div>
                </div>
                
                <div className='separator'></div>
        
                <h6 className='collapse-title'>Otros</h6>
                <div className='collapse-row'>
                        <div className='collapse-field w100'>
                            <label className='cflabel w8_75'>Observaciones</label>
                            <textarea className='cftextarea w91_75' defaultValue={proveedor.observaciones}  name='observaciones' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></textarea>
                        </div>
                </div>
                    
                </div>
            </div>
        </div>
    );
};


ProveedorComponent.propTypes = {
    proveedor: PropTypes.instanceOf(Proveedor)
}


export default ProveedorComponent;
