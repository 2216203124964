import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Albaran } from '../../models/albaran.class';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllClientes, findClienteById, findClienteByNombre, findClienteByCif } from '../../services/fecthService';
import { getAllArticulos, findArticulos } from '../../services/fecthService';
import { Cliente } from '../../models/cliente.class';
import { Articulo } from '../../models/articulo.class';

const ModalAlbaranComponent = ({saveNuevoAlbaran, data}) => {

    const [clientes, setClientes] = useState([]);
    const [articulos, setArticulos] = useState([]);
    const [cliente, setCliente] = useState();

    const [clientesBuscados, setClientesBuscados] = useState([]);
    const [articulosBuscados, setArticulosBuscados] = useState([]);

    const[autocompleteState, setAutocompleteState] = useState(false);

    const [ tipoValue, setTipoValue] = useState('ALBARANADO');
    const [ numeroAlbaranValue, setNumeroAlbaranValue] = useState('');
    const [ clienteIdValue, setClienteIdValue] = useState('');
    const [ clienteNombreValue, setClienteNombreValue] = useState('');
    const [ clienteCIFValue, setClienteCIFValue] = useState('');
    const [ fechaValue, setFechaValue] = useState(today());
    const [ fechaEntregaValue, setFechaEntregaValue] = useState(today());
    const [ observacionesValue, setObservacionesValue ] = useState('');
    const [ articulosValues, setArticulosValues ] = useState([
        {
            id:'',
            idArticulo:'',
            nombre: '',
            unidades: '',
            precio: '',
            descuento: '0',
            porcentajeIva:'',
            importeIva:'',
            subtotal: ''
        }
    ]);

    const [ formularioCompleto, setFormularioCompleto ] = useState(true);

   useEffect(() => {
    obtainClientes();
    obtainArticulos();
    const modalPedido = document.getElementById('nuevoPedidoModal');

    // Agregar un escuchador al evento 'shown.bs.modal'
    const handlePedidoModalClosed = () => {
        if(autocompleteState == false){
            setAutocompleteState(true);
        }else{
            setAutocompleteState(false);
        }
        setTipoValue('ALBARANADO');
        setNumeroAlbaranValue('');
        setClienteIdValue('');
        setClienteNombreValue('');
        setClienteCIFValue('');
        setFechaValue(today());
        setFechaEntregaValue(today());
        setObservacionesValue('');
        setArticulosValues([
            {
                id:'',
                idArticulo:'',
                nombre: '',
                unidades: '',
                precio: '',
                descuento: '',
                porcentajeIva:'',
                importeIva:'',
                subtotal: ''
            }
        ]);
        setArticulosBuscados([]);
        setClientesBuscados([]);
        setFormularioCompleto(true);
    };

    modalPedido.addEventListener('hidden.bs.modal', handlePedidoModalClosed);

    return () => {
        modalPedido.removeEventListener('hidden.bs.modal', handlePedidoModalClosed);
    };
   }, []);

    const obtainClientes = () => {
        
        getAllClientes(data.token)
        .then((response) => {
            let arts = [];
            let obs = "";
            response.map((item) => {
                if(item.observaciones != null) obs = item.observaciones;
                var cliente = new Cliente(
                    item.id,
                    item.nombre,
                    item.nombreComercial,
                    item.cif,
                    item.direccion,
                    item.cp,
                    item.poblacion,
                    item.provincia,
                    item.tributacion,
                    item.tipoDePago,
                    item.movil,
                    item.telefono,
                    item.email,
                    item.observaciones
                );
                arts.push(cliente);
                obs = "";
                
            });
            setClientesBuscados(arts);
        }) 
        .catch(()=>{
            alert("Error al recuperar clientes");
        })
        .finally(() => {
            
        });
    
    }

    const obtainArticulos = () => {
        getAllArticulos(data.token)
        .then((response) => {
            let arts = [];
            let obs = "";
            response.map((item) => {
                if(item.observaciones != null) obs = item.observaciones;
                var articulo = new Articulo(
                    item.id,
                    item.nombre,
                    item.familia,
                    item.subfamilia,
                    item.descripcion,
                    item.stockMin,
                    item.stockMax,
                    item.unidades,
                    obs,
                    item.precio,
                    item.iva,
                    item.publicable,
                    item.rotura,
                    item.descatalogado,
                    item.portada,
                    item.masVendido,
                    item.novedades,
                    item.codigoEAN13
                );
                arts.push(articulo);
                obs = "";
                
            });
            setArticulos(arts);
            
        }) 
        .catch(()=>{
            alert("Error al recuperar artículos");
        })
        .finally(() => {
        });
    }

    function handleCliente(id, evento){
        if(evento != null){
            if(id == 'id'){
                setClienteIdValue(evento.target.value);

                if(evento.target.value == ''){
                    setCliente('');
                    setClienteNombreValue('');
                    setClienteCIFValue('');
                }else{
                    findClienteById(data.token, evento.target.value)
                    .then((response) => {
                        if(response != null){
                            setCliente(response);
                            setClienteNombreValue(response.nombreComercial);
                            setClienteCIFValue(response.cif);
                        }else{
                            setCliente('');
                            setClienteIdValue(evento.target.value);
                            setClienteNombreValue('');
                            setClienteCIFValue('');
                        }
                    }) 
                    .catch(()=>{
                    })
                    .finally(() => {
                    });
                }
            }else if(id == 'nombre'){
                setCliente('');
                setClienteIdValue('');
                setClienteNombreValue(evento.target.value);
                setClienteCIFValue('');
                findClienteByNombre(data.token,  evento.target.value)
                .then((response) => {
                    setClientesBuscados(response);
                }) 
                .catch(()=>{
                })
                .finally(() => {
                });
            }else if(id == 'cif'){
                setCliente('');
                setClienteIdValue('');
                setClienteNombreValue('');
                setClienteCIFValue(evento.target.value);
                findClienteByCif(data.token,  evento.target.value)
                .then((response) => {
                    setClientesBuscados(response);
                }) 
                .catch(()=>{
                })
                .finally(() => {
                });
            }
        }
    }

    function handleArticulo(event){
        var ar = [];
        if(event != null){
            ar.push(event.target.value);
            findArticulos(data.token, ar)
                .then((response) => {
    
                    setArticulosBuscados(response.sort((a, b) => a.nombre.localeCompare(b.nombre)));
                }) 
                .catch(()=>{
                })
                .finally(() => {
                });
        }
        
    }

    function rellenarCliente(id, evento, valor){
        if(evento != null){
            var split = valor.split('|');
            var idC = split[0].split(':')[1].trim();
            var nombre = split[1].split(':')[1].trim();
            var cif = split[2].split(':')[1].trim();

            setCliente(clientesBuscados.find((item) => item.id == idC));
            setClienteIdValue(idC);
            setClienteNombreValue(nombre);
            setClienteCIFValue(cif);
            
        }   
    }

    function rellenarArticulo(id, index, valor){
        
        var arts = [...articulosValues];

        if(id == 'nombre'){
            var art = articulosBuscados.find((item) => item.nombre == valor);
            if(art != undefined){
                arts[index].idArticulo = art.id;
                arts[index].nombre = art.nombre;
                arts[index].precio = formatear3decimales(art.precio);
                arts[index].descuento = 0;
                arts[index].porcentajeIva = art.iva;
                arts[index].importeIva = formatear3decimales(((arts[index].precio*arts[index].unidades)*(1-arts[index].descuento/100))*arts[index].porcentajeIva/100);
                arts[index].subtotal = formatear3decimales(((arts[index].precio*arts[index].unidades)*(1-arts[index].descuento/100)));

            }else{
                arts[index].nombre = valor;
            }
            
        }else if(id == 'unidades'){
            arts[index].unidades = valor;
            arts[index].importeIva = formatear3decimales(((arts[index].precio*arts[index].unidades)*(1-arts[index].descuento/100))*arts[index].porcentajeIva/100);
            arts[index].subtotal = formatear3decimales(((arts[index].precio*arts[index].unidades)*(1-arts[index].descuento/100)));

        }else if(id == 'precio'){
            arts[index].precio = formatear3decimales(valor);
            arts[index].importeIva = formatear3decimales(((arts[index].precio*arts[index].unidades)*(1-arts[index].descuento/100))*arts[index].porcentajeIva/100);
            arts[index].subtotal = formatear3decimales(((arts[index].precio*arts[index].unidades)*(1-arts[index].descuento/100)));

        }else if(id == 'descuento'){
            arts[index].descuento = valor;
            arts[index].importeIva = formatear3decimales(((arts[index].precio*arts[index].unidades)*(1-arts[index].descuento/100))*arts[index].porcentajeIva/100);
            arts[index].subtotal = formatear3decimales(((arts[index].precio*arts[index].unidades)*(1-arts[index].descuento/100)));

        }else if(id == 'porcentajeIva'){
            arts[index].porcentajeIva = valor;
            arts[index].importeIva = formatear3decimales(((arts[index].precio*arts[index].unidades)*(1-arts[index].descuento/100))*arts[index].porcentajeIva/100);
            arts[index].subtotal = formatear3decimales(((arts[index].precio*arts[index].unidades)*(1-arts[index].descuento/100)));

        }

        setArticulosValues(arts);
    }

    function formatear3decimales(precio){
        if (typeof precio !== 'string') {
            precio = precio.toString(); // Convertir a cadena si no lo es
        }
    
        // Verificar si hay más de tres decimales y truncarlos si es necesario
        if (/^\d*\.\d{4,}$/.test(precio)) {
            const parts = precio.split('.');
            precio = `${parts[0]}.${parts[1].substring(0, 3)}`;
        }
    
      return precio;
    }

    function today(){
        var date = new Date();
        var split = date.toLocaleDateString();
        var split2 = split.split('/');
        
        var day = split2[0];
        var month = split2[1];
        var year = split2[2];
        if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
            day = '0'.concat(day);
        }
        if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
            month = '0'.concat(month);
        }
        return year.concat('-').concat(month).concat('-').concat(day);
    }

    function cambiarValorInput(id, valor){
        if(id == 'tipo'){
            setTipoValue(valor);
        }else if(id == 'numeroAlbaran'){
            setNumeroAlbaranValue(valor);
        }else if(id == 'clienteId'){
            setClienteIdValue(valor);
        }else if(id == 'clienteNombre'){
            setClienteNombreValue(valor);
        }else if(id == 'clienteCIF'){
            setClienteCIFValue(valor);
        }else if(id == 'fecha'){
            setFechaValue(valor);
        }else if(id == 'fechaEntrega'){
            setFechaEntregaValue(valor);
        }else if(id == 'observaciones'){
            setObservacionesValue(valor);
        }
    }

    function isClienteFilled(id){
        
        if(formularioCompleto){
            return ({ border: '1px solid lightgrey', borderRadius: '3px', width: '100%'});
        }else{
            if(id == 'clienteId'){
                if(clienteIdValue == ''){
                    return ({ border: '1px solid red', borderRadius: '3px', width: '100%'});
                }else{
                    return ({ border: '1px solid lightgrey', borderRadius: '3px', width: '100%'});
                }
            }else if(id == 'clienteNombre'){
                if(clienteNombreValue == ''){
                    return ({ border: '1px solid red', borderRadius: '3px', width: '100%'});
                }else{
                    return ({ border: '1px solid lightgrey', borderRadius: '3px', width: '100%'});
                }
            }if(id == 'clienteCIF'){
                if(clienteCIFValue == ''){
                    return ({ border: '1px solid red', borderRadius: '3px', width: '100%'});
                }else{
                    return ({ border: '1px solid lightgrey', borderRadius: '3px', width: '100%'});
                }
            }
            
        }
    }

    function modal(){
        return (<div class="modal fade" id='nuevoPedidoModal' tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div class="modal-content">
            <div class="modal-header">
                <h1 className='modal-main-title' id="exampleModalLabel">Nuevo pedido</h1>
                <button id='closePedidoModal' type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
                
            <div class="modal-body">
                <h6 className='modal-title'>Datos generales</h6>

                <div className='modal-row'>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Tipo</label>
                        <select className='mfselect w65' id="pedidoC-modal-field-tipo" value={tipoValue} onChange={(event) => cambiarValorInput('tipo', event.target.value)}>
                            <option value="PRESUPUESTADO">Presupuesto</option>
                            <option value="ALBARANADO">Albarán</option>
                        </select>                    
                    </div>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Nº albarán</label>
                        <input className='mfinput w65' id='pedidoC-modal-field-numeroAlbaran' value={numeroAlbaranValue} onChange={(event) => cambiarValorInput('numeroAlbaran', event.target.value)}></input>
                    </div>
                </div>

                <div className='separator'></div>

                <h6 className='modal-title'>Datos de cliente</h6>
                <div className='modal-row'>
                    <div className='modal-field w20'>
                        <label className='mflabel w45'>Código</label>
                        <input className='mfinput w55' style={isClienteFilled('clienteId')} value={clienteIdValue} onChange={(evento) => handleCliente('id', evento)}></input>
                    </div>
                    <div className='modal-field w60'>
                        <label className='mflabel w20'>Nombre</label>
                        <Autocomplete
                            style={{width:'80%', fontSize:'1.5vh'}}
                            freeSolo
                            key={autocompleteState}
                            value={clienteNombreValue}
                            onInputChange={(evento) => handleCliente('nombre', evento)} 
                            onChange={(event,value) => rellenarCliente('nombre', event, value)}                  
                            options={clientesBuscados.map((item) => "Código: ".concat(item.id).concat(" | Nombre: ").concat(item.nombreComercial).concat(" | CIF: ").concat(item.cif))}
                            renderInput={(params) => (
                                <div style={{width:'100%'}} ref={params.InputProps.ref}>
                                    <input style={isClienteFilled('clienteNombre')} type="text" {...params.inputProps}/>
                                </div>
                            )}
                        />
                    </div> 
                    <div className='modal-field w20'>
                        <label className='mflabel w35'>CIF</label>
                        <Autocomplete
                           style={{width:'65%', fontSize:'1.5vh'}}
                            freeSolo
                            key={autocompleteState}
                            value={clienteCIFValue}
                            onInputChange={(evento) => handleCliente('cif', evento)} 
                            onChange={(event,value) => rellenarCliente('cif', event, value)}                  
                            options={clientesBuscados.map((item) => "Código: ".concat(item.id).concat(" | Nombre: ").concat(item.nombreComercial).concat(" | CIF: ").concat(item.cif))}
                            renderInput={(params) => (
                                <div style={{width:'100%'}} ref={params.InputProps.ref}>
                                    <input style={isClienteFilled('clienteCIF')} type="text" {...params.inputProps}/>
                                </div>
                            )}
                        />
                    </div> 
                   
                </div>

                <div className='separator'></div>

                <h6 className='modal-title'>Datos de gestión</h6>
                <div className='modal-row'>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Fecha</label>
                        <input className='mfinput w65' value={fechaValue} type='date' id='pedidoC-modal-field-fecha' onChange={(event) => cambiarValorInput('fecha', event.target.value)}></input>
                    </div> 
                    <div className='modal-field w25'>
                        <label className='mflabel w50'>Fecha de entrega</label>
                        <input className='mfinput w50' value={fechaEntregaValue} type='date' id='pedidoC-modal-field-fechaEntrega' onChange={(event) => cambiarValorInput('fechaEntrega', event.target.value)}></input>
                    </div>
                </div>

                <div className='separator'></div>

                <h6 className='modal-title'>Otros</h6>
                <div className='modal-row'>
                    <label className='mflabel w8_75'>Observaciones</label>
                    <textarea className='mftextarea w91_75' value={observacionesValue} id='pedidoC-modal-field-observaciones'  onChange={(event) => cambiarValorInput('observaciones', event.target.value)}></textarea>   
                </div>

                <div className='separator'></div>
                    
                <h6 className='modal-title'>Artículos</h6>
                <div className='pedido-modal-articulos-table'>
                    <div className='pmaheader w100'>
                        <label className='pmahfield w10'>Código</label>
                        <label className='pmahfield w20'>Nombre</label>
                        <label className='pmahfield w10'>Unidades</label>
                        <label className='pmahfield w10'>Precio</label>
                        <label className='pmahfield w10'>Descuento</label>
                        <label className='pmahfield w10'>IVA %</label>
                        <label className='pmahfield w10'>Importe IVA</label>
                        <label className='pmahfield w10'>Subtotal</label>
                        <div className='acciones'>
                            <label>Acciones</label>
                        </div>
                    </div>
                    
                    {renderArticulos().map((item) => {
                        return item;
                    })}
                
                    <div className='modal-add w100'>
                        <button className='addRowButton' id="modalAddRow" onClick={()=>addModalRow()}><h3>+</h3></button>
                    </div>
                </div>
                
            </div>
            <div class="modal-footer">
                <p>Total: {calcularTotal()}€ </p>
                <button type="button" class="btn btn-primary modal-footer-button" onClick={() => nuevoAlbaran()}>Guardar pedido</button>
            </div>
            </div>
        </div>
        </div>);
    }

    function calcularTotal(){
        var total = 0.00;
        articulosValues.map((articulo) => {
            if(articulo.importeIva && articulo.subtotal){
                total = parseFloat(total) + parseFloat(articulo.importeIva) + parseFloat(articulo.subtotal);
            }
        });

        if(!isNaN(total)){
            return parseFloat(total).toFixed(2);
        }
        else{
            return '0.00';
        }
    }

    function validarNumero(valor) {
        
        if (isNaN(valor)) {
          return "";
        } else {
          return valor;
        }
    }

    function isArticuloFilled(id, index){
        if(!formularioCompleto){
            if(id == 'nombre'){
                if(articulosValues[index].nombre == ''){
                    return ("1px solid red");
                }else{
                    return ("1px solid ligthgrey");
                }
            }else if(id == 'unidades'){
                if(articulosValues[index].unidades == ''){
                    return ({border: 'solid 1px red'});
                }else{
                    return ({});
                }
            }else if(id == 'precio'){
                if(articulosValues[index].precio == ''){
                    return ({border: 'solid 1px red'});
                }else{
                    return ({});
                }
            }else if(id == 'porcentajeIva'){
                if(articulosValues[index].porcentajeIva == ''){
                    return ({border: 'solid 1px red'});
                }else{
                    return ({});
                }
            }
        }
        
        
    }

    function renderArticulos(){
        let articles = [];
        console.log(articulosValues);
        for(var i = 0; i < articulosValues.length; i++){
        let a = i;

            articles.push(
                <div key={'pedidoModalRow'.concat(a)} id={'pedidoModalRow'.concat(a)} className='pmarow w100'>
                    <input className='pmarfield w10' id={'pmarfieldId'.concat(a)} disabled value={validarNumero(articulosValues[a].idArticulo)}></input>
                    <Autocomplete
                            freeSolo
                            value={articulosValues[a].nombre}
                            onInputChange={(evento) => handleArticulo(evento)} 
                            onChange={(event, value) => rellenarArticulo('nombre', a, value)}
                            style={{fontSize:'1.5vh', width:'20%', margin: '0 0.2vw 0 0.2vw'}}
                            id={'pmarfieldNombre'.concat(a)}
                            options={articulosBuscados.map((item) => item.nombre)}
                            renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                    <input style={formularioCompleto || articulosValues[a].nombre != ''  ?{fontSize:'1.5vh',border: '1px solid lightgrey', borderRadius: '5px', width: '100%'}: {fontSize:'1.5vh',border: '1px solid red', borderRadius: '5px', width: '100%'}} type="text" {...params.inputProps}
                                />
                                </div>
                            )}
                        />                    
                    <input className='pmarfield w10' style={isArticuloFilled('unidades', a)} id={'pmarfieldUnidades'.concat(a)} value={articulosValues[a].unidades} onChange={(event)=>rellenarArticulo('unidades', a, event.target.value)}></input>
                    <input className='pmarfield w10' style={isArticuloFilled('precio', a)} id={'pmarfieldPrecio'.concat(a)} value={articulosValues[a].precio} onChange={(event)=>rellenarArticulo('precio', a, event.target.value)}></input>
                    <input className='pmarfield w10' id={'pmarfieldDescuento'.concat(a)} value={articulosValues[a].descuento} onChange={(event)=>rellenarArticulo('descuento', a, event.target.value)}></input>
                    <input className='pmarfield w10' style={isArticuloFilled('porcentajeIva', a)} id={'pmarfieldPorcentajeIva'.concat(a)} value={articulosValues[a].porcentajeIva} onChange={(event)=>rellenarArticulo('porcentajeIva', a, event.target.value)}></input>
                    <input className='pmarfield w10' disabled id={'pmarfieldImporteIva'.concat(a)} value={validarNumero(articulosValues[a].importeIva)}></input>
                    <input className='pmarfield w10' disabled id={'pmarfieldSubtotal'.concat(a)}  value={validarNumero(articulosValues[a].subtotal)}></input>
                    <div className='acciones'>
                        <i className="bi bi-trash3" title='Eliminar' onClick={() => removeModalRow(a)}></i>
                    </div>
                </div>)
        }
        return articles;
    }

    function addModalRow(){
        setFormularioCompleto(true);
        let b = [...articulosValues];
        
        b.push({
            id:'',
            idArticulo:'',
            nombre: '',
            unidades: '',
            precio: '',
            descuento: '',
            porcentajeIva:'',
            importeIva:'',
            subtotal: ''
        });
        setArticulosValues(b);

    }

    function removeModalRow(index){

        var arts = [...articulosValues];

        arts.splice(index, 1);

        setArticulosValues(arts);
    }

    function nuevoAlbaran(){
        var form = true;
        
        if(clienteIdValue == '' || clienteNombreValue == '' || clienteCIFValue == ''){
            setFormularioCompleto(false);
            form = false;
        }

        articulosValues.map((item) => {
            if(item.nombre == '' || item.precio == '' || item.porcentajeIva == '' || item.unidades == ''){
                setFormularioCompleto(false);
                form = false;
            }
        });
        if(form){

        var articulosToSave = [];
        
        articulosValues.map((item, index) => {
            var articuloToSave ={};

            articuloToSave['idArticulo'] = articulosValues[index].idArticulo;
            articuloToSave['nombre'] = articulosValues[index].nombre;
            articuloToSave['precio'] = articulosValues[index].precio;
            articuloToSave['cantidad'] = articulosValues[index].unidades;
            articuloToSave['descuento'] = articulosValues[index].descuento;
            articuloToSave['porcentajeIva'] = articulosValues[index].porcentajeIva;
            articuloToSave['importeIva'] = articulosValues[index].importeIva;
            articuloToSave['subtotal'] = articulosValues[index].subtotal;

            articulosToSave.push(articuloToSave);
        });
        const albaran = new Albaran(
            "",
            numeroAlbaranValue,
            cliente,
            fechaValue,
            fechaEntregaValue,
            tipoValue,
            observacionesValue,
            articulosToSave,
            data.username
        );

        saveNuevoAlbaran(albaran);
        document.getElementById('closePedidoModal').click();
        }
    }


    return (modal());
};


ModalAlbaranComponent.propTypes = {

};


export default ModalAlbaranComponent;
