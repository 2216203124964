import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ProveedorComponent from '../pure/proveedorComponent';
import { Proveedor } from '../../models/proveedor.class';


const ModalProveedorComponent = ({saveNuevoproveedor}) => {

    function nuevoProveedor(){
        
        const proveedor = new Proveedor(
            "",
            document.getElementById("proveedor-modal-field-nombre").value,
            document.getElementById("proveedor-modal-field-nombreComercial").value,
            document.getElementById("proveedor-modal-field-cif").value,
            document.getElementById("proveedor-modal-field-direccion").value,
            document.getElementById("proveedor-modal-field-cp").value,
            document.getElementById("proveedor-modal-field-poblacion").value,
            document.getElementById("proveedor-modal-field-provincia").value,
            document.getElementById("proveedor-modal-field-tributacion").value,
            document.getElementById("proveedor-modal-field-tipoDePago").value,
            document.getElementById("proveedor-modal-field-movil").value,
            document.getElementById("proveedor-modal-field-telefono").value,
            document.getElementById("proveedor-modal-field-email").value,
            document.getElementById("proveedor-modal-field-horasApertura").value
                .concat(':')
                .concat(document.getElementById("proveedor-modal-field-minutosApertura").value)
                .concat('-')
                .concat(document.getElementById("proveedor-modal-field-horasCierre").value)
                .concat(':')
                .concat(document.getElementById("proveedor-modal-field-minutosCierre").value),
            document.getElementById("proveedor-modal-field-contactoComercial").value,
            document.getElementById("proveedor-modal-field-numeroComercial").value,
            document.getElementById("proveedor-modal-field-observaciones").value
            );

        saveNuevoproveedor(proveedor);
        limpiarModal();
        document.getElementById('closeProveedorModal').click();
    }

    function modal(){
        return (<div class="modal fade" id='nuevoProveedorModal' tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-main-title" id="exampleModalLabel">Nuevo proveedor</h1>
                <button id='closeProveedorModal' type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
                
            <div class="modal-body">
                <h6 className='modal-title'>Datos generales</h6>
                <div className='modal-row'>
                    <div className='modal-field w50'>
                        <label className='mflabel w17_5'>Nombre</label>
                        <input className='mfinput w82_5' name='nombre' id='proveedor-modal-field-nombre'></input>
                    </div>
                    <div className='modal-field w50'>
                        <label className='mflabel w30'>Nombre comercial</label>
                        <input className='mfinput w70' name='nombreComercial' id='proveedor-modal-field-nombreComercial'></input>
                    </div>
                </div>
                <div className='modal-row'>
                    <div className='modal-field w25'>
                        <label className='cflabel w35'>CIF</label>
                        <input className='cfinput w65' name='cif' id='proveedor-modal-field-cif'></input>
                    </div>
                </div>

                <div className='separator'></div>

                <h6 className='modal-title'>Datos económicos</h6>
                <div className='modal-row'>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Tributación</label>
                        <select className='mfselect w65' name='tributacion' id="proveedor-modal-field-tributacion">
                            <option value="NACIONAL" selected="selected">Nacional</option>
                            <option value="CYM">Ceuta y Melilla</option>
                            <option value="CANARIAS">Canarias</option>
                            <option value="UE">Unión Europea</option>
                            <option value="EXTRACOMUNITARIO">Extracomunitario</option>
                        </select>                     
                    </div>
                    <div className='modal-field w25'>
                        <label className='mflabel w40'>Tipo de pago</label>
                        <select className='mfselect w60' name='tipoDePago' id="proveedor-modal-field-tipoDePago">
                            <option value="CONTADO" selected="selected">Contado</option>
                            <option value="TARJETA">Tarjeta</option>
                            <option value="TRANSFERENCIA">Transferencia</option>
                            <option value="D10">Pago a 10 días</option>
                            <option value="D30">Pago a 30 días</option>
                            <option value="D45">Pago a 45 días</option>
                            <option value="D60">Pago a 60 días</option>
                        </select>                     
                    </div>
                </div>

                <div className='separator'></div>

                <h6 className='modal-title'>Localización</h6>
                <div className='modal-row'>
                    <div className='modal-field w50'>
                        <label className='mflabel w17_5'>Dirección</label>
                        <input className='mfinput w82_5' name='direccion' id='proveedor-modal-field-direccion'></input>
                    </div>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Provincia</label>
                        <input className='mfinput w65' name='provincia'  id='proveedor-modal-field-provincia'></input>
                    </div> 
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Población</label>
                        <input className='mfinput w65' name='poblacion'  id='proveedor-modal-field-poblacion'></input>
                    </div>
                </div>
                <div className='modal-row'>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Código postal</label>
                        <input className='mfinput w65' name='cp'  id='proveedor-modal-field-cp'></input>
                    </div>
                </div>

                <div className='separator'></div>

                <h6 className='modal-title'>Contacto</h6>
                <div className='modal-row'>
                    <div className='modal-field w50'>
                        <label className='mflabel w17_5'>Email</label>
                        <input className='mfinput w82_5' name='email' id='proveedor-modal-field-email'></input>
                    </div>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Teléfono</label>
                        <input className='mfinput w65' name='telefono' id='proveedor-modal-field-telefono'></input>
                    </div>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Móvil</label>
                        <input className='mfinput w65' name='movil' id='proveedor-modal-field-movil'></input>
                    </div>
                </div>
                <div className='modal-row'>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Comercial</label>
                        <input className='mfinput w65' name='email' id='proveedor-modal-field-contactoComercial'></input>
                    </div>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'><i class="bi bi-telephone" style={{margin:'0 2px 0 2px'}}></i>Comercial</label>
                        <input className='mfinput w65' name='telefono' id='proveedor-modal-field-numeroComercial'></input>
                    </div>
                    <div className='modal-field w25'>
                        <label className='mflabel w35'>Horario</label>
                        <input className='mfinput center w10' name='horario' id='proveedor-modal-field-horasApertura'></input>
                        <label className='mflschedule w3'>:</label>
                        <input className='mfinput center w10' name='horario' id='proveedor-modal-field-minutosApertura'></input>
                        <label className='mflschedule w5'>-</label>
                        <input className='mfinput center w10' name='horario' id='proveedor-modal-field-horasCierre'></input>
                        <label className='mflschedule w3'>:</label>
                        <input className='mfinput center w10' name='horario' id='proveedor-modal-field-minutosCierre'></input>
                    </div>
                </div>

                <div className='separator'></div>

                <h6 className='modal-title'>Otros</h6>
                <div className='modal-row'>
                    <label className='mflabel w8_75'>Observaciones</label>
                    <textarea className='mftextarea w91_75' name='observaciones' id='proveedor-modal-field-observaciones'></textarea>   
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" className="btn btn-primary modal-footer-button" data-bs-toggle="modal" data-bs-target="#nuevoProveedorModal" onClick={() => nuevoProveedor()}>Guardar proveedor</button>
            </div>
            </div>
        </div>
        </div>);
    }

    function limpiarModal(){

        document.getElementById("proveedor-modal-field-nombre").value="";
            document.getElementById("proveedor-modal-field-nombreComercial").value="";
            document.getElementById("proveedor-modal-field-cif").value="";
            document.getElementById("proveedor-modal-field-direccion").value="";
            document.getElementById("proveedor-modal-field-cp").value="";
            document.getElementById("proveedor-modal-field-poblacion").value="";
            document.getElementById("proveedor-modal-field-provincia").value="";
            document.getElementById("proveedor-modal-field-tributacion").value="";
            document.getElementById("proveedor-modal-field-tipoDePago").value="";
            document.getElementById("proveedor-modal-field-movil").value="";
            document.getElementById("proveedor-modal-field-telefono").value="";
            document.getElementById("proveedor-modal-field-email").value="";
            document.getElementById("proveedor-modal-field-observaciones").value="";

    }

    return (modal());
};


ModalProveedorComponent.propTypes = {

};


export default ModalProveedorComponent;