import React, { useEffect, useState } from 'react';
import logo from '../../img/logo.png';
import { getUser } from '../../services/fecthService';

import PropTypes from 'prop-types';

const HeaderComponent = ({data, actualizarMenu, toggleSidebar}) => {
    const [usuario, setUsuario] = useState({});
         // eslint-disable-next-line no-restricted-globals
    const [screenWidth, setScreenWidth] = useState(screen.width);
    
    useEffect(() => {
      const actualizarDimensiones = () => {
             // eslint-disable-next-line no-restricted-globals
             setScreenWidth(screen.width);
        showName();
      };
      
      window.addEventListener('resize', actualizarDimensiones);
  
      return () => {
        window.removeEventListener('resize', actualizarDimensiones);
      };
    }, []); 
  
    useEffect(() => {
      
    }, []); 
  
    function showName() {
      getUser(data.token, data.username)
        .then((response) => {
          setUsuario(response);
        })
        .catch(() => {
          alert("Error de autenticación");
        });
    }
  
    function showProfile() {
      actualizarMenu(8);
    }
  
    function renderHeader() {
      if (screenWidth < 500) {
        return ( 
          <>
            <div className='col-3'></div>
            <div className='col-6 logo'>
              <img src={logo} alt=''></img>
            </div>
            <div className='col-1'></div>
            <button className=' col-2 sidebar-movil-button' onClick={toggleSidebar}>☰</button>
          </>
        );
      } else if (screenWidth > 1300) {
        return (
          <>
            <div className='col-6 logo'>
              <img src={logo} alt=''></img>
            </div>
            <div className='col-6 perfil'>
              <label className={data.active == 8 ? 'hover perfilSelected' : 'hover'} onClick={() => showProfile()}><i class="bi bi-person-circle mr10"></i> <strong>{usuario.name}</strong></label>
            </div>      
          </>
        );  
      }    
    }
  
    return (
    <div className="sticky">
        <div className='header'>
            {renderHeader()}
        </div>
    </div>
    );
  };
  
  export default HeaderComponent;
