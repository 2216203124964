import React, { useState } from 'react';
import PropTypes from 'prop-types';

const SidebarComponent = ({actualizarMenu}) => {

    const [active, setActive] = useState(0);

    function actualizarMenuSidebar(id){
        actualizarMenu(id);
    }

        return (
            <div className='sidebar'>
                <ul className='sidebar-list'>
                    
                    <li className={active === 0 ? 'sidebar-element-active': 'sidebar-element'} onClick={() => {
                            setActive(0); 
                            actualizarMenuSidebar(0);
                            } 
                        }>
                        <span className = {active === 0 ? 'sidebar-element-bullet-active': 'sidebar-element-bullet'}></span>
                        <i className='bi bi-house sidebar-element-icon'></i>
                        <span className='sidebar-element-title'>Inicio</span>
                    </li>

                    <li className={active === 1 ? 'sidebar-element-active': 'sidebar-element'} onClick={() => {
                            setActive(1); 
                            actualizarMenuSidebar(1);
                            } 
                        }>
                    <span className = {active === 1 ? 'sidebar-element-bullet-active': 'sidebar-element-bullet'}></span>
                        <i className='bi bi-file-earmark-text sidebar-element-icon'></i>
                        <span className='sidebar-element-title'>Pedidos Clientes</span>
                    </li>
                    
                    

                    <li className={active === 2 ? 'sidebar-element-active': 'sidebar-element'} onClick={() => {
                            setActive(2); 
                            actualizarMenuSidebar(2);
                            } 
                        }>
                        <span className = {active === 2 ? 'sidebar-element-bullet-active': 'sidebar-element-bullet'}></span>
                        <i class="bi bi-people sidebar-element-icon"></i>
                        <span className='sidebar-element-title'>Clientes</span>
                    </li>

                    <li className={active === 3 ? 'sidebar-element-active': 'sidebar-element'} onClick={() => {
                            setActive(3); 
                            actualizarMenuSidebar(3);
                            } 
                        }>
                        <span className = {active === 3 ? 'sidebar-element-bullet-active': 'sidebar-element-bullet'}></span>
                        <i className='bi bi-diagram-3 sidebar-element-icon'></i>
                        <span className='sidebar-element-title'>Artículos</span>
                    </li>

                    <div className='separator-sidebar'></div>
                    <li className={active === 4 ? 'sidebar-element-active': 'sidebar-element'} onClick={() => {
                            setActive(4); 
                            actualizarMenuSidebar(4);
                            } 
                        }>
                    <span className = {active === 4 ? 'sidebar-element-bullet-active': 'sidebar-element-bullet'}></span>
                        <i class="bi bi-file-earmark-person  sidebar-element-icon"></i>
                        <p className='sidebar-element-title'>Pedidos Proveedores</p>
                    </li>

                    <li className={active === 5 ? 'sidebar-element-active': 'sidebar-element'} onClick={() => {
                            setActive(5); 
                            actualizarMenuSidebar(5);
                            } 
                        }>
                        <span className = {active === 5 ? 'sidebar-element-bullet-active': 'sidebar-element-bullet'}></span>
                        <i className='bi bi-person-vcard sidebar-element-icon'></i>
                        <span className='sidebar-element-title'>Proveedores</span>
                    </li>
                    <div className='separator-sidebar'></div>

                    <li className={active === 6 ? 'sidebar-element-active': 'sidebar-element'} onClick={() => {
                            setActive(6); 
                            actualizarMenuSidebar(6);
                            } 
                        }>
                        <span className = {active === 6 ? 'sidebar-element-bullet-active': 'sidebar-element-bullet'}></span>
                        <i class="bi bi-file-earmark-bar-graph  sidebar-element-icon"></i>
                        <span className='sidebar-element-title'>Informes</span>
                    </li>

                    <li className={active === 7 ? 'sidebar-element-active': 'sidebar-element'} onClick={() => {
                            setActive(7); 
                            actualizarMenuSidebar(7);
                            } 
                        }>
                        <span className = {active === 7 ? 'sidebar-element-bullet-active': 'sidebar-element-bullet'}></span>
                        <i class="bi bi-person-circle  sidebar-element-icon"></i>                        
                        <span className='sidebar-element-title'>Usuarios</span>
                    </li>

                </ul>
                <ul className='sidebar-list-down'>
                <div className='separator-sidebar'></div>

                    <li className={active === 9 ? 'sidebar-element-active mtauto': 'sidebar-element mtauto'} onClick={() => {
                            setActive(9); 
                            actualizarMenuSidebar(9);
                            } 
                        }>
                        <span className = {active === 9 ? 'sidebar-element-bullet-active': 'sidebar-element-bullet'}></span>
                        <i class="bi bi-list-columns  sidebar-element-icon"></i>                        
                        <span className='sidebar-element-title'>Registro de eventos</span>
                    </li>
                    <div className='separator-sidebar'></div>

                </ul>
            </div>
        );
}
SidebarComponent.propTypes = {

};
export default SidebarComponent;
