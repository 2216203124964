import React, { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { AlbaranProveedor } from '../../models/albaranProveedor.class';
import { FacturaProveedor } from '../../models/facturaProveedor.class';
import { Proveedor } from '../../models/proveedor.class';
import { Articulo } from '../../models/articulo.class';
import AlbaranProveedorComponent from '../pure/albaranProveedorComponent';
import FacturaProveedorComponent from '../pure/facturaProveedorComponent';
import ModalAlbaranProveedorComponent from './modalAlbaranProveedorComponent';
import { createAlbaranP, createFacturaP, getAllAlbaranesP, getAllFacturasP, getAllProveedores, getAllArticulos, updateAlbaranP, updateFacturaP, getAlbaranP, getArticulo, updateArticulo, subirFacturaProveedor, descargarFacturaProveedor, deleteAlbaranP, findAlbaranesP, findFacturasP} from '../../services/fecthService';
import  ModalCambioPrecioProveedorComponent from './modalCambioPrecioProveedorComponent';
import ModalDatosAlbaranProveedorComponent from './modalDatosAlbaranProveedorComponent';
import ModalDatosFacturaProveedorComponent from './modalDatosFacturaProveedorComponent';

export const albaranProveedorContext = React.createContext();
export const facturaProveedorContext = React.createContext();
export const preciosContext = React.createContext();


const PedidosCComponent = ({data, toasting, registrarEvento}) => {

    const [numberListItemsSelected, setNumberListItemsSelected] = useState(0);
    const [listItemsSelected, setListItemsSelected] = useState([]);

    const [filterButtonActive, setFilterButtonActive] = useState(0);
    
    const [albaranes, setAlbaranes] = useState([]);
    const [facturas, setFacturas] = useState([]);
    const [proveedores, setProveedores] = useState([]);
    const [articulos, setArticulos] = useState([]);

    const [datosAlbaranProveedor, setDatosAlbaranProveedor] = useState('');
    const [datosFacturaProveedor, setDatosFacturaProveedor] = useState('');

    const [precios, setPrecios] = useState('');

    useEffect(() => {
        obtainAlbaranes();
        obtainFacturas();
        obtainProveedores();
        obtainArticulos();
    },[]);

    const obtainAlbaranes = () => {
        getAllAlbaranesP(data.token)
        .then((response) => {
            let albs = [];

            response.map((item) => {
               
                var albaran = new AlbaranProveedor(
                    item.id,
                    item.numeroAlbaran,
                    item.proveedor,
                    item.fecha,
                    item.fechaEntrega,
                    item.estado,
                    item.observaciones,
                    item.articulos
                );
                
                albs.push(albaran);                
            });
            setAlbaranes(albs);
        }) 
        .catch(()=>{
            toasting('Error al cargar los albaranes', 'error');
        })
        .finally(() => {
        });
    }

    function obtainAlbaran(id){
        getAlbaranP(data.token, id)
        .then((response) => {
            return response;
        }) 
        .catch(()=>{
        })
        .finally(() => {
            obtainAlbaranes();
        });
    }

    const obtainFacturas = () => {
        getAllFacturasP(data.token)
        .then((response) => {
            let facts = [];
            response.map((item) => {
               
                var factura = new FacturaProveedor(
                    item.id,
                    item.codigoFactura,
                    item.proveedor,
                    item.fecha,
                    item.fechaContable,
                    item.estado,
                    item.observaciones,
                    item.totalSinIva,
                    item.totalConIva,
                    item.document,
                    item.albaranes
                );
                
                facts.push(factura);
            });
            setFacturas(facts);
        }) 
        .catch(()=>{
            toasting('Error al cargar las facturas', 'error');
        })
        .finally(() => {
        });
    }

    const obtainProveedores = () => {
        
        if(proveedores.length == 0){
            getAllProveedores(data.token)
            .then((response) => {
                let arts = [];
                response.map((item) => {
                    var proveedor = new Proveedor(
                        item.id,
                        item.nombre,
                        item.nombreComercial,
                        item.cif,
                        item.direccion,
                        item.cp,
                        item.poblacion,
                        item.provincia,
                        item.tributacion,
                        item.tipoDePago,
                        item.movil,
                        item.telefono,
                        item.email,
                        item.observaciones
                    );
                    arts.push(proveedor);
                    
                });
                setProveedores(arts);
            }) 
            .catch(()=>{
                toasting('Error al cargar los proveedores', 'error');
            })
            .finally(() => {
                
            });
        }
        
    }

    const obtainArticulos = () => {
        getAllArticulos(data.token)
        .then((response) => {
            let arts = [];
            let obs = "";
            response.map((item) => {
                if(item.observaciones != null) obs = item.observaciones;
                var articulo = new Articulo(
                    item.id,
                    item.nombre,
                    item.familia,
                    item.subfamilia,
                    item.descripcion,
                    item.stockMin,
                    item.stockMax,
                    item.unidades,
                    obs,
                    item.precio,
                    item.iva,
                    item.publicable,
                    item.rotura,
                    item.descatalogado,
                    item.portada,
                    item.masVendido,
                    item.novedades,
                    item.codigoEAN13
                );
                arts.push(articulo);
                obs = "";
                
            });
            setArticulos(arts);
            
        }) 
        .catch(()=>{
            toasting('Error al cargar los artículos', 'error');
        })
        .finally(() => {
        });
    }


    function setTitle(){
        let i = filterButtonActive;

        if(i === 0){
            return "Albaranes";
        }else if(i === 1){
            return "Facturas";
        }
    }

    function sayDatosAlbaranProveedorModal(albaran){
        setDatosAlbaranProveedor(albaran);
    }
    function sayDatosFacturaProveedorModal(factura){
        setDatosFacturaProveedor(factura);
    }

    function mostrarPedidos(){
        if(albaranes.length > 0){
            if(filterButtonActive === 0){
                var albs = [];
                
                albaranes.map((item,index) => {
                    albs.push(
                        <AlbaranProveedorComponent key = {'albaran'.concat(item.id)} albaran={item} proveedores={proveedores} articulos={articulos} active={filterButtonActive} toasting={toasting} modificarAlbaran={modificarAlbaran} obtainAlbaran={obtainAlbaran} checkboxChanged={checkboxChanged} facturarUno = {facturarUno} borrarAlbaran={borrarAlbaran} data={data} sayDatosAlbaranProveedorModal={sayDatosAlbaranProveedorModal}  setDatosAlbaran={setDatosAlbaranProveedor}></AlbaranProveedorComponent>
                    );
                });

                return albs;

            }else if(filterButtonActive === 1){
                var facts = [];

                facturas.map((item,index) => {
                    facts.push(
                        <FacturaProveedorComponent key = {'factura'.concat(item.id)} factura={item} active={filterButtonActive} articulos={articulos} modificarFactura={modificarFactura} pagar = {pagar} subirFactura={subirFactura} descargarFactura={descargarFactura} sayDatosFacturaProveedorModal={sayDatosFacturaProveedorModal}/*checkboxChanged={checkboxChanged} modificarAlbaran={modificarAlbaran} albaranarUno = {albaranarUno} facturarUno = {facturarUno} */></FacturaProveedorComponent>
                    );
                });
                return facts;
            }
        }else{
            return (
                <div class="d-flex justify-content-center">
                                    <div class="spinner-border  spinner-border-sm  text-warning m-4" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div>
            );
        }
    }

    function gestionarStockAlbaran(albaran, operacion){
        if(operacion == '+'){
            albaran.articulos.map((articulo) => {
                if(articulo.inventariable){
                    
                    getArticulo(data.token, articulo.idArticulo)
                        .then((response) => {
                            updateArticulo(data.token,  articulo.idArticulo, 'unidades', parseInt(response.unidades) + parseInt(articulo.cantidad))
                                .then((response) => {
                                    toasting('Artículo actualizado con éxito', 'success');
                                }) 
                                .catch((error)=>{
                                    toasting('Error al actualizar artículo', 'error');
                                })
                                .finally(() => {
                                    obtainArticulos();
                                });
                    }) 
                    .catch(()=>{
            
                    })
                    .finally(() => {
                    });
                }
            });
        }
    }

    function checkPrices(a){
        var p = [];
        a.map((articulo, index) => {
            getArticulo(data.token, articulo.idArticulo)
            .then((response) => {
                if(formatear3decimales(response.precioProveedor) != articulo.precio){
                    console.log('precios distintos');
                    p.push({
                        'id':articulo.idArticulo,
                        'nombre': articulo.nombre,
                        'precioActualP': formatear3decimales(response.precioProveedor),
                        'precioNuevoP': articulo.precio,
                        'precioActualS': response.precio
                    });
                }
            }) 
            .catch((error)=>{
                toasting('Error al actualizar albarán', 'error');
            })
            .finally(() => {
            });
        });
        setPrecios(p);

    }
    function saveNuevoAlbaran(albaran){
        
        var cond = false;
        createAlbaranP(data.token,albaran)
        .then((response) => {
            toasting('Albarán creado con éxito', 'success');
            gestionarStockAlbaran(albaran, '+');
            registrarEvento('Albarán de proveedor'.concat(' con ID ').concat(response.id).concat(' creado.'));
            checkPrices(albaran.articulos);
        }) 
        .catch(()=>{
            toasting('Error al crear el albarán', 'error');
        })
        .finally(() => {
            obtainAlbaranes();
        });
    }

    function formatear3decimales(precio){
        if (typeof precio !== 'string') {
            precio = precio.toString(); // Convertir a cadena si no lo es
        }
    
        // Verificar si hay más de tres decimales y truncarlos si es necesario
        if (/^\d*\.\d{4,}$/.test(precio)) {
            const parts = precio.split('.');
            precio = `${parts[0]}.${parts[1].substring(0, 3)}`;
        }
    
      return precio;
    }
    function modificarAlbaran(id, albaran){

        updateAlbaranP(data.token, id, albaran)
        .then((response) => {
            obtainAlbaranes();
            toasting('Albarán actualizado con éxito', 'success');

            /*if(key != 'cliente'){
                registrarEvento('Albarán con ID '.concat(id).concat(' modificado: '.concat(key).concat(' -> ').concat(value)));
            }else{
                registrarEvento('Albarán con ID '.concat(id).concat(' modificado: '.concat(key).concat(' -> ').concat(value.nombreComercial)));
            }*/
            checkPrices(albaran.articulos);
        }) 
        .catch((error)=>{
            toasting('Error al actualizar albarán', 'error');
        })
        .finally(() => {
            obtainAlbaranes();
        });    
    }


    function borrarAlbaran(albaran){
        deleteAlbaranP(data.token, albaran.id)
        .then((response) => {
            toasting('Albarán eliminado con éxito', 'success');
            gestionarStockAlbaran(albaran, '+');
            registrarEvento('Albarán de proveedor'.concat(' con ID ').concat(albaran.id).concat(' eliminado.'));

        }) 
        .catch(()=>{
            toasting('Error al eliminar el albarán', 'error');

        })
        .finally(() => {
            obtainAlbaranes();
        });
    }

    function modificarFactura(id, factura){

        updateFacturaP(data.token, id, factura)
        .then((response) => {
            
            toasting('Factura actualizada con éxito', 'success');
            /*registrarEvento('Factura de proveedor con ID '.concat(id).concat(' modificada: '.concat(key).concat('->').concat(value)));*/
        }) 
        .catch((error)=>{
            toasting('Error al actualizar factura', 'error');
        })
        .finally(() => {
            obtainAlbaranes();
            obtainFacturas();
        });
    }


    function buscar(){

        let textoBuscado = document.getElementById("buscador").value;
        
        if(textoBuscado != ''){

        let split = textoBuscado.trim().split(' ');
        
        
        if(filterButtonActive == 0){
            findAlbaranesP(data.token, split)
            .then((response) => {
                setAlbaranes(response.reverse());
            }) 
            .catch(()=>{
                toasting('Error de búsqueda', 'error');

            })
            .finally(() => {
            });

        }else{
            findFacturasP(data.token, split)
            .then((response) => {
                setFacturas(response.reverse());
            }) 
            .catch(()=>{
                toasting('Error de búsqueda', 'error');

            })
            .finally(() => {
            });
        }
        }else{
            obtainAlbaranes();
            obtainFacturas();
        }

    }

    function saveNuevaFactura(factura){
        createFacturaP(data.token,factura)
        .then((response) => {
            toasting('Factura creada con éxito', 'success');
            registrarEvento('Factura de proveedor con ID '.concat(response.id).concat(' creado.'));
            return true;

        }) 
        .catch(()=>{
            toasting('Error al crear la factura', 'error');
            return false;
        })
        .finally(() => {
            obtainFacturas();
        });
    }

    function checkboxChanged(event, id){
        let number = numberListItemsSelected;
        
        var itemSelected = [...listItemsSelected];
        if(event.target.checked){
            number = number + 1;
            itemSelected.push(id);
        }
        else {
            itemSelected = itemSelected.filter((item) => item != id);
            number = number - 1;
        }

        updateSelected(number, itemSelected);
    }

    function updateSelected (number, itemSelected){
        setListItemsSelected(itemSelected);
        setNumberListItemsSelected(number);
    }

    function facturar(){
        var totalSinIva = 0;
        var totalConIva = 0;
        let albs = [];
        let proveedor = albaranes.find(x => x.id == listItemsSelected[0]).proveedor.id;
        var same = true;

        listItemsSelected.map((item) => {
            let alb = albaranes.find(x => x.id == item);
            if(alb.proveedor.id != proveedor){
                same = false;
            }
            albs.push(alb);
            alb.articulos.map((articulo) => {
                totalSinIva = totalSinIva + (articulo.cantidad * articulo.precio);
                totalConIva = totalConIva + ((articulo.cantidad * articulo.precio) * (1+(articulo.porcentajeIva/100)))
            });
        });
        if(same){
            var date = new Date().toISOString();        

            var factura = new FacturaProveedor(
                '',
                '1',
                albs[0].proveedor,
                '',
                '',
                'PENDIENTE',
                '',
                totalSinIva,
                totalConIva,
                'NO',
                albs
            );
    
            saveNuevaFactura(factura);
            listItemsSelected.map((item) => {
                modificarAlbaran(item, {'estado': 'FACTURADO'});
            });
            setListItemsSelected([]);
            setNumberListItemsSelected(0);
        }else{
            toasting('Facturación con distintos proveedores', 'error');
        }
        
    }

    function facturarUno(albaran){
         
        var totalSinIva = 0;
        var totalConIva = 0;

        albaran.articulos.map((articulo) => {
            totalSinIva = totalSinIva + (articulo.cantidad * articulo.precio);
            totalConIva = totalConIva + ((articulo.cantidad * articulo.precio) * (1+(articulo.porcentajeIva/100)));
            
        });
        var albs = [];
        albs.push(albaran); 

        let factura = new FacturaProveedor(
            '',
            '1',
            albaran.proveedor,
            '',
            '',
            'PENDIENTE',
            '',
            totalSinIva,
            totalConIva,
            'NO',
            albs
        );

        saveNuevaFactura(factura);
        modificarAlbaran(albaran.id, {'estado': 'FACTURADO'});  

    }

    function pagar(id){
        modificarFactura(id, {'estado': 'PAGADA'});
    }

    function actualizarPrecios(cambiosPrecios){
        cambiosPrecios.map((cambio) => {
            if(cambio.cambiar == true){
                updateArticulo(data.token, cambio.id, 'precioProveedor', cambio.precioNuevoP)
                .then((response) => {
                    
                        updateArticulo(data.token, cambio.id, 'precio', cambio.precioNuevoS)
                        .then((response) => {
                            toasting('Precios actualizados con éxito', 'success');
                        }) 
                        .catch(()=>{
                            toasting('Error al actualizar precios', 'error');
                        })
                        .finally(() => {
                            obtainArticulos();
                        });
                    
                }) 
                .catch(()=>{
                    toasting('Error al actualizar precios', 'error');
                })
                .finally(() => {
                    obtainArticulos();
                });

            }
        });
        
        document.getElementById('closeModalCambioPrecios').click();
    }

    function subirFactura(id, file){
        const formData = new FormData();
        formData.append('file', file);
        formData.append('codigo', id);
            subirFacturaProveedor(data.token, id, formData)
            .then((response) => {
                toasting('Documento subido con éxito', 'success');
            }) 
            .catch(()=>{
                toasting('Error al subir el documento', 'error');
            })
            .finally(() => {
                obtainFacturas();
            });
    }

    function clearPrecios(){
        setPrecios('');
    }

    function descargarFactura(id, codigo){
        descargarFacturaProveedor(data.token, id)
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((href) => {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = href;
        a.download = "Factura proveedor ".concat(codigo);
        a.click();
        })
        .catch((error)=>{
            toasting('Error al generar la factura', 'error');
        })
        .finally(() => {
            obtainFacturas();
        });
    }

    function clearAlbaran(){
        setDatosAlbaranProveedor('');
    }

    function clearFactura(){
        setDatosFacturaProveedor('');
    }

    return (
        <div>
            <h2 className='page-title'>Pedidos a proveedores - {setTitle()}</h2>
            <div className='upper-buttons'>
            <button type="button" id='cambioPrecioButton' class="btn btn-primary upper-button" style={{visibility:'hidden'}} data-bs-toggle="modal" data-bs-target="#cambioPrecioModal"></button>

                {(numberListItemsSelected > 0 && filterButtonActive === 0) ? <button type="button" className="btn btn-primary upper-button w15 bold" onClick={() => facturar()}>Facturar</button>: '' }
                <button type="button" id='nuevoPedidoProveedorButton' className="btn btn-primary upper-button w20 bold" data-bs-toggle="modal" data-bs-target="#nuevoPedidoProveedorModal">Nuevo pedido</button>
            </div>

            <ModalAlbaranProveedorComponent saveNuevoAlbaran={saveNuevoAlbaran} data={data}></ModalAlbaranProveedorComponent>
            
            <preciosContext.Provider value={precios}>
                <ModalCambioPrecioProveedorComponent actualizarPrecios={actualizarPrecios} clearPrecios={clearPrecios}></ModalCambioPrecioProveedorComponent>
            </preciosContext.Provider>
            <albaranProveedorContext.Provider value={datosAlbaranProveedor}>
                <ModalDatosAlbaranProveedorComponent data={data} actualizarAlbaran={modificarAlbaran} clearAlbaran={clearAlbaran}></ModalDatosAlbaranProveedorComponent>
            </albaranProveedorContext.Provider>
            <facturaProveedorContext.Provider value={datosFacturaProveedor}>
                <ModalDatosFacturaProveedorComponent data={data} actualizarFactura={modificarFactura} clearFactura={clearFactura}></ModalDatosFacturaProveedorComponent>
            </facturaProveedorContext.Provider>
            <div className='table-container'>
                        <div className='toolbar-listado'>
                            <div className='filtros-rapidos'>
                                <span className={filterButtonActive === 0 ? 'filtro-rapido filtro-activado': 'filtro-rapido'} onClick={() => {/*resetAllCheckboxes();*/ document.getElementById('buscador').value=''; setFilterButtonActive(0);}}>Albaranes</span>
                                <span className={filterButtonActive === 1 ? 'filtro-rapido filtro-activado': 'filtro-rapido'} onClick={() => {/*resetAllCheckboxes();*/ document.getElementById('buscador').value='';setFilterButtonActive(1);}}>Facturas</span>
                            </div>
                            <div className='buscador'>
                                <input className='form-control me-2' id="buscador" type='search' placeholder='Buscar...' aria-label='Search' onChange={()=>buscar()}></input>
                            </div>
                        </div>
                        <table className='content-table'>
                            <thead className='listado-header'>
                                <th>
                                    <div className="form-check">
                                        <input className="form-check-input" style = {{opacity: '0%'}}type="checkbox" value="" id="flexCheckDefaultAll" /*onChange={(event) => selectAllCheckboxes(event)}*/></input>
                                    </div>
                                </th>
                                <th className='listado-header-field w7_5'>
                                    {filterButtonActive == 0 
                                        ? 'Nº albarán'
                                        : 'Nº factura'
                                    }
                                </th>
                                <th className='listado-header-field w7_5'>Cod. prov.</th>
                                <th className='listado-header-field w40'>Proveedor</th>
                                <th className='listado-header-field w10'>Fecha</th>
                                <th className='listado-header-field w15'>Estado</th>
                                <th className='listado-header-field w10'>Importe</th>
                                <div className='acciones'>
                                    <label>Acciones</label>
                                </div>
                                
                            </thead>
                            
                            <div className='wrapper-listado'>
                            {mostrarPedidos()}

                            </div>                    
                        </table>
                    </div>
        </div>
    );

};
PedidosCComponent.propTypes = {

};
export default PedidosCComponent;