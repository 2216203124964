import React, { useState, useEffect } from 'react';
import ContainerComponent from './containerComponent';
import HeaderComponent from './headerComponent';
import LoginForm from '../pure/forms/loginForm';

const MainComponent = () => {

    const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.screen.width < 768);
    };

    // Ejecutar handleResize al cargar la página
    handleResize();

    // Agregar un event listener para manejar cambios en el tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Limpieza del event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    const estadoInicial = {
        active: 1
    }
    const [data, setData] = useState(estadoInicial);
    const [logged, setLogged] = useState(false);

    function setToken(token,username){
        var d = {...data};
        d.token = token;
        d.username = username;
        setLogged(true);
        setData(d);
    }

    function actualizarMenu(id){
        var estado = {...data};
        estado['active'] = id;
        setData(estado);
    }

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    function login(){
        
        if(isMobile){
            
            return (
                <div className={`app ${sidebarOpen ? 'sidebar-open' : ''}`}>
                
                    {/* Capa de atenuación */}
                    {sidebarOpen && <div className="overlay" onClick={closeSidebar}></div>}
                        
                    {/* Sidebar */}
                    <div className={`sidebar-movil ${sidebarOpen ? 'open' : ''}`}>
                        <ul>
                            <li onClick={() => {actualizarMenu(1); closeSidebar();}}>Pedidos de clientes</li>
                            <li onClick={() => {actualizarMenu(2); closeSidebar();}}>Clientes</li>
                            {/* Agrega más elementos según sea necesario */}
                        </ul>
                    </div>
                
                    <HeaderComponent data={data} actualizarMenu = {actualizarMenu} toggleSidebar={toggleSidebar}></HeaderComponent>
                    <ContainerComponent data={data} actualizarMenu = {actualizarMenu}></ContainerComponent>            
                </div>);
            
        }else{
            return (
                <div>
                    <HeaderComponent data={data} actualizarMenu = {actualizarMenu} toggleSidebar={toggleSidebar}></HeaderComponent>
                    <ContainerComponent data={data} actualizarMenu = {actualizarMenu}></ContainerComponent>            
                </div>);
        }
        
    }

    return (
        <div>
            {!logged ? <LoginForm setToken={setToken}></LoginForm> : login()}
        </div>
    );
};

export default MainComponent;
