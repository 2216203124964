import React, { useState } from 'react';
import PropTypes from 'prop-types';
import logo from '../../../img/logo.png';
import { basicAuthentication} from '../../../services/fecthService';

const LoginForm = ({setToken, setUser}) => {
    
    const obtainToken = () => {
        var user = document.getElementById('login-input-user').value;
        var password = document.getElementById('login-input-password').value;
        basicAuthentication(user, password)
        .then((response) => {
            setToken(response.token, response.username);
        })
        .catch(()=>{
            alert("Error de autenticación");
        })
        .finally(() => {
            
        });
    }
    
    function setInitialData(){
        obtainToken();
    }

    return (
        <div className='loginContainer'>
            <div className='loginCard'>
                <img src={logo}></img>
                <div className='login-row'>
                    <h6 className='login-label'>Usuario</h6>
                </div>
                <div className='login-row' style={{margin: '0 0 3vh 0'}}>
                    <input id='login-input-user' className='login-input w60'></input>
                </div>

                <div className='login-row'>
                    <h6 className='login-label'>Contraseña</h6>
                </div>
                <div className='login-row' style={{margin: '0 0 3vh 0'}}>
                    <input id='login-input-password' className='login-input w60' type='password'></input>
                </div>
                
                <div className='login-row'>
                    <button id='login-button' className='btn btn-warning' onClick={()=>setInitialData()}>Iniciar sesión</button>
                </div>
            </div>
        </div>
            
    );
};


LoginForm.propTypes = {

};


export default LoginForm;
