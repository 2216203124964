export class AlbaranProveedor{
    
    id='';
    numeroAlbaran='';
    proveedor='';
    fecha='';
    fechaEntrega='';
    estado='';
    observaciones='';

    articulos=[];
    
    constructor(id, numeroAlbaran, proveedor, fecha, fechaEntrega, estado, observaciones, articulos){
        this.id=id;
        this.numeroAlbaran=numeroAlbaran;
        this.proveedor = proveedor;
        this.fecha = fecha;
        this.fechaEntrega = fechaEntrega;
        this.estado = estado;
        this.observaciones = observaciones;
        this.articulos = articulos;
    }
}