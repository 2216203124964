import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { Cliente } from '../../models/cliente.class';
import '../../styles/styles.scss'

const EventoComponent = ({evento}) => {

    function formatearFecha(item){
        var date = new Date(item);
        var split = date.toLocaleDateString();
        var split2 = split.split('/');
        
        var day = split2[0];
        var month = split2[1];
        var year = split2[2];
        if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
            day = '0'.concat(day);
        }
        if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
            month = '0'.concat(month);
        }
        return day.concat('-').concat(month).concat('-').concat(year);
    }
    return (
        <div>
            <tr key={evento.id} className='listado-row'>
                <td className='listado-field w7_5' style={{padding: '0 0 0 1vw'}}>{evento.id}</td>
                <td className='listado-field w10'>{formatearFecha(evento.fecha)}</td>
                <td className='listado-field w10'>{evento.usuario}</td>
                <td className='listado-field w75'>{evento.evento}</td>
            </tr>
        </div>
    );
};


EventoComponent.propTypes = {
    cliente: PropTypes.instanceOf(Cliente)
}


export default EventoComponent;
