import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { findClientes } from '../../services/fecthService';
import { findArticulos } from '../../services/fecthService';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';

const AlbaranComponent = ({albaran, checkboxChanged, modificarAlbaran, generarDocumentoAlbaran, borrarAlbaran, sayModalIsOpen, facturarUno, sayDatosAlbaranModal}) => {



    const [isMobile, setIsMobile] = useState(false);
    // Estado para controlar si el modal está abierto o cerrado
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Datos que quieres pasar al modal
  const [modalData, setModalData] = useState(null);


    useEffect(() => {
        const handleResize = () => {
            // eslint-disable-next-line no-restricted-globals
       setIsMobile(screen.width < 768);
   };

// Ejecutar handleResize al cargar la página
handleResize();

// Agregar un event listener para manejar cambios en el tamaño de la ventana
window.addEventListener('resize', handleResize);

// Limpieza del event listener cuando el componente se desmonta
return () => {
 window.removeEventListener('resize', handleResize);
};
    }, []);
  // Función para abrir el modal y pasar los datos
  const openModal = (data) => {
    
    setModalData(data);
    sayModalIsOpen();
    setIsModalOpen(true);
  };

 
  // Datos para pasar al modal
  const exampleData = {
    title: 'Ejemplo de Modal',
    content: 'Este es un ejemplo de contenido para el modal.',
  };
    
    function calcularImporte(){
        var importe = 0;

        albaran.articulos.map((item) => {
            importe = parseFloat(importe) + parseFloat(item.subtotal) + parseFloat(item.importeIva);
        });
        return importe.toFixed(2);
    }

    function formatearFecha(item){
        var date = new Date(item);
        var split = date.toLocaleDateString();
        var split2 = split.split('/');
        
        var day = split2[0];
        var month = split2[1];
        var year = split2[2];
        if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
            day = '0'.concat(day);
        }
        if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
            month = '0'.concat(month);
        }
        return day.concat('-').concat(month).concat('-').concat(year);
    }

    function formatearFechaAlReves(item){
        var date = new Date(item);
        var split = date.toLocaleDateString();
        var split2 = split.split('/');
        
        var day = split2[0];
        var month = split2[1];
        var year = split2[2];
        if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
            day = '0'.concat(day);
        }
        if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
            month = '0'.concat(month);
        }
        return year.concat('-').concat(month).concat('-').concat(day);
    }
    
    function estados(){
        if(albaran.estado === "PRESUPUESTADO"){
            return (<select  className='selectEstados'onChange={() => albaranar()}>
                        <option value="PRESUPUESTADO" selected>Presupuestado</option>
                        <option value="ALBARANADO">Albaranado</option>
                    </select>);
        }else if(albaran.estado === "ALBARANADO"){
            return (<select className='selectEstados' onChange={() => facturar()}>
                        <option value="ALBARANADO" selected="selected">Albaranado</option>
                        <option value="FACTURADO">Facturado</option>
                    </select>);
        }else if(albaran.estado === "FACTURADO"){
            return ("Facturado");
        }
    }

    function albaranar(){
        var alb = {'estado': 'ALBARANADO'};
        modificarAlbaran(albaran.id, alb);
    }

    function facturar(){
        facturarUno(albaran);
    }

    function formatear3decimales(precio){
        if (typeof precio !== 'string') {
            precio = precio.toString(); // Convertir a cadena si no lo es
        }
    
        // Verificar si hay más de tres decimales y truncarlos si es necesario
        if (/^\d*\.\d{4,}$/.test(precio)) {
            const parts = precio.split('.');
            precio = `${parts[0]}.${parts[1].substring(0, 3)}`;
        }
    
      return precio;
    }


    function validarNumero(valor) {
        
        if (isNaN(valor)) {
          return "";
        } else {
          return valor;
        }
      }
    function setStyle(){
        if(albaran.estado == 'FACTURADO'){
            return ({opacity: '0%', display: 'hidden'});
        }else{
            return {};
        }
    }

    function cambiarModal(){
        sayDatosAlbaranModal(albaran);
    }
    
    function renderAlbaran(){
        if(isMobile){
            return (
                <div>
                    <tr key={albaran.id} className='listado-row'>  
                       
                        <td className='listado-field' style={{margin:'0 0 0 2vw', width:'25%'}}>{albaran.numeroAlbaran}</td>
                        <td className='listado-field bold' style={{maxWidth:'55%', width:'55%'}}>{albaran.cliente.nombreComercial}</td>    
                        <div className='acciones' style={{width:'20%'}}>
                            <i className="bi bi-eye hover" title="Ver datos" data-bs-toggle="modal" data-bs-target="#datosAlbaranModal" onClick={() => cambiarModal()}></i>
                            <i className='bi bi-printer hover' title="Imprimir" onClick={() => generarDocumentoAlbaran(albaran.id, albaran.numeroAlbaran)}></i>
                        </div>
                    </tr>
                </div>
    );
            
        }else{
            return (
                <div>
                    <tr key={albaran.id} className='listado-row'> 
                       
                        <td>
                            <div className="form-check">
                                <input className="form-check-input" disabled={albaran.estado == 'FACTURADO' ? true : false} style={setStyle()} type="checkbox" value="" id="flexCheckDefault" onClick={(event) => checkboxChanged(event, albaran.id)}></input>
                            </div>
                        </td>     
                            <td className='listado-field w7_5'>{albaran.numeroAlbaran}</td>
                            <td className='listado-field w7_5'>{albaran.cliente.id}</td>
                            <td className='listado-field w32_5 bold'>{albaran.cliente.nombreComercial}</td>
                            <td className='listado-field w10'>{formatearFecha(albaran.fecha)}</td>
                            <td className='listado-field w15'>
                                {estados()}
                            </td>
                            <td className='listado-field w10'>{
                                 calcularImporte() + '€'
                             }</td>
                             <td className='listado-field w7_5'>{albaran.responsable}</td>
        
                        <div className='acciones'>
                            <i className="bi bi-eye hover" title="Ver datos" data-bs-toggle="modal" data-bs-target="#datosAlbaranModal" onClick={() => cambiarModal()}></i>
                            <i className='bi bi-printer hover' title="Imprimir" onClick={() => generarDocumentoAlbaran(albaran.id, albaran.numeroAlbaran)}></i>
                            <i class="bi bi-x-lg hover" title="Eliminar" onClick={() => borrarAlbaran(albaran)}></i>
                        </div>
                    </tr>
                    </div>                
               
              
            );
        }
    }

    return(renderAlbaran());
};
const Modal = ({ onClose, data, actualizarAlbaran }) => {
    const [currentArticulosRows, setCurrentArticulosRows] = useState(data.articulos);

    useEffect(() => {
        
    }, [currentArticulosRows]);

    const [primeraCarga, setPrimeraCarga] = useState(true);

    const [clientesBuscados, setClientesBuscados] = useState([]);
    const [articulosBuscados, setArticulosBuscados] = useState([]);
    const [cliente, setCliente] = useState();

    function onSave(){

    }

    function addModalRow(){
        var arts = [...currentArticulosRows];
        arts.push({
            'id':'',
            'nombre': '',
            'cantidad': '',
            'precio': '',
            'descuento': 0,
            'porcentajeIva':''
        });
        setCurrentArticulosRows(arts);
    }

    function removeModalRow(y){
        var arts = [];
            for(var i = 0; i < currentArticulosRows.length; i++){
                var a = i;

                if(i != y){
    
                    var articuloToSave={};
                    articuloToSave['id'] = currentArticulosRows[i].idArticulo;
                    articuloToSave['nombre'] = currentArticulosRows[i].nombre;
                    articuloToSave['cantidad'] = currentArticulosRows[i].cantidad;
                    articuloToSave['precio'] = currentArticulosRows[i].precio;
                    articuloToSave['descuento'] = 0;
                    articuloToSave['porcentajeIva'] = currentArticulosRows[i].porcentajeIva;
                    
                    arts.push(articuloToSave);
                }
            }
        setCurrentArticulosRows(arts);
    }

    function rellenarArticulo(filtro, value, a){
        
        var index = articulosBuscados.findIndex((item) => item.nombre == value);

        if(value != null){
            if(filtro == 'nombre'){
                
                document.getElementById('pcarfieldPrecio'.concat(a)).value = articulosBuscados[index].precio;

            }
        }
        let b = [...currentArticulosRows];
        for(var i = 0; i < currentArticulosRows.length; i++){
            var x = i;
            if(b[i].id == ''){
                b[i].id = articulosBuscados[index].idArticulo;
                b[i].porcentajeIva = articulosBuscados[index].porcentajeIva;
            }
            
            b[i].nombre = document.getElementById('pcarfieldNombre'.concat(x)).value;
            b[i].cantidad = document.getElementById('pcarfieldUnidades'.concat(x)).value;
            b[i].precio = document.getElementById('pcarfieldPrecio'.concat(x)).value;

        }
        setCurrentArticulosRows(b);
    }

    function renderArticulo(articulo,index){
        return(
            <div key={'pedidoModalRow'.concat(index)} className='pedido' style={{ borderRadius:'1vw', border:'1px solid rgb(219, 219, 219)', margin:'0 0 1vh 0'}}>
                <div className='articulo-modal-header-movil' style={{margin:'1vh 0 0 0'}}>
                    <label style={{width:'78%', margin:'0 1% 0 1%'}}>Artículo</label>
                    <i style={{fontWeight:'bold',textAlign:'right',width:'17%', margin:'0 2% 0 1%'}} class="bi bi-x-lg hover" title="Eliminar" onClick={() => removeModalRow(index)}></i>
                </div>
                <div style={{display:'flex', justifyContent:'flex-start', width:'100%'}}>
                    <Autocomplete
                        PaperComponent={({ children }) => (
                        <Paper
                            elevation={3}
                            style={{
                                maxWidth: '100vw'
                            }}
                            >
                            {children}
                        </Paper>)}
                        style={{maxWidth:'100%', fontSize:'1.5vh', textAlign:'left', width:'98%', borderRadius:'1vw', border:'none', margin:'0 1% 0 1%'}}
                        onInputChange={(evento) => handleArticulo(evento)} 
                        onChange={(event, value) => rellenarArticulo('nombre', value, index)}
                        freeSolo
                        value={articulo.nombre}
                        id={'pcarfieldNombre'.concat(index)}
                        options={articulosBuscados.map((item) => item.nombre)}
                        renderInput={(params) => (
                            <div style={{}} ref={params.InputProps.ref}>
                                <input style={{ border: '1px solid lightgrey', borderRadius: '3px', width:'100%'}} type="text" {...params.inputProps}/>
                            </div>
                        )}
                    />  
                </div>
                <div className='articulo-modal-header-movil' style={{margin:'0.5vh 0 0 0'}}>
                    <label style={{ textAlign:'left', width:'48.5%', margin:'0 0.5% 0 1%'}}>Precio sin iva</label>
                    <label style={{ textAlign:'left', width:'48.5%', margin:'0 1% 0 0.5%'}}>Unidades</label>
                </div>
                
                <div style={{display:'flex', justifyContent:'flex-start', width:'100%', margin:'0 0 2vh 0'}}>
                    <input id={'pcarfieldPrecio'.concat(index)} style={{fontSize:'1.5vh', textAlign:'left', width:'48.5%', borderRadius:'1vw', border:'1px solid rgb(219, 219, 219)', margin:'0 0.5% 0 1%'}} value={articulo.precio} onChange={(event) => handleInputChange(event, index, 'precio')}></input>
                    <input id={'pcarfieldUnidades'.concat(index)} style={{fontSize:'1.5vh', textAlign:'left', width:'48.5%', borderRadius:'1vw', border:'1px solid rgb(219, 219, 219)', margin:'0 1% 0 0.5%'}} value={articulo.cantidad} onChange={(event) => handleInputChange(event, index, 'cantidad')}></input>
                </div>
            </div>
            
            
        );
        
    }

    function renderArticulos(){
        var arts = [];
        currentArticulosRows.map((item, index) => {
            arts.push(renderArticulo(item, index));
        });
        return arts;
    }

    function handleCliente(event){
        if(event != null){
            var cl = [];
        
            cl.push(event.target.value);
            findClientes(data.token, cl)
                .then((response) => {
                    setClientesBuscados(response.reverse());
                }) 
                .catch(()=>{
                })
                .finally(() => {
                });
        }
    }

    function handleArticulo(event){
        if(event != null){
            var ar = [];
            ar.push(event.target.value);
            findArticulos(data.token, ar)
                .then((response) => {
                    setArticulosBuscados(response.reverse());
                }) 
                .catch(()=>{
                })
                .finally(() => {
                });
        }
        
    }

    function rellenarCliente(value){
        if(value != null){
            var split = value.split('|');
            setCliente(clientesBuscados.find((item) => item.id == split[2]));
        }   
    }

    function handleInputChange(event, a, name){

        let rows = [...currentArticulosRows];
        for(var i = 0; i < currentArticulosRows.length; i++){
            rows[i].nombre = document.getElementById('pcarfieldNombre'.concat(i)).value;
            rows[i].cantidad = document.getElementById('pcarfieldUnidades'.concat(i)).value;
            rows[i].precio = document.getElementById('pcarfieldPrecio'.concat(i)).value;
        }

        if(name == 'nombre'){
            rows[a].nombre = event.target.value;
        }
        if(name == 'cantidad'){
            rows[a].cantidad = event.target.value;
        }
        if(name == 'precio'){
            rows[a].precio = event.target.value;
        }

        setCurrentArticulosRows(rows);
    }

    function prepararActualizacion(){

        actualizarAlbaran(data.id, 'numeroAlbaran', document.getElementById('pedidoC-movil-field-numeroAlbaran').value);
        
        if(cliente == undefined){
            actualizarAlbaran(data.id, 'cliente', data.cliente);
        }else{  
            actualizarAlbaran(data.id, 'cliente', cliente);
        }
        
        actualizarAlbaran(data.id, 'fecha', document.getElementById('pedidoC-movil-field-fecha').value);
        
        actualizarAlbaran(data.id, 'fechaEntrega', document.getElementById('pedidoC-movil-field-fechaEntrega').value);
        
        actualizarAlbaran(data.id, 'observaciones', document.getElementById('pedidoC-movil-field-observaciones').value);
    
        var articulosToSave = [];
        for(var i = 0; i < currentArticulosRows.length; i++){
            var articuloToSave ={};
            articuloToSave['idArticulo'] = currentArticulosRows[i].idArticulo;
            articuloToSave['nombre'] = document.getElementById('pcarfieldNombre'.concat(i)).value;
            articuloToSave['precio'] = document.getElementById('pcarfieldPrecio'.concat(i)).value;
            articuloToSave['cantidad'] = document.getElementById('pcarfieldUnidades'.concat(i)).value;
            articuloToSave['descuento'] = 0;
            articuloToSave['porcentajeIva'] = currentArticulosRows[i].porcentajeIva;
            articuloToSave['importeIva'] =
                (
                    (
                        ( articuloToSave['precio']*articuloToSave['cantidad'] )
                        *
                        ( 1-articuloToSave['descuento']/100 )
                    )
                    *
                    articuloToSave['porcentajeIva']/100
                ).toFixed(2);

            articuloToSave['subtotal'] = 
                (
                    (
                        (
                            ( articuloToSave['precio']*articuloToSave['cantidad'] )
                            *
                            ( 1-articuloToSave['descuento']/100 )
                        )
                    ) 
                    + 
                    (
                        ( articuloToSave['precio']*articuloToSave['cantidad'] )
                        *
                        ( 1-articuloToSave['descuento']/100 )
                    )
                    *
                    articuloToSave['porcentajeIva']/100
                ).toFixed(2)


            articulosToSave.push(articuloToSave);

    }
    actualizarAlbaran(data.id, 'articulos', articulosToSave);

}
    return (
      <div className="modal-overlay" id='overlay' onClick={onClose}>
        <div className="modal-content" style={{position:'relative'}} onClick={(e) => e.stopPropagation()}>
            <h3>Albarán {data.numeroAlbaran}</h3>
            <div style={{overflow: 'scroll', maxWidth:'100vw'}}>
                <p className='p-modal-movil'>Número albarán</p>
                <input className='input-modal-movil' id='pedidoC-movil-field-numeroAlbaran' defaultValue={data.numeroAlbaran}></input> 
                <p className='p-modal-movil'>Cliente</p>
                <Autocomplete
                    PaperComponent={({ children }) => (
                    <Paper
                        elevation={3}
                        style={{
                            maxWidth: '100vw'
                        }}
                        >
                        {children}
                    </Paper>)}
                    style={{maxWidth:'100%', fontSize:'1.5vh'}}
                    onInputChange={(evento) => handleCliente(evento)} 
                    onChange={(event,value) => rellenarCliente(value)}                  
                    freeSolo
                    defaultValue={data.cliente.nombreComercial.concat(" | ").concat(data.cliente.cif).concat(" | ").concat(data.cliente.id)}
                    id="autocomplete-cliente"
                    options={clientesBuscados.map((item) => item.nombreComercial.concat(" | ").concat(item.cif).concat(" | ").concat(item.id))}
                    renderInput={(params) => (
                        <div style={{}} ref={params.InputProps.ref}>
                            <input style={{ border: '1px solid lightgrey', borderRadius: '3px', width:'100%'}} type="text" {...params.inputProps}/>
                        </div>
                    )}
                />  
                <p className='p-modal-movil'>Fecha</p>
                <input className='input-modal-movil' id='pedidoC-movil-field-fecha' type='date' defaultValue={new Date().toISOString().split('T')[0]}></input>
                <p className='p-modal-movil'>Fecha entrega</p>
                <input className='input-modal-movil' id='pedidoC-movil-field-fechaEntrega' type='date' defaultValue={new Date().toISOString().split('T')[0]}></input>
                <p className='p-modal-movil'>Observaciones</p>
                <input className='input-modal-movil' id='pedidoC-movil-field-observaciones' defaultValue={data.observaciones}></input>
                <p className='p-modal-movil'>Artículos</p>
                {renderArticulos()}
                <p style={{textAlign:'center', width:'100%', fontSize:'2vh', fontWeight:'bold'}} onClick={() => addModalRow()}>+</p>
            </div>
            
            <div className="modal-footer">
                <button  data-bs-dismiss="modal" aria-label="Close" style={{backgroundColor: 'rgb(219, 219, 219)'}}>Cerrar</button>
                <button onClick={() => prepararActualizacion()}>Guardar cambios</button>
            </div>
        </div>
      </div>
    );
    
  };


AlbaranComponent.propTypes = {

};


export default AlbaranComponent;
