import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Cliente } from '../../models/cliente.class';
import '../../styles/styles.scss'

const ClienteComponent = ({cliente, guardarCambios, borrarCliente, data, sayModalIsOpen, sayModalIsNotOpen}) => {
    const [isMobile, setIsMobile] = useState(false);
    // Estado para controlar si el modal está abierto o cerrado
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Datos que quieres pasar al modal
  const [modalData, setModalData] = useState(null);
  useEffect(() => {
    const handleResize = () => {
        // eslint-disable-next-line no-restricted-globals
   setIsMobile(screen.width < 768);
};

// Ejecutar handleResize al cargar la página
handleResize();

// Agregar un event listener para manejar cambios en el tamaño de la ventana
window.addEventListener('resize', handleResize);

// Limpieza del event listener cuando el componente se desmonta
return () => {
window.removeEventListener('resize', handleResize);
};
}, []);
// Función para abrir el modal y pasar los datos
const openModal = (data) => {

setModalData(data);
sayModalIsOpen();
setIsModalOpen(true);
};

// Función para cerrar el modal
const closeModal = () => {

setIsModalOpen(false);
sayModalIsNotOpen();

};


// Datos para pasar al modal
const exampleData = {
title: 'Ejemplo de Modal',
content: 'Este es un ejemplo de contenido para el modal.',
};

      function handleOnChange(event){}
      function handleOnKeyUp(event){
        if (event.keyCode === 13) {
            guardarCambios(cliente.id, document.activeElement.name, event.target.value)
        }
      }

function renderCliente(){
    if(isMobile){
        return(
            <div>
            {isModalOpen && (
                <Modal
                data={cliente}
                onClose={closeModal} // Pasamos la función para cerrar el modal
                actualizarCliente={guardarCambios}
        
                
                />
            )}
            <tr key={cliente.id} className='listado-row'>
                <td className='listado-field' style={{margin:'0 0 0 2vw', width:'25%'}}>{cliente.cif}</td>
                <td className='listado-field bold' style={{maxWidth:'55%', width:'55%'}}>{cliente.nombreComercial}</td>
                <div className='acciones' style={{width:'20%'}}>
                    <i className="bi bi-eye" title="Ver datos" onClick={() => openModal(exampleData)}></i>
                </div>
            </tr>
        </div>
        );
    }else{
        return (
            <div>
            <tr key={cliente.id} className='listado-row'>
                <td className='listado-field w7_5' style={{padding: '0 0 0 1vw'}}>{cliente.id}</td>
                <td className='listado-field w10'>{cliente.cif}</td>
                <td className='listado-field w40 bold'>{cliente.nombreComercial}</td>
                <td className='listado-field w10'>{cliente.telefono}</td>
                <td className='listado-field w12_5'>{cliente.provincia}</td>
                <td className='listado-field w15'>{cliente.poblacion}</td>
                <div className='acciones'>
                    <i className="bi bi-caret-down" title="Ver datos" data-bs-toggle="collapse" data-bs-target={'#collapseExample'.concat(cliente.id)} aria-expanded="true" aria-controls="collapseExample"></i>
                    <i class="bi bi-x-lg hover" title="Eliminar" onClick={() => borrarCliente(cliente)}></i>
                </div>
            </tr>
                <div className="collapse" id={'collapseExample'.concat(cliente.id)}>
                    <div className="card card-body">
                    <h6 className='collapse-title'>Datos generales</h6>
                    <div className='collapse-row'>
                        <div className='collapse-field w50'>
                            <label className='cflabel w17_5'>Nombre</label>
                            <input className='cfinput w82_5' defaultValue={cliente.nombre} name='nombre' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>                       
                        </div>
                        <div className='collapse-field w50'>
                            <label className='cflabel w30'>Nombre comercial</label>
                            <input className='cfinput w70' defaultValue={cliente.nombreComercial} name='nombreComercial' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        </div>
                    </div>
                    <div className='collapse-row'>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>CIF</label>
                            <input className='cfinput w65' defaultValue={cliente.cif} name='cif' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        </div>
                    </div>
                          
                    <div className='separator'></div>
                    
                    <h6 className='collapse-title'>Datos económicos</h6>
                    <div className='collapse-row'>
                            <div className='collapse-field w25'>
                                <label className='cflabel w35'>Tributación</label>
                                <select className='cfselect w65' id="cliente-modal-field-tributacion"  name='tributacion' defaultValue={cliente.tipoDePago} onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}>
                                    <option value="NACIONAL" selected="selected">Nacional</option>
                                    <option value="CYM">Ceuta y Melilla</option>
                                    <option value="CANARIAS">Canarias</option>
                                    <option value="UE">Unión Europea</option>
                                    <option value="EXTRACOMUNITARIO">Extracomunitario</option>
                                </select>                        </div>
                            <div className='collapse-field w25'>
                                <label className='cflabel w35'>Tipo de pago</label>
                                <select className='cfselect w65' id="cliente-modal-field-tipoDePago"  name='tipoDePago' defaultValue={cliente.tipoDePago} onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}>
                                    <option value="CONTADO" selected="selected">Contado</option>
                                    <option value="TARJETA">Tarjeta</option>
                                    <option value="TRANSFERENCIA">Transferencia</option>
                                    <option value="D10">Pago a 10 días</option>
                                    <option value="D30">Pago a 30 días</option>
                                    <option value="D45">Pago a 45 días</option>
                                    <option value="D60">Pago a 60 días</option>
                                </select>
                            </div>
                    </div>
                        
                    <div className='separator'></div>
                    
                    <h6 className='collapse-title'>Localización</h6>
                    <div className='collapse-row'>
                            <div className='collapse-field w50'>
                                <label className='cflabel w17_5'>Dirección</label>
                                <input className='cfinput w82_5' defaultValue={cliente.direccion}  name='direccion' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                            </div>
                            <div className='collapse-field w25'>
                                <label className='cflabel w35'>Provincia</label>
                                <input className='cfinput w65' defaultValue={cliente.provincia}  name='provincia' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                            </div>
                            <div className='collapse-field w25'>
                                <label className='cflabel w35'>Población</label>
                                <input className='cfinput w65' defaultValue={cliente.poblacion}  name='poblacion' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                            </div>
                    </div>
                    <div className='collapse-row'>
                        <div className='collapse-field w25'>
                            <label className='cflabel w35'>Código postal</label>
                            <input className='cfinput w65' defaultValue={cliente.cp}  name='cp' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                        </div>
                    </div>
                
                    <div className='separator'></div>
                
                    <h6 className='collapse-title'>Contacto</h6>
                    <div className='collapse-row'>
                            <div className='collapse-field w50'>
                                <label className='cflabel w17_5'>Email</label>
                                <input className='cfinput w82_5' defaultValue={cliente.email}  name='email' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                            </div>
                            <div className='collapse-field w25'>
                                <label className='cflabel w35'>Teléfono</label>
                                <input className='cfinput w65' defaultValue={cliente.telefono}  name='telefono' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                            </div>
                            <div className='collapse-field w25'>
                                <label className='cflabel w35'>Móvil</label>
                                <input className='cfinput w65' defaultValue={cliente.movil}  name='movil' onChange={(event)=>handleOnChange(event)} onKeyUp={(event)=>handleOnKeyUp(event)}></input>
                            </div>
                    </div>
                    
                    <div className='separator'></div>
            
                    <h6 className='collapse-title'>Otros</h6>
                    <div className='modal-row'>
                        <label className='cflabel w8_75'>Observaciones</label>
                        <textarea defaultValue={cliente.observaciones} className='cftextarea w91_75' name='observaciones' onKeyUp={(event) => handleOnKeyUp(event)} onChange={(event)=>handleOnChange(event)}></textarea>   
                    </div>
                        
                    </div>
                </div>
            </div>
        );
    }
}
    return (renderCliente());
};


const Modal = ({ onClose, data, actualizarCliente, closeModal }) => {

    useEffect(() => {
        
    }, );


    const [clientesBuscados, setClientesBuscados] = useState([]);
    const [articulosBuscados, setArticulosBuscados] = useState([]);
    const [cliente, setCliente] = useState();

    function prepararActualizacion(){

        actualizarCliente(data.id, 'nombre', document.getElementById('cliente-movil-field-nombre').value);
        actualizarCliente(data.id, 'nombreComercial', document.getElementById('cliente-movil-field-nombreComercial').value);
        actualizarCliente(data.id, 'cif', document.getElementById('cliente-movil-field-cif').value);
        actualizarCliente(data.id, 'tributacion', document.getElementById('cliente-movil-field-tributacion').value);
        actualizarCliente(data.id, 'tipoDePago', document.getElementById('cliente-movil-field-tipoDePago').value);
        actualizarCliente(data.id, 'direccion', document.getElementById('cliente-movil-field-direccion').value);
        actualizarCliente(data.id, 'provincia', document.getElementById('cliente-movil-field-provincia').value);
        actualizarCliente(data.id, 'poblacion', document.getElementById('cliente-movil-field-poblacion').value);
        actualizarCliente(data.id, 'cp', document.getElementById('cliente-movil-field-cp').value);
        actualizarCliente(data.id, 'email', document.getElementById('cliente-movil-field-email').value);
        actualizarCliente(data.id, 'telefono', document.getElementById('cliente-movil-field-telefono').value);
        actualizarCliente(data.id, 'movil', document.getElementById('cliente-movil-field-movil').value);
        actualizarCliente(data.id, 'observaciones', document.getElementById('cliente-movil-field-observaciones').value);
        onClose();
}

    return (
      <div className="modal-overlay" id='overlay' onClick={onClose}>
        <div className="modal-content" style={{position:'relative'}} onClick={(e) => e.stopPropagation()}>
        <h3>{data.nombreComercial}</h3>
            <div style={{overflow: 'scroll', maxWidth:'100vw'}}>
                <p className='p-modal-movil'>Nombre</p>
                <input className='input-modal-movil' id='cliente-movil-field-nombre' defaultValue={data.nombre}></input>

                <p className='p-modal-movil'>Nombre comercial</p>
                <input className='input-modal-movil' id='cliente-movil-field-nombreComercial' defaultValue={data.nombreComercial}></input>

                <p className='p-modal-movil'>CIF</p>
                <input className='input-modal-movil' id='cliente-movil-field-cif' defaultValue={data.cif}></input>

                <p className='p-modal-movil'>Tributación</p>
                <select className='mfselect' style={{width:'100%'}} name='tributacion' id="cliente-movil-field-tributacion"  defaultValue={data.tributacion}>
                            <option value="NACIONAL" selected="selected">Nacional</option>
                            <option value="CYM">Ceuta y Melilla</option>
                            <option value="CANARIAS">Canarias</option>
                            <option value="UE">Unión Europea</option>
                            <option value="EXTRACOMUNITARIO">Extracomunitario</option>
                        </select> 
                <p className='p-modal-movil'>Tipo de pago</p>
                <select className='mfselect' style={{width:'100%'}} name='tipoDePago' id="cliente-movil-field-tipoDePago" defaultValue={data.tipoDePago}>
                            <option value="CONTADO" selected="selected">Contado</option>
                            <option value="TARJETA">Tarjeta</option>
                            <option value="TRANSFERENCIA">Transferencia</option>
                            <option value="D10">Pago a 10 días</option>
                            <option value="D30">Pago a 30 días</option>
                            <option value="D45">Pago a 45 días</option>
                            <option value="D60">Pago a 60 días</option>
                        </select> 
                <p className='p-modal-movil'>Dirección</p>
                <input className='input-modal-movil' id='cliente-movil-field-direccion' defaultValue={data.direccion}></input>

                <p className='p-modal-movil'>Provincia</p>
                <input className='input-modal-movil' id='cliente-movil-field-provincia' defaultValue={data.provincia}></input>

                <p className='p-modal-movil'>Población</p>
                <input className='input-modal-movil' id='cliente-movil-field-poblacion' defaultValue={data.poblacion}></input>

                <p className='p-modal-movil'>Código postal</p>
                <input className='input-modal-movil' id='cliente-movil-field-cp' defaultValue={data.cp}></input>

                <p className='p-modal-movil'>Email</p>
                <input className='input-modal-movil' id='cliente-movil-field-email' defaultValue={data.email}></input>

                <p className='p-modal-movil'>Teléfono</p>
                <input className='input-modal-movil' id='cliente-movil-field-telefono' defaultValue={data.telefono}></input>

                <p className='p-modal-movil'>Móvil</p>
                <input className='input-modal-movil' id='cliente-movil-field-movil' defaultValue={data.movil}></input>

                <p className='p-modal-movil'>Observaciones</p>
                <input className='input-modal-movil' id='cliente-movil-field-observaciones' defaultValue={data.observaciones}></input>
            </div>
            
            <div className="modal-footer">
                <button onClick={onClose} style={{backgroundColor: 'rgb(219, 219, 219)'}}>Cerrar</button>
                <button onClick={() => prepararActualizacion()}>Guardar cambios</button>
            </div>
        </div>
      </div>
    );
    
  };
ClienteComponent.propTypes = {
    cliente: PropTypes.instanceOf(Cliente)
}


export default ClienteComponent;
