import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { event } from 'jquery';
import { findProveedores } from '../../services/fecthService';
import { findArticulos } from '../../services/fecthService';
import Autocomplete from '@mui/material/Autocomplete';


const AlbaranProveedorComponent = ({albaran, checkboxChanged, proveedores, articulos, active, modificarAlbaran, albaranarUno, facturarUno, borrarAlbaran, data, sayDatosAlbaranProveedorModal}) => {

    const [currentArticulosRows, setCurrentArticulosRows] = useState([]);

    const [primeraCarga, setPrimeraCarga] = useState(true);

    const [proveedor, setProveedor] = useState();

    const [proveedoresBuscados, setProveedoresBuscados] = useState([]);
    const [articulosBuscados, setArticulosBuscados] = useState([]);

    useEffect(() => {
        setArticulos();
    }, []);

    function setArticulos(){

        var arts = [];
        if(primeraCarga){
            albaran.articulos.map((item) => {
                arts.push({
                    'inventariable': item.inventariable,
                    'id': item.id,
                    'nombre': item.nombre,
                    'unidades': item.cantidad,
                    'precio': item.precio,
                    'descuento': item.descuento,
                    'idArticulo': item.idArticulo,
                    'porcentajeIva': item.porcentajeIva,
                    'importeIva': item.importeIva,
                    'subtotal': item.subtotal
                });
            });
            setPrimeraCarga(false);
        }else{
            var articulosRows = document.getElementsByClassName('pedidoC'.concat(albaran.id));
            for(var i = 0; i < articulosRows.length; i++){
                arts.push({
                    'inventariable': document.getElementById('pcarfieldStock'.concat(i)).value,
                    'id': document.getElementById('pcarfieldId'.concat(i)).value,
                    'nombre': document.getElementById('pcarfieldNombre'.concat(i)).value,
                    'unidades': document.getElementById('pcarfieldUnidades'.concat(i)).value,
                    'precio': document.getElementById('pcarfieldPrecio'.concat(i)).value,
                    'porcentajeIva': document.getElementById('pcarfieldPorcentajeIva'.concat(i)).value,
                    'importeIva': document.getElementById('pcarfieldImporteIva'.concat(i)).value,
                    'subtotal': document.getElementById('pcarfieldSubtotal'.concat(i)).value,
                });
            }
        }
        setCurrentArticulosRows(arts);
    }
    
    
    function calcularImporte(){
        var importe = 0;

        albaran.articulos.map((item) => {
            importe = parseFloat(importe) + parseFloat(item.subtotal) + parseFloat(item.importeIva);
        });
        return importe.toFixed(2);
    }

    function formatearFecha(item){
        var date = new Date(item);
        var split = date.toLocaleDateString();
        var split2 = split.split('/');
        
        var day = split2[0];
        var month = split2[1];
        var year = split2[2];
        if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
            day = '0'.concat(day);
        }
        if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
            month = '0'.concat(month);
        }
        return day.concat('-').concat(month).concat('-').concat(year);
    }

    function formatearFechaAlReves(item){
        var date = new Date(item);
        var split = date.toLocaleDateString();
        var split2 = split.split('/');
        
        var day = split2[0];
        var month = split2[1];
        var year = split2[2];
        if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
            day = '0'.concat(day);
        }
        if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
            month = '0'.concat(month);
        }
        return year.concat('-').concat(month).concat('-').concat(day);
    }

    function setStyle(){
        let style = {};

        if(albaran.estado === 'Facturado')
            style['display'] = 'hidden';
            style['opacity'] = '0%';

        return style;
    }
    
    function estados(){
        if(albaran.estado === "PRESUPUESTADO"){
            return (<select  className='selectEstados'onChange={() => albaranar()}>
                        <option value="PRESUPUESTADO" selected>Presupuestado</option>
                        <option value="ALBARANADO">Albaranado</option>
                    </select>);
        }else if(albaran.estado === "ALBARANADO"){
            return (<select className='selectEstados' onChange={() => facturar()}>
                        <option value="ALBARANADO" selected="selected">Albaranado</option>
                        <option value="FACTURADO">Facturado</option>
                    </select>);
        }else if(albaran.estado === "FACTURADO"){
            return ("Facturado");
        }
    }

    function albaranar(){
        var alb = {'estado': 'ALBARANADO'};
        modificarAlbaran(albaran.id, alb);
    }

    function facturar(){
        facturarUno(albaran);
    }

    function redondear(precio){
    
        if(precio == '' || precio == undefined){
            return '';
        }else{
            return parseFloat(precio).toFixed(2);
        }
    }

    function handleProveedor(event){
        if(event != null){
            var pr = [];
        
            pr.push(event.target.value);
            findProveedores(data.token, pr)
                .then((response) => {
                    setProveedoresBuscados(response.reverse());
                }) 
                .catch(()=>{
                })
                .finally(() => {
                });
        }
    }

    function handleArticulo(event){
        if(event != null){
            var ar = [];
            ar.push(event.target.value);
            findArticulos(data.token, ar)
                .then((response) => {
                    setArticulosBuscados(response.sort((a, b) => a.nombre.localeCompare(b.nombre)));
                }) 
                .catch(()=>{
                })
                .finally(() => {
                });
        }
        
    }

    function handleOnKeyUp(event, name){
        if (event.keyCode === 13) {
            if(name == 'proveedor'){
                var split = event.target.value.split('|');
                console.log(proveedores.find((item) => item.id == parseInt(split[0].split(':')[1].trim())));
                actualizarAlbaran(albaran.id, name, proveedores.find((item) => item.id == parseInt(split[0].split(':')[1].trim())));

            }else if(name == 'articulos'){
                
                const temp = document.getElementsByClassName('pedidoC'.concat(albaran.id));
                const modalRows = [].slice.call(temp);
                var articulosToSave = [];

                modalRows.map((item, index) => {
                    var articuloToSave = {};
                    if(albaran.articulos.length > index){
                        articuloToSave['id'] = albaran.articulos[index].id;
                    }
                    articuloToSave['nombre'] = document.getElementById('pcarfieldNombre'.concat(index)).value;
                    articuloToSave['precio'] = document.getElementById('pcarfieldPrecio'.concat(index)).value;
                    articuloToSave['cantidad'] = document.getElementById('pcarfieldUnidades'.concat(index)).value;
                    articuloToSave['descuento'] = document.getElementById('pcarfieldDescuento'.concat(index)).value;
                    articuloToSave['porcentajeIva'] = document.getElementById('pcarfieldPorcentajeIva'.concat(index)).value;
                    articuloToSave['importeIva'] = document.getElementById('pcarfieldImporteIva'.concat(index)).value;
                    articuloToSave['subtotal'] = document.getElementById('pcarfieldSubtotal'.concat(index)).value;
                    articulosToSave.push(articuloToSave);
                });
                console.log(articulosToSave);
                actualizarAlbaran(albaran.id, name, articulosToSave);
            }else{
                actualizarAlbaran(albaran.id, name, event.target.value);
            }
        }
    }

    const actualizarAlbaran = (id, key, value) => {
        modificarAlbaran(id, key, value);
        setPrimeraCarga(true);
    }

    function renderArticulos(){
        let articles = [];
        currentArticulosRows.map((articulo, index) => {

            var a = index;
            articles.push(
                <div key={'pedido'.concat(albaran.id).concat('CollapseRow').concat(a)} id={'pedido'.concat(albaran.id).concat('CollapseRow').concat(a)} className={'pcarow w100 pedidoC'.concat(albaran.id)}>
                    <div className="form-check-modal w4">
                        <input className="form-check-input" type="checkbox" id={'pcarfieldStock'.concat(a)} defaultChecked={articulo.inventariable}></input>
                    </div>
                    <input className='pcarfield w10' value={articulo.idArticulo} id={'pcarfieldId'.concat(a)} onChange={(event)=>handleArticulosChange(event,a,'id')} onKeyUp={(event) => handleOnKeyUp(event, 'articulos')}></input>
                    <Autocomplete
                            freeSolo
                            defaultValue={articulo.nombre}
                            onChange={(event, value) => rellenarArticulo('nombre', value, a)}
                            onInputChange={(evento) => handleArticulo(evento)} 
                            style={{fontSize:'1.5vh', width:'20%', margin: '0 0.2vw 0 0.2vw'}}
                            id={'pcarfieldNombre'.concat(a)}
                            onKeyUp={(event) => handleOnKeyUp(event, 'articulos')}
                            options={articulosBuscados.map((item) => item.nombre)}
                            renderInput={(params) => (
                                <div style={{width:'100%'}} ref={params.InputProps.ref}>
                                   <input style={{fontSize:'1.5vh',border: '1px solid lightgrey', borderRadius: '5px', width: '100%'}} type="text" {...params.inputProps} 
                                    />
                                </div>
                            )}
                        />                    
                    <input className='pcarfield w7_5' value={articulo.unidades} id={'pcarfieldUnidades'.concat(a)} onChange={(event)=>handleArticulosChange(event,a,'unidades')} onKeyUp={(event) => handleOnKeyUp(event, 'articulos')}></input>
                    <input className='pcarfield w7_5' value={redondear(articulo.precio)} id={'pcarfieldPrecio'.concat(a)} onKeyUp={(event) => handleOnKeyUp(event, 'articulos')}></input>
                    <input className='pcarfield w7_5' value={articulo.descuento} id={'pcarfieldDescuento'.concat(a)} onChange={(event)=>handleArticulosChange(event,a,'descuento')} onKeyUp={(event) => handleOnKeyUp(event, 'articulos')}></input>
                    <input className='pcarfield w7_5' value={articulo.porcentajeIva} id={'pcarfieldPorcentajeIva'.concat(a)} onChange={(event)=>handleArticulosChange(event,a,'porcentajeIva')} onKeyUp={(event) => handleOnKeyUp(event, 'articulos')}></input>
                    <input className='pcarfield w7_5' value={articulo.importeIva} disabled id={'pcarfieldImporteIva'.concat(a)} 
                        onChange={(event)=>handleArticulosChange(event,a,'importeIva') }>
                    </input>
                    <input className='pcarfield w10' disabled id={'pcarfieldSubtotal'.concat(a)} value={articulo.subtotal}
                         onChange={(event)=>handleArticulosChange(event,a, 'subtotal')}></input>
                    <div className='acciones'>
                        <i className="bi bi-trash3" title='Eliminar' onClick={() => removeModalRow(a)}></i>
                    </div>
                </div>)
        });
        return articles;
    }
    
    function addModalRow(){
        var arts = [...currentArticulosRows];
        arts.push({
            'id': '',
            'nombre': '',
            'unidades': '',
            'precio': '',
            'descuento': 0,
            'porcentajeIva': '',
            'importeIva': '',
            'subtotal': ''
        });
        setCurrentArticulosRows(arts);

    }

    function removeModalRow(y){

        var arts = [];

        for(var i = 0; i < albaran.articulos.length; i++){
            var a = i;
            if(i != y){

                var articuloToSave={};

                articuloToSave['nombre'] = document.getElementById('pcarfieldNombre'.concat(a)).value;
                articuloToSave['cantidad'] = parseInt(document.getElementById('pcarfieldUnidades'.concat(a)).value);
                articuloToSave['precio'] = parseFloat(document.getElementById('pcarfieldPrecio'.concat(a)).value);
                articuloToSave['descuento'] = document.getElementById('pcarfieldDescuento'.concat(a)).value;
                articuloToSave['porcentajeIva'] = document.getElementById('pcarfieldPorcentajeIva'.concat(a)).value;
                articuloToSave['importeIva'] = document.getElementById('pcarfieldImporteIva'.concat(a)).value;
                articuloToSave['subtotal'] = document.getElementById('pcarfieldSubtotal'.concat(a)).value;
                
                var articulo = albaran.articulos.find((item) => item.nombre == articuloToSave['nombre']);
                console.log(articulo);
                if(articulo != null){
                    articuloToSave['id'] = articulo.id;
                }
                console.log(articuloToSave);
                arts.push(articuloToSave);
            }
        }
    
        setCurrentArticulosRows(arts);
        modificarAlbaran(albaran.id, 'articulos', arts);

    }
    function handleArticulosChange(event, a, name){

        let rows = [...currentArticulosRows];
        for(var i = 0; i < currentArticulosRows.length; i++){
            rows[i].id = parseInt(document.getElementById('pcarfieldId'.concat(i)).value);
            rows[i].nombre = document.getElementById('pcarfieldNombre'.concat(i)).value;
            rows[i].unidades = document.getElementById('pcarfieldUnidades'.concat(i)).value;
            rows[i].precio = parseFloat(document.getElementById('pcarfieldPrecio'.concat(i)).value);
            rows[i].descuento = document.getElementById('pcarfieldDescuento'.concat(i)).value;        
            rows[i].porcentajeIva = document.getElementById('pcarfieldPorcentajeIva'.concat(i)).value;
            rows[i].importeIva = document.getElementById('pcarfieldImporteIva'.concat(i)).value;
        }
        if(name == 'numeroAlbaran'){
            document.getElementById('cfNumeroAlbaran').value = event.target.value;
        }
        if(name == 'id'){
            rows[a].id = event.target.value;
        }
        if(name == 'nombre'){
            rows[a].nombre = event.target.value;
        }
        if(name == 'unidades'){
            rows[a].unidades = event.target.value;
        }
        if(name == 'precio'){
            rows[a].precio = parseFloat(event.target.value);
        }
        if(name == 'porcentajeIva'){
            rows[a].porcentajeIva = event.target.value;
        }
        if(name == 'descuento'){
            rows[a].descuento = event.target.value;
        }
        if(rows[a].unidades != '' && rows[a].precio != ''){
            rows[a].importeIva = (((rows[a].precio*rows[a].unidades)*(1-rows[a].descuento/100))*rows[a].porcentajeIva/100).toFixed(2)
            rows[a].subtotal =   ((((rows[a].precio*rows[a].unidades)*(1-rows[a].descuento/100))) + ((rows[a].precio*rows[a].unidades)*(1-rows[a].descuento/100))*rows[a].porcentajeIva/100).toFixed(2)
        }

        setCurrentArticulosRows(rows);
    
    }

    function handleCollapseFields(event, name){
        if(name == 'numeroAlbaran'){
            document.getElementById('cfNumeroAlbaran').value = event.target.value;
        }
    }

    function rellenarProveedor(value){

        if(value != null){
            var split = value.split('|');
            var c = parseInt(split[0].split(':')[1].trim());
            setProveedor(proveedoresBuscados.find((item) => item.id == c));
        } 
    }

    function rellenarArticulo(index){
        var arts = [...currentArticulosRows];
        var a = articulos.findIndex((item) => item.nombreArticulo == arts[index].value);
        console.log(articulos[index]);
        document.getElementById('pcarfieldId'.concat(index)).value = articulos[index].id;
        document.getElementById('pcarfieldPrecio'.concat(index)).value = articulos[index].precio;
        document.getElementById('pcarfieldDescuento'.concat(index)).value = 0;
        document.getElementById('pcarfieldPorcentajeIva'.concat(index)).value = articulos[index].iva;
        document.getElementById('pcarfieldImporteIva'.concat(index)).value = '';
        document.getElementById('pcarfieldSubtotal'.concat(index)).value = '';

    }

    function rellenarArticulo(filtro, value, a){
        

        if(value != null){
            if(filtro == 'nombre'){
                
                var index = articulosBuscados.findIndex((item) => item.nombre == value);

                document.getElementById('pcarfieldId'.concat(a)).value = articulosBuscados[index].id;
                document.getElementById('pcarfieldPrecio'.concat(a)).value = articulosBuscados[index].precio;
                document.getElementById('pcarfieldPorcentajeIva'.concat(a)).value = articulosBuscados[index].iva;
                
                document.getElementById('pcarfieldImporteIva'.concat(a)).value = 
                    (((document.getElementById('pcarfieldUnidades'.concat(a)).value * document.getElementById('pcarfieldPrecio'.concat(a)).value)
                    * (1-document.getElementById('pcarfieldDescuento'.concat(a)).value/100))
                    * document.getElementById('pcarfieldPorcentajeIva'.concat(a)).value/100).toFixed(2);
                
                document.getElementById('pcarfieldSubtotal'.concat(a)).value =
                    (
                        (
                            (
                                ( document.getElementById('pcarfieldPrecio'.concat(a)).value * document.getElementById('pcarfieldUnidades'.concat(a)).value)
                                * 
                                ( 1-document.getElementById('pcarfieldDescuento'.concat(a)).value/100)
                            )
                        ) 
                        + 
                        (
                            (document.getElementById('pcarfieldPrecio'.concat(a)).value * document.getElementById('pcarfieldUnidades'.concat(a)).value )
                            *
                            (1-document.getElementById('pcarfieldDescuento'.concat(a)).value/100)
                        )
                        *
                        document.getElementById('pcarfieldPorcentajeIva'.concat(a)).value/100)
                        .toFixed(2);
                
                }
        }
        let b = [...currentArticulosRows];
        for(var i = 0; i < currentArticulosRows.length; i++){
            var x = i;
            if(document.getElementById('pcarfieldId'.concat(x)).value != ''){
                b[i].id = parseInt(document.getElementById('pcarfieldId'.concat(x)).value);
            }else{
                b[i].id = '';
            }
            
            b[i].nombre = document.getElementById('pcarfieldNombre'.concat(x)).value;
            b[i].unidades = document.getElementById('pcarfieldUnidades'.concat(x)).value;
            b[i].precio = document.getElementById('pcarfieldPrecio'.concat(x)).value;
            b[i].descuento = document.getElementById('pcarfieldDescuento'.concat(x)).value;
            b[i].porcentajeIva = document.getElementById('pcarfieldPorcentajeIva'.concat(x)).value;
            b[i].importeIva = document.getElementById('pcarfieldImporteIva'.concat(x)).value;
        }

        setCurrentArticulosRows(b);
    }

    function cambiarModal(){
        sayDatosAlbaranProveedorModal(albaran);
    }

    return (
        <div>
            <tr key={albaran.id} className='listado-row'>  
            <td>
                <div className="form-check">
                    <input className="form-check-input" disabled = {albaran.estado === 'FACTURADO' ? true : false} style = {albaran.estado === 'FACTURADO' ? setStyle() : {}} type="checkbox" value="" id="flexCheckDefault" onClick={(event) => checkboxChanged(event, albaran.id)}></input>
                </div>
            </td>     
                
                    <td className='listado-field w7_5'>{albaran.numeroAlbaran}</td>
                    <td className='listado-field w7_5'>{albaran.proveedor.id}</td>
                    <td className='listado-field w40 bold'>{albaran.proveedor.nombreComercial}</td>
                    <td className='listado-field w10'>{formatearFecha(albaran.fecha)}</td>
                    <td className='listado-field w15'>
                        {estados()}
                    </td>
                    <td className='listado-field w10'>{
                         calcularImporte() + '€'
                     }</td>

                <div className='acciones'>
                    <i className="bi bi-eye hover" title="Ver datos" data-bs-toggle="modal" data-bs-target="#datosAlbaranProveedorModal" onClick={() => cambiarModal()}></i>
                    <i class="bi bi-x-lg hover" title="Eliminar" onClick={() => borrarAlbaran(albaran)}></i>
                </div>
            </tr>
        </div>
      
    );
};


AlbaranProveedorComponent.propTypes = {

};


export default AlbaranProveedorComponent;
