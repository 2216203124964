import React, {useEffect, useState, useContext} from 'react'
import PropTypes from 'prop-types'
import { preciosContext } from './pedidosPComponent';

const ModalCambioPrecioProveedorComponent = ({ actualizarPrecios, clearPrecios}) => {
    
    const [preciosValues, setPrecioValues] = useState([]);
    const [datosCargados, setDatosCargados] = useState(false);
    const precios = useContext(preciosContext);

    useEffect(() => {
        const m = document.getElementById('cambioPrecioModal');
       
        const handleC = () => {
            clearPrecios();
            setDatosCargados(false);
            setPrecioValues([]);
        }
        m.addEventListener('hidden.bs.modal', handleC);

        return() => {
            m.removeEventListener('hidden.bs.modal', handleC);
        };
    });


    function renderPrecios(){
        let htmlElements = [];
        for(var i = 0; i < preciosValues.length; i++){
        let a = i;

        htmlElements.push(
                <div key={'cambioPrecioModalRow'.concat(a)} id={'pedidoModalRow'.concat(a)} className='pmarow w100'>
                    
                    <input disabled className='pmarfield w10 center' value={preciosValues[a].id} ></input>                
                    <input disabled className='pmarfield w40 center' value={preciosValues[a].nombre} ></input>
                    <input disabled className='pmarfield w10 center' value={formatear3decimales(preciosValues[a].precioActualP)} ></input>
                    <label disabled className='pmarfield w1 center' style={{border:'none', color:'white'}}><i class="bi bi-arrow-right"></i></label>
                    <input disabled className='pmarfield w10 center' value={formatear3decimales(preciosValues[a].precioNuevoP)} ></input>
                    <input disabled className='pmarfield w10 center' value={formatear3decimales(preciosValues[a].precioActualS)} ></input>
                    <label disabled className='pmarfield w1 center' style={{border:'none', color:'white'}}><i class="bi bi-arrow-right"></i></label>
                    <input  className='pmarfield w10 center' value={preciosValues[a].precioNuevoS} id='precioNuevoS' onChange={(evento) => cambiarValorInput(a, evento)}></input>
                    <div className="form-check-modal w10">
                        <input className="form-check-input" type="checkbox" checked={preciosValues[a].cambiar == true ? 'checked': ''} id={'pmarfieldStock'.concat(a)} onClick={() => checkboxChanged(a)}></input>
                    </div>
                </div>)
        }
        return htmlElements;
    }
    
    function formatear3decimales(precio){
        if (typeof precio !== 'string') {
            precio = precio.toString(); // Convertir a cadena si no lo es
        }
    
        // Verificar si hay más de tres decimales y truncarlos si es necesario
        if (/^\d*\.\d{4,}$/.test(precio)) {
            const parts = precio.split('.');
            precio = `${parts[0]}.${parts[1].substring(0, 3)}`;
        }
    
      return precio;
    }

    function checkboxChanged(index){
        var p = [...preciosValues];
        if(p[index].cambiar == true){
            p[index].cambiar = false;
        }else{
            p[index].cambiar = true;
        }
        setPrecioValues(p); 
    }

    function cambiarValorInput(index, evento){
        var p = [...preciosValues];
        if(evento != null){
            p[index].precioNuevoS = evento.target.value;
        }
        setPrecioValues(p);
    }

    function modal(){

        if(precios != ''){
            if(!datosCargados){
                document.getElementById('cambioPrecioButton').click();
                setDatosCargados(true);
                var p = [];
                console.log('Cambiando precios');
                precios.map((item) => {
                    p.push({
                        'id': item.id,
                        'nombre': item.nombre,
                        'precioActualP': item.precioActualP,
                        'precioNuevoP': item.precioNuevoP,
                        'precioActualS': item.precioActualS,
                        'precioNuevoS': formatear3decimales(parseFloat(item.precioActualS) + parseFloat(item.precioNuevoP) - parseFloat(item.precioActualP)),
                        'cambiar':false
                    });
                });
    
                setPrecioValues(p);
            }
        }

        return (
        <div class="modal fade" id="cambioPrecioModal" tabindex="-1">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Cambio de precios de proveedores</h5>
                        <button type="button" id='closeModalCambioPrecios' class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <h6>Marque los precios que desea actualizar:</h6>
                        <div className='pedido-modal-articulos-table'>
                    <div className='pmaheader w100'>
                        <label className='pmahfield w10'>Cód. artículo</label>
                        <label className='pmahfield w40'>Nombre</label>
                        <label className='pmahfield w10'>Precio actual P</label>
                        <label className='pmahfield w1'><i class="bi bi-arrow-right"></i></label>
                        <label className='pmahfield w10'>Nuevo precio P</label>
                        <label className='pmahfield w10'>Precio actual S</label>
                        <label className='pmahfield w1'><i class="bi bi-arrow-right"></i></label>
                        <label className='pmahfield w10'>Nuevo precio S</label>
                        <label className='pmahfield w10'>Actualizar</label>
                    </div>
                    {renderPrecios().map((item) => {
                        return item;
                    })}
                </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-primary" onClick={() => actualizarPrecios(preciosValues)}>Actualizar precios</button>
                    </div>
                </div>
            </div>
        </div>);

        
    }
    return modal();
};

ModalCambioPrecioProveedorComponent.propTypes = {}

export default ModalCambioPrecioProveedorComponent;
