export class Proveedor{
    
    id='';
    nombre='';
    nombreComercial='';
    cif='';
    direccion='';
    cp='';
    poblacion='';
    provincia='';
    tributacion='';
    tipoDePago='';
    movil='';
    telefono='';
    email='';
    horario='';
    contactoComercial='';
    numeroComercial='';
    observaciones='';
    
    constructor(id, nombre, nombreComercial, cif, direccion, cp, poblacion, provincia, tributacion, tipoDePago, 
        movil, telefono, email, horario, contactoComercial, numeroComercial, observaciones){
            this.id=id;
            this.nombre=nombre;
            this.nombreComercial=nombreComercial;
            this.cif=cif;
            this.direccion=direccion;
            this.cp=cp;
            this.poblacion=poblacion;
            this.provincia=provincia;
            this.tributacion=tributacion;
            this.tipoDePago=tipoDePago;
            this.telefono=telefono;
            this.movil=movil;
            this.email=email;
            this.horario=horario;
            this.contactoComercial=contactoComercial;
            this.numeroComercial=numeroComercial;
            this.observaciones=observaciones;
    }
}