import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Cliente } from '../../models/cliente.class';

import { generarModelo347Clientes, generarModelo347Proveedores, generarCierreEmitidas, generarCierreRecibidas, generarCierreCliente, getAllClientes, findClientes } from '../../services/fecthService';

const InformesComponent = ({data, toasting, registrarEvento}) => {

    const [clientes, setClientes] = useState([]);
    const [clientesBuscados, setClientesBuscados] = useState([]);

    const obtainClientes = () => {
        
        if(clientes.length == 0){
            getAllClientes(data.token)
            .then((response) => {
                let arts = [];
                response.map((item) => {
                    var cliente = new Cliente(
                        item.id,
                        item.nombre,
                        item.nombreComercial,
                        item.cif,
                        item.direccion,
                        item.cp,
                        item.poblacion,
                        item.provincia,
                        item.tributacion,
                        item.tipoDePago,
                        item.movil,
                        item.telefono,
                        item.email,
                        item.observaciones
                    );
                    arts.push(cliente);
                  
                    
                });
                setClientes(arts);
            }) 
            .catch(()=>{
                alert("Error al recuperar clientes");
            })
            .finally(() => {
                
            });
        }
        
    }
    function generarInforme347clientes(){
        var year = document.getElementById('modelo347-clientes-year').value;
        generarModelo347Clientes(data.token,year)
            .then((response) => {
                registrarEvento('Modelo 347 de clientes para el año '.concat(year).concat(' generado.'));
                return response.blob();
            })
            .then((blob) => URL.createObjectURL(blob))
            .then((href) => {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = href;
            a.download = "Modelo 347 clientes ".concat(year);
            a.click();
            })
            .catch((error)=>{
                toasting('Error al generar el documento', 'error');
            })
            .finally(() => {
            });
        
    }

    function generarInforme347proveedores(){
        var year = document.getElementById('modelo347-proveedores-year').value;
        generarModelo347Proveedores(data.token,year)
            .then((response) => {
                registrarEvento('Modelo 347 de clientes para el año '.concat(year).concat(' generado.'));
                return response.blob();
            })
            .then((blob) => URL.createObjectURL(blob))
            .then((href) => {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = href;
            a.download = "Modelo 347 proveedores ".concat(year);
            a.click();
            })
            .catch((error)=>{
                toasting('Error al generar el documento', 'error');
            })
            .finally(() => {
            });
    }

    function generarInformeFacturasEmitidas(){
        var year = document.getElementById('cierre-emitidas-year').value;
        var trimestre = document.getElementById('cierre-emitidas-trimestre').value;
        console.log("Emitiendo informe facturas emitidas");
        generarCierreEmitidas(data.token, year, trimestre)
            .then((response) => {
                registrarEvento('Informe trimestral de facturas emitidas para el año'.concat(year).concat(' trimestre ').concat(trimestre).concat(' generado.'));
                return response.blob();
            })
            .then((blob) => URL.createObjectURL(blob))
            .then((href) => {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = href;
            a.download = "Cierre trimestral emitidas ".concat(year).concat(" trimestre ").concat(trimestre);
            a.click();
            })
            .catch((error)=>{
                toasting('Error al generar el documento', 'error');
            })
            .finally(() => {
            });
    }

    function generarInformeFacturasRecibidas(){
        var year = document.getElementById('cierre-recibidas-year').value;
        var trimestre = document.getElementById('cierre-recibidas-trimestre').value;
        generarCierreRecibidas(data.token, year, trimestre)
            .then((response) => {
                registrarEvento('Informe trimestral de facturas recibidas para el año'.concat(year).concat(' trimestre ').concat(trimestre).concat(' generado.'));
                return response.blob();
            })
            .then((blob) => URL.createObjectURL(blob))
            .then((href) => {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = href;
            a.download = "Cierre trimestral recibidas ".concat(year).concat(" trimestre ").concat(trimestre);
            a.click();
            })
            .catch((error)=>{
                toasting('Error al generar el documento', 'error');
            })
            .finally(() => {
            });
    }

    function generarInformeFacturasCliente(){
        var value = document.getElementById('cierre-cliente-cliente').value;
        var cliente = '';
        if(value != null){
            var split = value.split('|');
            var c = parseInt(split[0].split(':')[1].trim());
            cliente = clientesBuscados.find((item) => item.id == c);
        }
        var year = document.getElementById('cierre-cliente-year').value;
        var trimestre = document.getElementById('cierre-cliente-trimestre').value;

        generarCierreCliente(data.token, cliente.id, year, trimestre)
            .then((response) => {
                registrarEvento('Informe de facturas del año en curso para el cliente '.concat(cliente.nombreComercial).concat(' generado.'));
                return response.blob();
            })
            .then((blob) => URL.createObjectURL(blob))
            .then((href) => {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = href;
            a.download = "Informe año en curso  ".concat(cliente.nombreComercial.replace(/\./g, "_"));
            a.click();
            })
            .catch((error)=>{
                toasting('Error al generar el documento', 'error');
                console.log(error);
            })
            .finally(() => {
            });
    
    }

    function handleCliente(event){
        var cl = [];
        cl.push(event.target.value);
        findClientes(data.token, cl)
            .then((response) => {
                setClientesBuscados(response.reverse());
            }) 
            .catch(()=>{
            })
            .finally(() => {
            });
    }

    function mostrarYears(){
        var year = new Date().getFullYear();
        var options = [];

        for(var i = 0; i < 5; i++){
            options.push(
                <option value={year-i} selected={i == 0 ? true:false}>{year-i}</option>
            );
        }
        return options;
    }   

    return (
        
        <div>
            <h2 className='page-title'>Informes</h2>
            <div className='wrapper-listado'>
                <fieldset className='fieldset-styled'>
                    <legend className='bold' style={{fontSize:'2vh'}}>Modelo 347</legend>
                    <div class="row">
                    <div class="col-sm-6">
                        <div class="card informe">
                            <div class="card-body">
                                <h5 class="card-title" style={{fontSize:'1.9vh'}}>Clientes</h5>
                                <p class="card-text" style={{fontSize:'1.9vh'}}>Incluye la información de clientes anual y por trimestres.</p>
                                <select className='selector-year-modelo347' style={{fontSize:'1.9vh'}} id='modelo347-clientes-year'>
                                    {mostrarYears().map((item)=> {return item;})}
                                </select>
                                <button class="btn btn-primary generar-informe-button"  style={{fontSize:'1.9vh'}}onClick={() => generarInforme347clientes()}>Generar informe</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="card informe">
                            <div class="card-body">
                                <h5 class="card-title" style={{fontSize:'2vh'}}>Proveedores</h5>
                                <p class="card-text" style={{fontSize:'1.9vh'}}>Incluye la información de proveedores anual y por trimestres.</p>
                                <select className='selector-year-modelo347' style={{fontSize:'1.9vh'}} id='modelo347-proveedores-year'>
                                    {mostrarYears().map((item)=> {return item;})}
                                </select>                            
                                <button class="btn btn-primary generar-informe-button" style={{fontSize:'1.9vh'}} onClick={() => generarInforme347proveedores()}>Generar informe</button>
                            </div>
                        </div>
                    </div>
                </div>
                </fieldset>
                <fieldset className='fieldset-styled'>
                    <legend className='bold' style={{fontSize:'2vh'}}>Cierre trimestral</legend>
                    <div class="row">
                    <div class="col-sm-4">
                        <div class="card informe">
                            <div class="card-body">
                                <h5 class="card-title" style={{fontSize:'1.9vh'}}>Emitidas</h5>
                                <p class="card-text" style={{fontSize:'1.9vh'}}>Incluye las facturas emitidas del año y trimestre seleccionados.</p>
                                <div className='selectores-cierre'>
                                    <select className='selector-year-cierre w25' style={{fontSize:'1.9vh'}} id='cierre-emitidas-year'>
                                        {mostrarYears().map((item)=> {return item;})}
                                    </select>
                                    <select className='selector-year-cierre w25' style={{fontSize:'1.9vh'}} id='cierre-emitidas-trimestre'>
                                        <option value="1" selected>1º trimestre</option>
                                        <option value="2" >2º trimestre</option>
                                        <option value="3" >3º trimestre</option>
                                        <option value="4" >4º trimestre</option>
                                    </select>
                                </div>
                                <button class="btn btn-primary generar-informe-button"  style={{fontSize:'1.9vh'}}onClick={() => generarInformeFacturasEmitidas()}>Generar informe</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card informe">
                            <div class="card-body">
                                <h5 class="card-title" style={{fontSize:'1.9vh'}}>Recibidas</h5>
                                <p class="card-text" style={{fontSize:'1.9vh'}}>Incluye las facturas recibidas del año y trimestre seleccionados.</p>
                                <div className='selectores-cierre'>
                                    <select className='selector-year-cierre w25' style={{fontSize:'1.9vh'}} id='cierre-recibidas-year'>
                                        {mostrarYears().map((item)=> {return item;})}
                                    </select>
                                    <select className='selector-year-cierre w25'  style={{fontSize:'1.9vh'}}id='cierre-recibidas-trimestre'>
                                        <option value="1" selected>1º trimestre</option>
                                        <option value="2" >2º trimestre</option>
                                        <option value="3" >3º trimestre</option>
                                        <option value="4" >4º trimestre</option>
                                    </select>
                                </div>                      
                                <button class="btn btn-primary generar-informe-button" style={{fontSize:'1.9vh'}} onClick={() => generarInformeFacturasRecibidas()}>Generar informe</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card informe">
                            <div class="card-body">
                                <h5 class="card-title" style={{fontSize:'1.9vh'}}>Por cliente</h5>
                                <p class="card-text" style={{fontSize:'1.9vh'}}>Incluye las facturas emitidas del año y trimestre en curso para el cliente seleccionado.</p>
                                <div className='selectores-cierre'>
                                <select className='selector-year-cierre w25' style={{fontSize:'1.9vh'}} id='cierre-cliente-year'>
                                        {mostrarYears().map((item)=> {return item;})}
                                    </select>
                                    <select className='selector-year-cierre w25'  style={{fontSize:'1.9vh'}}id='cierre-cliente-trimestre'>
                                        <option value="1" selected>1º trimestre</option>
                                        <option value="2" >2º trimestre</option>
                                        <option value="3" >3º trimestre</option>
                                        <option value="4" >4º trimestre</option>
                                    </select>
                                <Autocomplete
                                    style={{width:'80%', fontSize:'1.5vh', margin: 'auto'}}
                                    freeSolo
                                    id="cierre-cliente-cliente"
                                    onInputChange={(evento) => handleCliente(evento)} 
                                    options={clientesBuscados.map((item) => "Código: ".concat(item.id).concat(" | Nombre: ").concat(item.nombreComercial).concat(" | CIF: ").concat(item.cif))}
                                    renderInput={(params) => (
                                        <div style={{width:'100%'}} ref={params.InputProps.ref}>
                                            <input style={{ border: '1px solid lightgrey', borderRadius: '3px', width: '100%'}} type="text" {...params.inputProps} 
                                            onFocus={()=>obtainClientes()}/>
                                        </div>
                                    )}
                                />
                                </div>                      
                                <button class="btn btn-primary generar-informe-button" style={{fontSize:'1.9vh'}} onClick={() => generarInformeFacturasCliente()}>Generar informe</button>
                            </div>
                        </div>
                    </div>
                </div>
                </fieldset>

            </div>
                
        </div>
    );
};


InformesComponent.propTypes = {

};

export default InformesComponent;
