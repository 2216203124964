export class Evento{
    
    id='';
    fecha='';
    evento='';
    usuario='';
    
    constructor(id, fecha, evento, usuario){
            this.id=id;
            this.fecha=fecha;
            this.evento=evento;
            this.usuario=usuario;
    }
}