import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllClientes,  findClienteById, findClienteByNombre, findClienteByCif } from '../../services/fecthService';
import { getAllArticulos, findArticulos } from '../../services/fecthService';
import { Cliente } from '../../models/cliente.class';
import { Articulo } from '../../models/articulo.class';
import { albaranContext } from './pedidosCComponent';
import { Paper } from '@mui/material';

const ModalDatosAlbaranComponent = ({ data, actualizarAlbaran, clearAlbaran}) => {

    const [isMobile, setIsMobile] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState();

    const [clientesBuscados, setClientesBuscados] = useState([]);
    const [articulosBuscados, setArticulosBuscados] = useState([]);

    const[autocompleteState, setAutocompleteState] = useState(false);


    const [datosCargados, setDatosCargados] = useState(false);
    const [numeroAlbaranValue, setNumeroAlbaranValue] = useState('');
    const [responsableValue, setResponsableValue] = useState('');
    const [clienteIdValue, setClienteIdValue] = useState('');
    const [clienteNombreValue, setClienteNombreValue] = useState('');
    const [clienteCIFValue, setClienteCIFValue] = useState('');
    const [fechaValue, setFechaValue] = useState('');
    const [fechaEntregaValue, setFechaEntregaValue] = useState('');
    const [observacionesValue, setObservacionesValue] = useState('');
    const [articulosValues, setArticulosValues] = useState([]);
    const [formularioCompleto, setFormularioCompleto ] = useState(true);

    const albaran = useContext(albaranContext);

   useEffect(() => {
        const handleResize = () => {
            // eslint-disable-next-line no-restricted-globals
            setIsMobile(screen.width < 768);
        };
        handleResize();
        var m = document.getElementById('datosAlbaranModal');
        const handleC = () => {
            clearAlbaran();
            setDatosCargados(false);
        };
       
        m.addEventListener('hidden.bs.modal', handleC);
        
        return () => {
            m.removeEventListener('hidden.bs.modal', handleC);
        }
   }, [albaran]);

    const obtainClientes = () => {
        
        if(clientes.length == 0){
            getAllClientes(data.token)
            .then((response) => {
                let arts = [];
                let obs = "";
                response.map((item) => {
                    if(item.observaciones != null) obs = item.observaciones;
                    var cliente = new Cliente(
                        item.id,
                        item.nombre,
                        item.nombreComercial,
                        item.cif,
                        item.direccion,
                        item.cp,
                        item.poblacion,
                        item.provincia,
                        item.tributacion,
                        item.tipoDePago,
                        item.movil,
                        item.telefono,
                        item.email,
                        item.observaciones
                    );
                    arts.push(cliente);
                    obs = "";
                    
                });
                setClientes(arts);
            }) 
            .catch(()=>{
                alert("Error al recuperar clientes");
            })
            .finally(() => {
                
            });
        }
        
    }

    function handleCliente(id, evento){
        if(evento != null){
            if(id == 'id'){
                setClienteIdValue(evento.target.value);

                if(evento.target.value == ''){
                    setCliente('');
                    setClienteNombreValue('');
                    setClienteCIFValue('');
                }else{
                    findClienteById(data.token, evento.target.value)
                    .then((response) => {
                        if(response != null){
                            setCliente(response);
                            setClienteNombreValue(response.nombreComercial);
                            setClienteCIFValue(response.cif);
                        }else{
                            setCliente('');
                            setClienteIdValue(evento.target.value);
                            setClienteNombreValue('');
                            setClienteCIFValue('');
                        }
                    }) 
                    .catch(()=>{
                    })
                    .finally(() => {
                    });
                }
            }else if(id == 'nombre'){
                setCliente('');
                setClienteIdValue('');
                setClienteNombreValue(evento.target.value);
                setClienteCIFValue('');
                findClienteByNombre(data.token,  evento.target.value)
                .then((response) => {
                    setClientesBuscados(response);
                }) 
                .catch(()=>{
                })
                .finally(() => {
                });
            }else if(id == 'cif'){
                setCliente('');
                setClienteIdValue('');
                setClienteNombreValue('');
                setClienteCIFValue(evento.target.value);
                findClienteByCif(data.token,  evento.target.value)
                .then((response) => {
                    setClientesBuscados(response);
                }) 
                .catch(()=>{
                })
                .finally(() => {
                });
            }
        }
    }

    function handleArticulo(event){
        var ar = [];
        if(event != null){
            ar.push(event.target.value);
            findArticulos(data.token, ar)
                .then((response) => {
    
                    setArticulosBuscados(response.sort((a, b) => a.nombre.localeCompare(b.nombre)));
                }) 
                .catch(()=>{
                })
                .finally(() => {
                });
        }
       
    }

    function rellenarCliente(id, evento, valor){
        if(evento != null){
            var split = valor.split('|');
            var idC = split[0].split(':')[1].trim();
            var nombre = split[1].split(':')[1].trim();
            var cif = split[2].split(':')[1].trim();

            setCliente(clientesBuscados.find((item) => item.id == idC));
            setClienteIdValue(idC);
            setClienteNombreValue(nombre);
            setClienteCIFValue(cif);
            
        }   
    }

    function rellenarArticulo(id, index, valor){
        
        var arts = [...articulosValues];

        if(id == 'nombre'){
            var art = articulosBuscados.find((item) => item.nombre == valor);
            if(art != undefined){
                arts[index].idArticulo = art.id;
                arts[index].nombre = art.nombre;
                arts[index].precio = formatear3decimales(art.precio);
                arts[index].descuento = 0;
                arts[index].porcentajeIva = art.iva;
                arts[index].importeIva = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100))*arts[index].porcentajeIva/100);
                arts[index].subtotal = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100)));

            }else{
                arts[index].nombre = valor;
            }
            
        }else if(id == 'unidades'){
            arts[index].cantidad = valor;
            arts[index].importeIva = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100))*arts[index].porcentajeIva/100);
            arts[index].subtotal = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100)));

        }else if(id == 'precio'){
            arts[index].precio = formatear3decimales(valor);
            arts[index].importeIva = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100))*arts[index].porcentajeIva/100);
            arts[index].subtotal = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100)));

        }else if(id == 'descuento'){
            arts[index].descuento = valor;
            arts[index].importeIva = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100))*arts[index].porcentajeIva/100);
            arts[index].subtotal = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100)));

        }else if(id == 'porcentajeIva'){
            arts[index].porcentajeIva = valor;
            arts[index].importeIva = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100))*arts[index].porcentajeIva/100);
            arts[index].subtotal = formatear3decimales(((arts[index].precio*arts[index].cantidad)*(1-arts[index].descuento/100)));

        }

        setArticulosValues(arts);
    }

    function formatear3decimales(precio){
        if(isNaN(precio)){
            precio = '0';
        }
        if (typeof precio !== 'string') {
            precio = precio.toString(); // Convertir a cadena si no lo es
        }
    
        // Verificar si hay más de tres decimales y truncarlos si es necesario
        if (/^\d*\.\d{4,}$/.test(precio)) {
            const parts = precio.split('.');
            precio = `${parts[0]}.${parts[1].substring(0, 3)}`;
        }
    
      return precio;
    }

    function formatearFechaAlReves(item){
        var date = new Date(item);
        var split = date.toLocaleDateString();
        var split2 = split.split('/');
        
        var day = split2[0];
        var month = split2[1];
        var year = split2[2];
        if (day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6 || day == 7 || day == 8 || day == 9){
            day = '0'.concat(day);
        }
        if (month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9){
            month = '0'.concat(month);
        }
        return year.concat('-').concat(month).concat('-').concat(day);
    }

    function mostrarTituloModal(){
       if(albaran != ''){
        if(albaran.estado == "PRESUPUESTADO"){
            return "Presupuesto ".concat(albaran.numeroAlbaran);
        }else if(albaran.estado == "ALBARANADO" || albaran.estado == "FACTURADO"){
            return "Albarán ".concat(albaran.numeroAlbaran);
        }
       }
        
  
    }

    function cambiarValorInput(id, valor){
        if(id == 'numeroAlbaran'){
            setNumeroAlbaranValue(valor);
        }else if(id == 'responsable'){
            setResponsableValue(valor);
        }else if(id == 'clienteId'){
            setClienteIdValue(valor);
        }else if(id == 'clienteNombre'){
            setClienteNombreValue(valor);
        }else if(id == 'clienteCIF'){
            setClienteCIFValue(valor);
        }else if(id == 'fecha'){
            setFechaValue(valor);
        }else if(id == 'fechaEntrega'){
            setFechaEntregaValue(valor);
        }else if(id == 'observaciones'){
            setObservacionesValue(valor);
        }
    }

    function isClienteFilled(id){
        
        if(formularioCompleto){
            return ({ border: '1px solid lightgrey', borderRadius: '3px', width: '100%'});
        }else{
            if(id == 'clienteId'){
                if(clienteIdValue == ''){
                    return ({ border: '1px solid red', borderRadius: '3px', width: '100%'});
                }else{
                    return ({ border: '1px solid lightgrey', borderRadius: '3px', width: '100%'});
                }
            }else if(id == 'clienteNombre'){
                if(clienteNombreValue == ''){
                    return ({ border: '1px solid red', borderRadius: '3px', width: '100%'});
                }else{
                    return ({ border: '1px solid lightgrey', borderRadius: '3px', width: '100%'});
                }
            }if(id == 'clienteCIF'){
                if(clienteCIFValue == ''){
                    return ({ border: '1px solid red', borderRadius: '3px', width: '100%'});
                }else{
                    return ({ border: '1px solid lightgrey', borderRadius: '3px', width: '100%'});
                }
            }
            
        }
    }

    function modal(){

        
        if(albaran != ''){
            if(!datosCargados){
                setDatosCargados(true);
                
                setNumeroAlbaranValue(albaran.numeroAlbaran);
                setResponsableValue(albaran.responsable);
                setClienteIdValue(albaran.cliente.id);
                setClienteNombreValue(albaran.cliente.nombreComercial);
                setClienteCIFValue(albaran.cliente.cif);
                setFechaValue(formatearFechaAlReves(albaran.fecha));
                setFechaEntregaValue(formatearFechaAlReves(albaran.fechaEntrega));
                setObservacionesValue(albaran.observaciones);
                setArticulosValues(albaran.articulos);
                console.log(albaran.articulos);
            }
        }

        if(isMobile){
            
            return (<div class="modal fade" tabindex="-1" id='datosAlbaranModal' aria-labelledby="exampleModalLabel" aria-hidden="true" style={{position:'fixed', margin:'auto', maxHeight:'100vh'}}>
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"  style={{position:'fixed', margin:'0 auto 0 auto', width:'100vw' }}>
                <div class="modal-content" style={{position:'fixed', height:'90vh',maxHeight:'90vh', width:'100vw',maxWidth:'100vw'}} onClick={(e) => e.stopPropagation()}>
                <div class="modal-header">
                <h3>Albarán {numeroAlbaranValue}</h3>
                </div>
                    
                <div class="modal-body">
                    <h6 style={{textAlign:'left'}}>Datos generales</h6>
                    <p className='w100' style={{margin: '0 0.7vh 0 0', fontWeight:'bold', fontSize: '1.4vh', textAlign:'left'}}>Tipo</p>
                    <select className='mfselect w100'  disabled={albaran.estado == 'FACTURADO'} id="pedidoC-datos-field-tipo">
                        <option value="PRESUPUESTADO" selected={albaran.estado == 'PRESUPUESTADO'}>Presupuesto</option>
                        <option value="ALBARANADO" selected={albaran.estado == 'ALBARANADO' || albaran.estado == 'FACTURADO'}>Albarán</option>
                    </select> 

                    <p className='w100' style={{margin: '1.5vh 0.7vh 0 0', fontWeight:'bold', fontSize: '1.4vh', textAlign:'left'}}>Nº albarán</p>
                    <input className='mfinput w100'  disabled={albaran.estado == 'FACTURADO'} value={numeroAlbaranValue} id='pedidoC-datos-field-numeroAlbaran' onChange={(event) => cambiarValorInput('numeroAlbaran', event.target.value)}></input>
                 
                    <p className='w100' style={{margin: '1.5vh 0.7vh 0 0', fontWeight:'bold', fontSize: '1.4vh', textAlign:'left'}}>Responsable</p>
                    <input className='mfinput w100'  disabled={albaran.estado == 'FACTURADO'} value={responsableValue} id='pedidoC-datos-field-responsable' onChange={(event) => cambiarValorInput('responsable', event.target.value)}></input>
                 
                    <div className='separator'></div>
    
                    <h6 style={{textAlign:'left'}}>Datos de cliente</h6>
                    <p className='w100' style={{margin: '1.5vh 0.7vh 0 0', fontWeight:'bold', fontSize: '1.4vh', textAlign:'left'}}>Código</p>
                    <input className='mfinput w55'  disabled={albaran.estado == 'FACTURADO'? true: false} style={isClienteFilled('clienteId')} value={clienteIdValue} onChange={(evento) => handleCliente('id', evento)}></input>

                    <p className='w100' style={{margin: '1.5vh 0.7vh 0 0', fontWeight:'bold', fontSize: '1.4vh', textAlign:'left'}}>Nombre</p>
                    

                    <p className='w100' style={{margin: '1.5vh 0.7vh 0 0', fontWeight:'bold', fontSize: '1.4vh', textAlign:'left'}}>CIF</p>
                    <Autocomplete
                               style={{width:'100%', fontSize:'1.5vh'}}
                                freeSolo
                                disabled={albaran.estado == 'FACTURADO'? true: false}
                                key={autocompleteState}
                                value={clienteCIFValue}
                                onInputChange={(evento) => handleCliente('cif', evento)} 
                                onChange={(event,value) => rellenarCliente('cif', event, value)}                  
                                id="pedidoC-modal-field-clienteCIF"
                                options={clientesBuscados.map((item) => "Código: ".concat(item.id).concat(" | Nombre: ").concat(item.nombreComercial).concat(" | CIF: ").concat(item.cif))}
                                renderInput={(params) => (
                                    <div style={{width:'100%'}} ref={params.InputProps.ref}>
                                        <input style={isClienteFilled('clienteCIF')} type="text" {...params.inputProps}/>
                                    </div>
                                )}
                            />
    
                    <div className='separator'></div>
    
                    <h6 style={{textAlign:'left'}}>Datos de gestión</h6>
                    <div style={{display:'flex', justifyContent:'center', width:'100%'}}>
                        <div style={{width:'50%'}}>
                            <p className='w100' style={{margin: '1.5vh 0.7vh 0 0', fontWeight:'bold', fontSize: '1.4vh', textAlign:'left'}}>Fecha</p>
                            <input className='mfinput w90' style={{margin:'0 10% 0 0'}} disabled={albaran.estado == 'FACTURADO'} value={fechaValue} type='date' id='pedidoC-datos-field-fecha' onChange={(event) => cambiarValorInput('fecha', event.target.value)}></input>
                        </div>
                        <div style={{width:'50%'}}>
                            <p className='w100' style={{margin: '1.5vh 0.7vh 0 0', fontWeight:'bold', fontSize: '1.4vh', textAlign:'left'}}>Fecha de entrega</p>
                            <input className='mfinput w90' style={{margin:'0 10% 0 0'}} disabled={albaran.estado == 'FACTURADO'} value={fechaEntregaValue} type='date' id='pedidoC-datos-field-fechaEntrega' onChange={(event) => cambiarValorInput('fechaEntrega', event.target.value)}></input>
                        </div>
                    </div>

                    <div className='separator'></div>
    
                    <h6 style={{textAlign:'left'}}>Otros</h6>
                    <p className='w100' style={{margin: '1.5vh 0.7vh 0 0', fontWeight:'bold', fontSize: '1.4vh', textAlign:'left'}}>Observaciones</p>
                    <textarea className='mftextarea w100' id='pedidoC-datos-field-observaciones' value={observacionesValue} onChange={(event) => cambiarValorInput('observaciones', event.target.value)}></textarea>   
    
                    <div className='separator'></div>
                        
                    <h6 style={{textAlign:'left'}}>Artículos</h6>
                    {renderArticulos()}
                    
                </div>
                <div class="modal-footer">
                    <p>Total: {calcularTotal()}€ </p>
                    <button type="button" class="btn btn-primary modal-footer-button" onClick={() => prepararActualizacion()}>Guardar cambios</button>
                </div>
                
                
                </div>
            </div>
            </div>);
        }else{

    
            
    
            return (<div class="modal fade" tabindex="-1" id='datosAlbaranModal' aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 className='modal-main-title' id="exampleModalLabel">{mostrarTituloModal()}</h1>
                    <button id='closeDatosAlbaranModal' type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                    
                <div class="modal-body">
                    <h6 className='modal-title'>Datos generales</h6>
    
                    <div className='modal-row'>
                        <div className='modal-field w25'>
                            <label className='mflabel w35'>Tipo</label>
                            <select className='mfselect w65' disabled={albaran.estado == 'FACTURADO'} id="pedidoC-datos-field-tipo">
                                <option value="PRESUPUESTADO" selected={albaran.estado == 'PRESUPUESTADO'}>Presupuesto</option>
                                <option value="ALBARANADO" selected={albaran.estado == 'ALBARANADO' || albaran.estado == 'FACTURADO'}>Albarán</option>
                            </select>                    
                        </div>
                        <div className='modal-field w25'>
                            <label className='mflabel w35'>Nº albarán</label>
                            <input className='mfinput w65'  disabled={albaran.estado == 'FACTURADO'} value={numeroAlbaranValue} id='pedidoC-datos-field-numeroAlbaran' onChange={(event) => cambiarValorInput('numeroAlbaran', event.target.value)}></input>
                        </div>
                        <div className='modal-field w25'>
                            <label className='mflabel w35'>Responsable</label>
                            <input className='mfinput w65'  disabled={albaran.estado == 'FACTURADO'} value={responsableValue} id='pedidoC-datos-field-responsable' onChange={(event) => cambiarValorInput('responsable', event.target.value)}></input>
                        </div> 
                    </div>
    
                    <div className='separator'></div>
    
                    <h6 className='modal-title'>Datos de cliente</h6>
                    <div className='modal-row'>
                        <div className='modal-field w20'>
                            <label className='mflabel w45'>Código</label>
                            <input className='mfinput w55'  disabled={albaran.estado == 'FACTURADO'? true: false} style={isClienteFilled('clienteId')} value={clienteIdValue} onChange={(evento) => handleCliente('id', evento)}></input>
                        </div>
                        <div className='modal-field w60'>
                            <label className='mflabel w20'>Nombre</label>
                            <Autocomplete
                                style={{width:'80%', fontSize:'1.5vh'}}
                                freeSolo
                                disabled={albaran.estado == 'FACTURADO'? true: false}
                                key={autocompleteState}
                                value={clienteNombreValue}
                                onInputChange={(evento) => handleCliente('nombre', evento)} 
                                onChange={(event,value) => rellenarCliente('nombre', event, value)}                  
                                id="pedidoC-modal-field-clienteNombre"
                                options={clientesBuscados.map((item) => "Código: ".concat(item.id).concat(" | Nombre: ").concat(item.nombreComercial).concat(" | CIF: ").concat(item.cif))}
                                renderInput={(params) => (
                                    <div style={{width:'100%'}} ref={params.InputProps.ref}>
                                        <input style={isClienteFilled('clienteNombre')} type="text" {...params.inputProps}/>
                                    </div>
                                )}
                            />
                        </div> 
                        <div className='modal-field w20'>
                            <label className='mflabel w35'>CIF</label>
                            <Autocomplete
                               style={{width:'65%', fontSize:'1.5vh'}}
                                freeSolo
                                disabled={albaran.estado == 'FACTURADO'? true: false}
                                key={autocompleteState}
                                value={clienteCIFValue}
                                onInputChange={(evento) => handleCliente('cif', evento)} 
                                onChange={(event,value) => rellenarCliente('cif', event, value)}                  
                                id="pedidoC-modal-field-clienteCIF"
                                options={clientesBuscados.map((item) => "Código: ".concat(item.id).concat(" | Nombre: ").concat(item.nombreComercial).concat(" | CIF: ").concat(item.cif))}
                                renderInput={(params) => (
                                    <div style={{width:'100%'}} ref={params.InputProps.ref}>
                                        <input style={isClienteFilled('clienteCIF')} type="text" {...params.inputProps}/>
                                    </div>
                                )}
                            />
                        </div> 
                       
                    </div>
    
                    <div className='separator'></div>
    
                    <h6 className='modal-title'>Datos de gestión</h6>
                    <div className='modal-row'>
                        <div className='modal-field w25'>
                            <label className='mflabel w35'>Fecha</label>
                            <input className='mfinput w65' disabled={albaran.estado == 'FACTURADO'} value={fechaValue} type='date' id='pedidoC-datos-field-fecha' onChange={(event) => cambiarValorInput('fecha', event.target.value)}></input>
                        </div> 
                        <div className='modal-field w25'>
                            <label className='mflabel w50'>Fecha de entrega</label>
                        <input className='mfinput w50' disabled={albaran.estado == 'FACTURADO'} value={fechaEntregaValue} type='date' id='pedidoC-datos-field-fechaEntrega' onChange={(event) => cambiarValorInput('fechaEntrega', event.target.value)}></input>
                        </div>
                    </div>
    
                    <div className='separator'></div>
    
                    <h6 className='modal-title'>Otros</h6>
                    <div className='modal-row'>
                        <label className='mflabel w8_75'>Observaciones</label>
                        <textarea className='mftextarea w91_75' id='pedidoC-datos-field-observaciones' value={observacionesValue} onChange={(event) => cambiarValorInput('observaciones', event.target.value)}></textarea>   
                    </div>
    
                    <div className='separator'></div>
                        
                    <h6 className='modal-title'>Artículos</h6>
                    <div className='pedido-modal-articulos-table'>
                        <div className='pmaheader w100'>
                            <label className='pmahfield w10'>Código</label>
                            <label className='pmahfield w20'>Nombre</label>
                            <label className='pmahfield w10'>Unidades</label>
                            <label className='pmahfield w10'>Precio</label>
                            <label className='pmahfield w10'>Descuento</label>
                            <label className='pmahfield w10'>IVA %</label>
                            <label className='pmahfield w10'>Importe IVA</label>
                            <label className='pmahfield w10'>Subtotal</label>
                            { albaran.estado != "FACTURADO" 
                    ?<div className='acciones'>
                                <label>Acciones</label>
                            </div>
                        : <div className='acciones'>
                                <label style={{display: 'none'}}>Acciones</label>
                            </div>
                    }
                            
                            
                        </div>
                        {renderArticulos().map((item) =>{
                            return item;
                        })}
                    { albaran.estado != "FACTURADO" 
                    ?<div className='modal-add w100'>
                            <button className='addRowButton' id="modalAddRow" onClick={()=>addModalRow()}><h3>+</h3></button>
                        </div>
                        : ''
                    }
                        
                    </div>
                    
                </div>
                <div class="modal-footer">
                    <p>Total: {calcularTotal()}€ </p>
                    <button type="button" class="btn btn-primary modal-footer-button" onClick={() => prepararActualizacion()}>Guardar cambios</button>
                </div>
                
                
                </div>
            </div>
            </div>);
            
        }

    }

    function prepararActualizacion(){
        var a = {
            'numeroAlbaran': numeroAlbaranValue,
            'cliente': cliente,
            'fecha': fechaValue,
            'fechaEntrega': fechaEntregaValue,
            'observaciones': observacionesValue,
            'responsable': responsableValue,
            'articulos': articulosValues
        };
        actualizarAlbaran(albaran.id, a);
        
        document.getElementById('closeDatosAlbaranModal').click();
    }

    function validarNumero(valor) {
        
        if (isNaN(valor)) {
          return "";
        } else {
          return valor;
        }
    }

    function isArticuloFilled(id, index){
        if(!formularioCompleto){
            if(id == 'nombre'){
                if(articulosValues[index].nombre == ''){
                    return ("1px solid red");
                }else{
                    return ("1px solid ligthgrey");
                }
            }else if(id == 'unidades'){
                if(articulosValues[index].cantidad == ''){
                    return ({border: 'solid 1px red'});
                }else{
                    return ({});
                }
            }else if(id == 'precio'){
                if(articulosValues[index].precio == ''){
                    return ({border: 'solid 1px red'});
                }else{
                    return ({});
                }
            }else if(id == 'porcentajeIva'){
                if(articulosValues[index].porcentajeIva == ''){
                    return ({border: 'solid 1px red'});
                }else{
                    return ({});
                }
            }
        }
        
        
    }

    function renderArticulos(){
        let articles = [];
        if(isMobile){
        articulosValues.map((item, index) => {
            articles.push(
                <div key={'pedidoModalRow'.concat(index)} className='pedido' style={{ borderRadius:'1vw', border:'1px solid rgb(219, 219, 219)', margin:'0 0 1vh 0'}}>
                <div className='articulo-modal-header-movil' style={{margin:'1vh 0 0 0'}}>
                    <label style={{width:'78%', margin:'0 1% 0 1%'}}>Artículo</label>
                    <i style={{fontWeight:'bold',textAlign:'right',width:'17%', margin:'0 2% 0 1%'}} class="bi bi-x-lg hover" title="Eliminar" onClick={() => removeModalRow(index)}></i>
                </div>
                <div style={{display:'flex', justifyContent:'flex-start', width:'100%'}}>
                    <Autocomplete
                        PaperComponent={({ children }) => (
                        <Paper
                            elevation={3}
                            style={{
                                maxWidth: '100vw'
                            }}
                            >
                            {children}
                        </Paper>)}
                        style={{maxWidth:'100%', fontSize:'1.5vh', textAlign:'left', width:'98%', borderRadius:'1vw', border:'none', margin:'0 1% 0 1%'}}
                        onInputChange={(evento) => handleArticulo(evento)} 
                        onChange={(event, value) => rellenarArticulo('nombre', value, index)}
                        freeSolo
                        value={item.nombre}
                        id={'pcarfieldNombre'.concat(index)}
                        options={articulosBuscados.map((item) => item.nombre)}
                        renderInput={(params) => (
                            <div style={{}} ref={params.InputProps.ref}>
                                <input style={{ border: '1px solid lightgrey', borderRadius: '3px', width: '100%' ,maxWidth:'100%'}} type="text" {...params.inputProps}/>
                            </div>
                        )}
                    />  
                </div>
                <div className='articulo-modal-header-movil' style={{margin:'0.5vh 0 0 0'}}>
                    <label style={{ textAlign:'left', width:'48.5%', margin:'0 0.5% 0 1%'}}>Precio sin iva</label>
                    <label style={{ textAlign:'left', width:'48.5%', margin:'0 1% 0 0.5%'}}>Unidades</label>
                </div>
                
                <div style={{display:'flex', justifyContent:'flex-start', width:'100%', margin:'0 0 2vh 0'}}>
                    <input id={'pcarfieldPrecio'.concat(index)} style={{fontSize:'1.5vh', textAlign:'left', width:'48.5%', borderRadius:'1vw', border:'1px solid rgb(219, 219, 219)', margin:'0 0.5% 0 1%'}} value={item.precio} onChange={(event) => handleArticulo(event, index, 'precio')}></input>
                    <input id={'pcarfieldUnidades'.concat(index)} style={{fontSize:'1.5vh', textAlign:'left', width:'48.5%', borderRadius:'1vw', border:'1px solid rgb(219, 219, 219)', margin:'0 1% 0 0.5%'}} value={item.cantidad} onChange={(event) => handleArticulo(event, index, 'cantidad')}></input>
                </div>
            </div>
            );
        });
        }else{
            for(var i = 0; i < articulosValues.length; i++){
                let a = i;
        
                    articles.push(
                        <div key={'pedidoModalRow'.concat(a)} id={'pedidoModalRow'.concat(a)} className='pmarow w100'>
                            <input className='pmarfield w10' id={'pmarfieldId'.concat(a)} disabled value={validarNumero(articulosValues[a].idArticulo)}></input>
                            <Autocomplete
                                    freeSolo
                                    disabled={albaran.estado == 'FACTURADO'? true: false}
                                    value={articulosValues[a].nombre}
                                    onInputChange={(evento) => handleArticulo(evento)} 
                                    onChange={(event, value) => rellenarArticulo('nombre', a, value)}
                                    style={{fontSize:'1.5vh', width:'20%', margin: '0 0.2vw 0 0.2vw'}}
                                    id={'pmarfieldNombre'.concat(a)}
                                    options={articulosBuscados.map((item) => item.nombre)}
                                    renderInput={(params) => (
                                        <div ref={params.InputProps.ref}>
                                            <input style={formularioCompleto || articulosValues[a].nombre != ''  ?{fontSize:'1.5vh',border: '1px solid lightgrey', borderRadius: '5px', width: '100%'}: {fontSize:'1.5vh',border: '1px solid red', borderRadius: '5px', width: '100%'}} type="text" {...params.inputProps}
                                        />
                                        </div>
                                    )}
                                />                    
                            <input className='pmarfield w10' disabled={albaran.estado == 'FACTURADO'? true: false} style={isArticuloFilled('unidades', a)} id={'pmarfieldUnidades'.concat(a)} value={articulosValues[a].cantidad} onChange={(event)=>rellenarArticulo('unidades', a, event.target.value)}></input>
                            <input className='pmarfield w10' disabled={albaran.estado == 'FACTURADO'? true: false}style={isArticuloFilled('precio', a)} id={'pmarfieldPrecio'.concat(a)} value={articulosValues[a].precio} onChange={(event)=>rellenarArticulo('precio', a, event.target.value)}></input>
                            <input className='pmarfield w10' disabled={albaran.estado == 'FACTURADO'? true: false}id={'pmarfieldDescuento'.concat(a)} value={articulosValues[a].descuento} onChange={(event)=>rellenarArticulo('descuento', a, event.target.value)}></input>
                            <input className='pmarfield w10' disabled={albaran.estado == 'FACTURADO'? true: false}style={isArticuloFilled('porcentajeIva', a)} id={'pmarfieldPorcentajeIva'.concat(a)} value={articulosValues[a].porcentajeIva} onChange={(event)=>rellenarArticulo('porcentajeIva', a, event.target.value)}></input>
                            <input className='pmarfield w10' disabled id={'pmarfieldImporteIva'.concat(a)} value={articulosValues[a].importeIva}></input>
                            <input className='pmarfield w10' disabled id={'pmarfieldSubtotal'.concat(a)}  value={articulosValues[a].subtotal}></input>
                            <div className='acciones'>
                                <i className="bi bi-trash3" title='Eliminar' onClick={() => removeModalRow(a)}></i>
                            </div>
                        </div>)
                }
        }
        
        return articles;
    }

    function addModalRow(){
        setFormularioCompleto(true);
        let b = [...articulosValues];
        
        b.push({
            id:'',
            idArticulo:'',
            nombre: '',
            unidades: '',
            precio: '',
            descuento: '',
            porcentajeIva:'',
            importeIva:'',
            subtotal: ''
        });
        setArticulosValues(b);

    }

    function removeModalRow(index){

        var arts = [...articulosValues];

        arts.splice(index, 1);

        setArticulosValues(arts);
    }

    function calcularTotal(){
        var total = 0.00;
        articulosValues.map((articulo) => {
            if(articulo.importeIva && articulo.subtotal){
                total = parseFloat(total) + parseFloat(articulo.importeIva) + parseFloat(articulo.subtotal);
            }
        });

        if(!isNaN(total)){
            return parseFloat(total).toFixed(2);
        }
        else{
            return '0.00';
        }
    }

    return (modal());
};


ModalDatosAlbaranComponent.propTypes = {

};


export default ModalDatosAlbaranComponent;
